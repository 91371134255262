import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import * as actions from "store/actions/index.js";
//services
import { updateData_ } from "store/services/commonService";

const validationSchema = Yup.object().shape({
  tradeCommission: Yup.string().required("Required"),
  depositCommission: Yup.string().required("Required"),
  withdrawCommission: Yup.string().required("Required"),
  fee: Yup.string().required("Required"),
});

const validateData = async (data) => {
  try {
    await validationSchema.validate(data, { abortEarly: false });
    return { isValid: true, errors: {} };
  } catch (error) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    return { isValid: false, errors };
  }
};

export default ({
  setisUpdateProfile,
  editData,
  dispatch,
  userPermissions,
  userRole,
}) => {
  const [show, setShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [fields, setfields] = useState({
    tradeCommission: "",
    depositCommission: "",
    withdrawCommission: "",
    fee: "",
  });
  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );
  //commission function for particular user
  const CommissionParticularFunction = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          url: "editUser",
          userId: editData?._id,
          ...fields,
        };
        setShow(true);
        await dispatch(updateData_(obj));
        setShow(false);
        setisUpdateProfile(true);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      console.log(err);
      setShow(false);
    }
  };
  useEffect(() => {
    setfields({
      tradeCommission: editData?.tradeCommission,
      depositCommission: editData?.depositCommission,
      withdrawCommission: editData?.withdrawCommission,
      fee: editData?.fee,
    });
  }, [editData]);

  return (
    <>
      <form onSubmit={CommissionParticularFunction}>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Commission</h5>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Trades</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="tradeCommission"
              value={fields?.tradeCommission}
              min={0}
              onChange={handleChange("tradeCommission")}
            />
            {validationErrors.tradeCommission && (
              <div className="text-danger err">
                {validationErrors.tradeCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Deposit</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="depositCommission"
              min={0}
              value={fields?.depositCommission}
              onChange={handleChange("depositCommission")}
            />
            {validationErrors.depositCommission && (
              <div className="text-danger err">
                {validationErrors.depositCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Withdrawal</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="withdrawCommission"
              min={0}
              value={fields?.withdrawCommission}
              onChange={handleChange("withdrawCommission")}
            />
            {validationErrors.withdrawCommission && (
              <div className="text-danger err">
                {validationErrors.withdrawCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Fee</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="fee"
              min={0}
              value={fields?.fee}
              onChange={handleChange("fee")}
            />
            {validationErrors.fee && (
              <div className="text-danger err">{validationErrors.fee}</div>
            )}
          </div>
        </div>
        {userRole?.role === "SUBADMIN" &&
        userPermissions.hasOwnProperty("userCount") &&
        userPermissions?.userCount?.update ? (
          <button
            type="submit"
            disabled={show ? true : false}
            className="btn btn-gradient-primary me-2"
          >
            {show ? "Wait.." : "Submit"}
          </button>
        ) : userRole?.role === "ADMIN" ? (
          <button
            type="submit"
            disabled={show ? true : false}
            className="btn btn-gradient-primary me-2"
          >
            {show ? "Wait.." : "Submit"}
          </button>
        ) : (
          <button
            type="submit"
            disabled={true}
            className="btn btn-gradient-primary me-2"
          >
            Submit
          </button>
        )}
      </form>
    </>
  );
};
