import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { FORMAT_TEXT } from "helpers/common";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { TESTIMONY, PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import SearchFilter from "components/shared/SearchFilter";
//services
import { DELETE_ALERT } from "helpers/constantsMessage";
import { deleteTestimony_, getTestimony_ } from "store/services/testimony";
import moment from "moment";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });

  const [listing, setListing] = useState([]);
  const [field, setfields] = useState({
    search_date: new Date(),
  });

  async function getData() {
    try {
      let query = {
        ...filter,
      };
      let res = await dispatch(getTestimony_(query));
      setListing(res?.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [filter?.page, filter?.search]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  //delete
  const deleteTestimonyData_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              testimonyId: id,
              status: "deleted",
            };
            await dispatch(deleteTestimony_(query)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  useEffect(() => {
    let objPer = userData["landingPage"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={TESTIMONY.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{TESTIMONY.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.testimony}>{TESTIMONY.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <SearchFilter
                  searchValue=""
                  handleSearchChange={(e) => {
                    setFilter({ ...filter, search: e.target.value });
                  }}
                  clearSerchFilter={() => {
                    setFilter({ ...filter, search: "" });
                  }}
                  options=""
                  SearchText="Search by title"
                  filter={filter}
                  setFilter={setFilter}
                  field={field}
                  setfields={setfields}
                  isAddButton={
                    userRole === "SUBADMIN" &&
                    userSubPermissions !== undefined &&
                    userSubPermissions?.create
                      ? true
                      : userRole === "ADMIN" && userSubPermissions === undefined
                      ? true
                      : false
                  }
                  isSelectBox={false}
                  isInput={true}
                  addLink={Path.testimony_add}
                />
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th> Sr No. </th>
                      <th onClick={() => handleSortingFunc("title")}>
                        {" "}
                        Title{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "title"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "title"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th> Description </th>
                      <th> Rate </th>
                      <th onClick={() => handleSortingFunc("createdAt")}>
                        {" "}
                        Created At{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      {(userRole === "SUBADMIN" &&
                        userSubPermissions !== undefined &&
                        userSubPermissions?.update) ||
                      userSubPermissions?.delete ? (
                        <th> Action</th>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && <th> Action</th>
                      )}{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {listing?.length > 0 &&
                      listing.map((list, i) => {
                        return (
                          <tr key={i} style={{ cursor: "pointer" }}>
                            <td>{i + 1}</td>
                            <td>
                              <img
                                src={list.url}
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "30px",
                                  marginRight: "12px",
                                }}
                              />
                              {FORMAT_TEXT(list.title)}
                            </td>
                            <td>{FORMAT_TEXT(list.description)}</td>
                            <td>{list.rate}</td>
                            <td>{moment(list.createdAt).format("ll")}</td>
                            <td>
                              {userRole === "SUBADMIN" &&
                              userSubPermissions !== undefined ? (
                                <>
                                  {userSubPermissions?.update && (
                                    <>
                                      <Link
                                        to={`${Path.testimony_edit}/${
                                          list?._id
                                        }?position=${
                                          i + 1
                                        }&totalCount=${totalItemsCount}`}
                                      >
                                        <label
                                          class="btn btn-sm"
                                          style={{ cursor: "pointer" }}
                                          title="Edit"
                                        >
                                          <i className="mdi mdi-grease-pencil"></i>
                                        </label>
                                      </Link>
                                      &nbsp;&nbsp;
                                    </>
                                  )}

                                  {userSubPermissions?.delete && (
                                    <>
                                      <label
                                        className="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Delete"
                                        onClick={() =>
                                          deleteTestimonyData_(list?._id)
                                        }
                                      >
                                        <i className="mdi mdi-delete"></i>
                                      </label>
                                    </>
                                  )}
                                </>
                              ) : (
                                userRole === "ADMIN" &&
                                userSubPermissions === undefined && (
                                  <>
                                    <Link
                                      to={`${Path.testimony_edit}/${
                                        list?._id
                                      }?position=${
                                        i + 1
                                      }&totalCount=${totalItemsCount}`}
                                    >
                                      <label
                                        class="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Edit"
                                      >
                                        <i className="mdi mdi-grease-pencil"></i>
                                      </label>
                                    </Link>
                                    &nbsp;&nbsp;
                                    <label
                                      className="btn btn-sm"
                                      style={{ cursor: "pointer" }}
                                      title="Delete"
                                      onClick={() =>
                                        deleteTestimonyData_(list?._id)
                                      }
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </label>
                                  </>
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Testimony.itemsCountPerPage,
    totalItemsCount: state.Testimony.totalItemsCount,
    loader: state.Testimony.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
