import {
  GET_BLOG,
  ADD_BLOG,
  UPDATE_BLOG,
  VIEW_BLOG,
  DELETE_BLOG,
} from "store/actions/blog";

const initialState = {
  isAuthenticated: false,
  loader: false,
  blogList: [],
  blogDetail: {},
  totalItemsCount: 0,
};

const getBlog = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    blogList: data?.data?.data,
    totalItemsCount: data?.data?.totalcount,
  };
  return stateObj;
};

const addBlog = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updateBlog = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const viewBlog = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    blogDetail: payload,
  };
  return stateObj;
};

const deleteBlog = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const Blogs = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_BLOG:
      return getBlog(state, payload);
    case ADD_BLOG:
      return addBlog(state, payload);
    case UPDATE_BLOG:
      return updateBlog(state, payload);
    case VIEW_BLOG:
      return viewBlog(state, payload);
    case DELETE_BLOG:
      return deleteBlog(state, payload);
    default:
      return state;
  }
};
export default Blogs;
