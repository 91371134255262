import Http from "./Http";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "./constantsMessage";
//Post
export function postData(url, data, msz) {
  return new Promise((resolve, reject) => {
    Http.post(url, data, msz)
      .then((res) => {
        if (res && res.data?.status === "success") {
          SUCCESS_MESSAGE(res?.data?.message);
        } else {
          ERROR_MESSAGE(res?.data?.message);
        }
        return resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          const { status, data } = err.response;
          const res = {
            status,
            data,
          };
          if (res && res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          return reject(res);
        } else {
          console.log(err, "-err");
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      })
      .finally(() => {
        // callback(); // Execute the callback function after the request is completed
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  }).catch((err) => {
    // console.log(err, "=======================error")
    if (err && err.data && err.data.message) {
      // ERROR_MESSAGE(err.data.message)
    }
    if (err && err.data == "") {
      // ERROR_MESSAGE('Network Error')
    }
    throw err;
  });
}

export function postData_(url, data, msz) {
  return new Promise((resolve, reject) => {
    Http.post(url, data, msz)
      .then((res) => {
        if (res && res.data?.status === "success") {
          // SUCCESS_MESSAGE(res?.data?.message)
        } else {
          // ERROR_MESSAGE(res?.data?.message)
        }
        return resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          const { status, data } = err.response;
          const res = {
            status,
            data,
          };
          if (res && res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          return reject(res);
        } else {
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      })
      .finally(() => {
        // callback(); // Execute the callback function after the request is completed
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  }).catch((err) => {
    // console.log(err, "=======================error")
    if (err && err.data && err.data.message) {
      // ERROR_MESSAGE(err.data.message)
    }
    if (err && err.data == "") {
      // ERROR_MESSAGE('Network Error')
    }
    throw err;
  });
}
//Get

export function getData(url, params) {
  return new Promise((resolve, reject) => {
    Http.get(url, params)
      .then((res) => {
        if (res && res?.data?.status === "success") {
          // if(data==='alert_true'){
          // SUCCESS_MESSAGE(res?.data?.message)
          // }
        }
        return resolve(res?.data);
      })
      .catch((err) => {
        // console.log(err, '=======================err');
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res?.data?.message);
          }
          return reject(res);
        } else {
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      });
  });
}
export function getData__(url, params) {
  return new Promise((resolve, reject) => {
    Http.get(url, params)
      .then((res) => {
        if (res && res?.data?.status === "success") {
          // if(data==='alert_true'){
          // SUCCESS_MESSAGE(res?.data?.message)
          // }
        }
        return resolve(res?.data);
      })
      .catch((err) => {
        // console.log(err, '=======================err');
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            // ERROR_MESSAGE(res?.data?.message);
          }
          return reject(res);
        } else {
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      });
  });
}

export function postMediaData(url, formData) {
  return new Promise((resolve, reject) => {
    Http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Make sure to set the correct content type
        // Other headers if needed
        // 'Authorization': 'Bearer your_token',
      },
    })
      .then((res) => {
        if (res) {
          // SUCCESS_MESSAGE("Upload Successfully");
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            // ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            // ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}
//deleted
export function deleteDataMethod(url, data, msz) {
  return new Promise((resolve, reject) => {
    Http.delete(url, data, msz)
      .then((res) => {
        if (res && res?.data?.status === "success") {
          // if(data==='alert_true'){
          // SUCCESS_MESSAGE(res?.data?.message)
          // }
        }
        return resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          const { status, data } = err.response;
          const res = {
            status,
            data,
          };
          if (res && res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          return reject(res);
        } else {
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      })
      .finally(() => {
        // callback(); // Execute the callback function after the request is completed
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  }).catch((err) => {
    // console.log(err, "=======================error")
    if (err && err.data && err.data.message) {
      // ERROR_MESSAGE(err.data.message)
    }
    if (err && err.data == "") {
      // ERROR_MESSAGE('Network Error')
    }
    throw err;
  });
}


// put
export function putData(url, data, msz) {
  return new Promise((resolve, reject) => {
    Http.put(url, data, msz) 
      .then((res) => {
        if (res && res.data?.status === "success") {
          // SUCCESS_MESSAGE(res?.data?.message);
        } else {
          // ERROR_MESSAGE(res?.data?.message);
        }
        return resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          const { status, data } = err.response;
          const res = {
            status,
            data,
          };
          if (res && res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          return reject(res);
        } else {
          // console.log(err, "-err");
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          return reject(err);
        }
      })
      .finally(() => {
        // callback(); // Execute the callback function after the request is completed
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  }).catch((err) => {
    // console.log(err, "=======================error")
    if (err && err.data && err.data.message) {
      // ERROR_MESSAGE(err.data.message)
    }
    if (err && err.data == "") {
      // ERROR_MESSAGE('Network Error')
    }
    throw err;
  });
}
