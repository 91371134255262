import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PROMOTIONS } from 'helpers/viewContent';
import { DATE_FORMAT_TIME } from "helpers/common";

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
//services
import { getDetail_ } from 'store/services/commonService';

const Detail = ({ dispatch, match }) => {
    let editkey = match.params.id;
    const [editData, setEditData] = useState({});
    async function getData() {
        try {
            let query = {
                url: "category/view",
                id: editkey
            }
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(getDetail_(query))
            setEditData(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        if (editkey) {
            getData()
        }
    }, []);
    return (
        <>
            <Helmet title={PROMOTIONS.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    {PROMOTIONS.CURRENT_VIEW}
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.promotion}>Promotion</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Detail</li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <p className="card-description"> Category info </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Name</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.name ? editData?.name : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Status</label>
                                        <div className="col-sm-6 col-form-label">
                                            <p className={`badge badge-gradient-${editData?.status == 1 ? 'success' : 'danger'}`}>{editData?.status == 1 ? 'Active' : 'Inactive'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Created At</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.created_at ? DATE_FORMAT_TIME(editData?.created_at) : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToPros = (state) => {
    return {
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(Detail);