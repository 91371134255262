import React from "react";
import Modal from "react-bootstrap/Modal";
import { IMG_LOGO_1, SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { FORMAT_TEXT_VALUE } from "helpers/common";
export default ({ showTradeModal, handleTradeClose, currentTradeValue }) => {
  return (
    <>
      <Modal
        show={showTradeModal}
        onHide={handleTradeClose}
        backdrop="static"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="axis_bank m-auto">
            <img
              src={
                currentTradeValue?.imgUrl
                  ? currentTradeValue?.imgUrl
                  : SIDEBAR_LOGIN_LOGO
              }
              alt=""
              className="img-fluid"
            />
          </div>
          {/* <h2 className="text-center my-2">Axis Bank ltd.</h2> */}
          <div className="axix_modal_content my-3">
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Balance</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentTradeValue?.userDetails?.walletCredit
                      ? (currentTradeValue.userDetails?.walletCredit).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>

              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Qty</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentTradeValue?.qty}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Client Name</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">{`${currentTradeValue?.userDetails?.first_name} ${currentTradeValue?.userDetails?.last_name}`}</label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Name</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentTradeValue?.name}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Id</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentTradeValue?.userDetails?.accountId
                      ? currentTradeValue?.userDetails?.accountId
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Symbol</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentTradeValue?.symbol
                      ? currentTradeValue?.symbol
                      : "N/A"}
                  </label>
                </li>
              </ul>

              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Amount</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentTradeValue?.price
                      ? (currentTradeValue?.price).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Status</strong>
                  </label>
                </li>
                <li>
                  {/* <label className="col-form-label">
                    {currentTradeValue?.status
                      ? currentTradeValue?.status
                      : "N/A"}
                  </label> */}
                  <label className="badge badge-success payment_status">
                    {currentTradeValue?.status
                      ? FORMAT_TEXT_VALUE(currentTradeValue?.status)
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Bought Price</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentTradeValue?.boughtPrice
                      ? (currentTradeValue?.boughtPrice).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>

              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Current Price</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentTradeValue?.current_price
                      ? (currentTradeValue?.current_price).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Equity</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    $
                    {currentTradeValue?.userDetails?.equity
                      ? (currentTradeValue?.userDetails?.equity).toFixed(2)
                      : "0"}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
