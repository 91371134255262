//action types
export const GET_BLOG = "GET_BLOG";
export const ADD_BLOG = "ADD_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const VIEW_BLOG = "VIEW_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";

export function getBlog(data, callback) {
  return {
    type: GET_BLOG,
    payload: { data, callback },
  };
}

export function addBlog(data, callback) {
  return {
    type: ADD_BLOG,
    payload: { data, callback },
  };
}

export function updateBlog(data, callback) {
  return {
    type: UPDATE_BLOG,
    payload: { data, callback },
  };
}

export function viewBlog(data, callback) {
  return {
    type: VIEW_BLOG,
    payload: { data, callback },
  };
}

export function deleteBlog(data, callback) {
  return {
    type: DELETE_BLOG,
    payload: { data, callback },
  };
}
