import { GET_DETAIL, GET_LIST,ADD_DATA, UPDATE_DATA, DELETE_DATA, GET_WITHOUT_PAGI_LIST, UPDATE_DETAIL_PUT} from 'store/actions/common';

const initialState = {
  isAuthenticated: false,
  loader:false,
  getList:[],
  getListpPagi:[],
  getView:{},
  totalItemsCount:0,
};

const getList = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      getList:data?.data?.data,
      totalItemsCount:data?.data?.totalcount
    };
    return stateObj;
};


const getWithoutPaginationList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    getListpPagi:data?.body,
  };
  return stateObj;
};

const getDetail = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    getView:payload?.data,
  };
  return stateObj;
};


const addData = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updateData = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updatePutData = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const deleteData = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const Common = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_LIST:
          return getList(state, payload); 
        case GET_WITHOUT_PAGI_LIST:
          return getWithoutPaginationList(state, payload); 
        case ADD_DATA:
          return addData(state, payload); 
        case GET_DETAIL:
          return getDetail(state, payload); 
        case UPDATE_DATA:
          return updateData(state, payload); 
        case UPDATE_DETAIL_PUT:
          return updatePutData(state, payload); 
        case DELETE_DATA:
          return deleteData(state, payload); 
        default:
        return state;
    };
}
export default Common;
