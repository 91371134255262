export const APP_PATH = "api/v1/admin";
export const COMMON_PATH = "api/v1/adminSetting";
//auth
export const LOGIN = "/adminLogin";
export const LOGOUT = "/logout";
//profile
export const PROFILE = "/profile";
export const UPDATE_PROFILE = "/editAdmin";
export const CHANGE_PASSWORD = "/changePassword";
export const FORGOT_PASSWORD = "/forgotpassword";
//reset password
export const RESET_PASSWORD_FORM = "/reset_password/";
export const RESET_PASSWORD = "/resetpassword";

//file
export const UPLOAD_IMAGE_PATH = "/uploadFile";
export const DELETE_IMAGE_PATH = "/delete_image";
//dashboard
export const GET_DASHBOARD_ = "/api/v1/adminDashboard/getRevenueLineGraph";

//system access
export const GET_ACCESS_LIST = "/admin/getAllUsers";
export const ADD_ACCESS = "/admin/addUser";
export const UPDATE_ACCESS = "/admin/updateUser";
export const DELETE_ACCESS = "/admin/deleteUser";
export const GET_ACCESS_DETAIL = "/admin/getUserDetails";
//cms
export const GET_CMS_LIST = "/contents";
export const UPDATE_CMS = "/contents/update";
export const ADD_CMS = "/contents/add";
export const VIEW_CMS_DETAIL = "/contents/edit/";
export const CMS_DLEETE = "/contents/remove";
//faq
export const GET_FAQ = "/getAllFaqs";
export const ADD_FAQ = "/addFaq";
export const UPDATE_FAQ = "/editFaq";
export const VIEW_FAQ = "/getFaqsById/";
export const DELETE_FAQ = "/deleteFaq";
export const UPDATE_STATUS_FAQ = "/faq/update_status/";

// blogs
export const GET_BLOG = "/blog/getWithFilters";
export const ADD_BLOG = "/blog/create";
export const UPDATE_BLOG = "/blog/update/";
export const VIEW_BLOG = "/blog/detail/";
export const DELETE_BLOG = "/blog/delete/";

// jobs
export const GET_JOB = "/job/getWithFilters";
export const ADD_JOB = "/job/create";
export const UPDATE_JOB = "/job/update/";
export const VIEW_JOB = "/job/detail/";
export const DELETE_JOB = "/job/delete/";

// testimony
export const GET_TESTIMONY = "/testimony/getWithFilters";
export const ADD_TESTIMONY = "/testimony/create";
export const UPDATE_TESTIMONY = "/testimony/update/";
export const VIEW_TESTIMONY = "/testimony/detail/";
export const DELETE_TESTIMONY = "/testimony/delete/";

// feature
export const GET_FEATURE = "/feature/getWithFilters";
export const ADD_FEATURE = "/feature/create";
export const UPDATE_FEATURE = "/feature/update/";
export const VIEW_FEATURE = "/feature/detail/";
export const DELETE_FEATURE = "/feature/delete/";

// basic setting
export const GET_BASICSETTING = "/getBasicSettingInfo";
export const UPDATE_BASICSETTING = "/basicAppSetting";
