import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
//services
import { getList_, updateData_ } from "store/services/commonService";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const StocksList = ({ tabIndex, dispatch, userSubPermissions, userRole }) => {
  const [listing, setListing] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  //filter
  const [filter, setFilter] = useState({
    page: 1,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  async function getData() {
    try {
      let query = {
        ...filter,
        search: searchValue,
        url: "popularStock/getStocksWithFilters",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setTotalCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //filter
  let listToDisplay = listing;
  if (searchValue !== "") {
    const searchTerm = searchValue.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      return itemName?.includes(searchTerm);
    });
  }

  useEffect(() => {
    getData();
  }, [tabIndex, filter, searchValue]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const updateStatus_ = async (list) => {
    try {
      let query = {
        url: `popularStock/stock/update`,
        stockId: list?._id,
        status: list?.status === "active" ? "inactive" : "active",
      };
      console.log(query, "query");
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(updateData_(query));
      dispatch(actions.persist_store({ loader: false }));
      getData();
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };
  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      status: inputValue,
    });
  };
  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      status: "",
    });
    setSearchValue("");
  };

  return (
    <>
      <h4 class="card-title">
        <div class="fillte_main flex-wrap d-flex justify-content-between align-items-center">
          <div class="filter_name">Listing</div>
          <div class="center_fillter d-flex flex-wrap gap-3 align-items-center">
            <div class="selector_fillter d-flex gap-3 align-items-center">
              <div class="filter_drop_filter">
                <select
                  class="form-select filler_search"
                  name="status"
                  onChange={(e) => handleSearchChange(e)}
                >
                  <option value="" disabled={true}>
                    Select{" "}
                  </option>
                  <option
                    value=""
                    selected={filter?.status === "" ? "selected" : ""}
                  >
                    All{" "}
                  </option>
                  <option
                    value="active"
                    selected={filter?.status === "active" ? "selected" : ""}
                  >
                    Active{" "}
                  </option>
                  <option
                    value="inactive"
                    selected={filter?.status === "inactive" ? "selected" : ""}
                  >
                    Inactive{" "}
                  </option>
                </select>
              </div>
            </div>

            <div class="date_time_slector">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    name="search"
                    type="text"
                    placeholder="Search by name"
                    value={searchValue || ""}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  &nbsp;
                  <button
                    onClick={clearSerchFilter}
                    type="button"
                    className="btn btn-gradient-primary btn-sm"
                  >
                    <i class="mdi mdi-close-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </h4>

      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th> Name </th>
              <th> Symbol </th>
              <th> Image </th>
              {userRole === "SUBADMIN" && userSubPermissions !== undefined ? (
                <>{userSubPermissions?.update && <th> Action </th>}</>
              ) : (
                userRole === "ADMIN" &&
                userSubPermissions === undefined && <th> Action </th>
              )}
            </tr>
          </thead>
          <tbody>
            {listToDisplay?.length > 0 &&
              listToDisplay.map((list, i) => {
                return (
                  <tr key={i} style={{ cursor: "pointer" }}>
                    <td>{list?.name ? list?.name : "N/A"}</td>
                    <td>{list?.symbol ? list?.symbol : "N/A"}</td>
                    <td>
                      <div className="symbol_img me-2">
                        <img
                          src={list?.imgUrl ? list?.imgUrl : SIDEBAR_LOGIN_LOGO}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </td>
                    <td>
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined ? (
                        <>
                          {userSubPermissions?.update && (
                            <Toggle
                              className="form-control"
                              id="cheese-status"
                              checked={list?.status === "active" ? true : false}
                              name="status"
                              onChange={() => updateStatus_(list)}
                            />
                          )}
                        </>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && (
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            checked={list?.status === "active" ? true : false}
                            name="status"
                            onChange={() => updateStatus_(list)}
                          />
                        )
                      )}
                    </td>
                    {/* <td onClick={() => updateStatus_(list)}>
                      <label
                        className="badge badge-success payment_status cursor-pointer"
                        title="Update"
                      >
                        {list?.status}
                      </label>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {listing.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT}
            totalItemsCount={totalCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {listing.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.Pages.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StocksList);
