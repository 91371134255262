import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import PhoneInput from "react-phone-input-2";
import { MAIN__DUMMY_ } from 'utils/constant'
import * as Yup from 'yup';
//upload file
import { uploadFile_ } from 'helpers/uploadFile';
//services
import { updateData_ } from 'store/services/commonService';

export default ({ editProfileModal, handleEditProfileClose, editData, dispatch, setEditProfileModal, setisUpdateProfile }) => {
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        mobileNumber: Yup.string().required('Required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
    const [showSubmitBtn, setshowSubmitBtn] = useState(false);
    const [initialFields, setInitialFields] = useState({});

    const [validationErrors, setValidationErrors] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageData, setImageData] = useState({});
    const [fields, setfields] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobileNumber: "",
        countryCode: "",
        profileImage: ""
    })
    const fileInputRef = useRef(null);

    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    const handleFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        let fileName = e.target.name;
        let data = await uploadFile_(dispatch, file, setValidationErrors, setSelectedFile, setPreviewImage, fileName)
        data = data ? data?.url : {};
        setImageData(data);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };

    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                let editedFields = {}
                // Check if the name field has changed
                if (initialFields.first_name !== fields.first_name) {
                    editedFields = {
                        first_name: fields.first_name,
                    };
                } else if (initialFields.last_name !== fields.last_name) {
                    editedFields = {
                        last_name: fields.last_name,
                    };
                } else if(initialFields.email !== fields.email){
                    editedFields = {
                        email: fields.email
                    }
                }else if(initialFields.mobileNumber !== fields.mobileNumber){
                    const phoneNumberWithoutPlus = fields.mobileNumber
                    ? fields.mobileNumber.substring(fields?.countryCode?.length)
                    : null;
                    const codeWithoutPlus = fields?.countryCode;
                    editedFields = {
                        mobileNumber: phoneNumberWithoutPlus,
                        countryCode: fields.countryCode
                        ? codeWithoutPlus?.startsWith("+")
                            ? codeWithoutPlus
                            : "+" + codeWithoutPlus
                        : null,
                    }
                }else if(initialFields.profileImage !== imageData){
                    editedFields = {
                        profileImage: imageData ? imageData : fields?.profileImage,
                    }
                }
                setshowSubmitBtn(true);
                editedFields = {
                    ...editedFields,
                    userId: editData?._id,
                    url: 'editUser',
                }
                await dispatch(updateData_(editedFields));
                setshowSubmitBtn(false);
                setEditProfileModal(false)
                setisUpdateProfile(true)
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            console.log(err);
            setshowSubmitBtn(false);
        }
    };
    

    useEffect(() => {
        let data = {
            first_name: editData?.first_name,
            last_name: editData?.last_name,
            email: editData?.email,
            mobileNumber: `${editData?.countryCode}${editData?.mobileNumber}`,
            profileImage: `${editData?.profileImage}`,
            countryCode: `${editData?.countryCode}`
        }
        setfields(data)
        setInitialFields(data)
        setImageData(`${editData?.profileImage}`)
    }, [editData])

    return (
        <>
            <Modal
                show={editProfileModal}
                onHide={handleEditProfileClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">First Name*</label>
                            <div className="col-md-12">
                                <input type="text" className="form-control" id="exampleInputName1" placeholder="First Name*" name="first_name" onChange={handleChange("first_name")} value={fields.first_name} />
                                {validationErrors.first_name && (
                                    <div className="text-danger err">{validationErrors.first_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Last Name*</label>
                            <div className="col-md-12">
                                <input type="text" className="form-control" id="exampleInputName1" placeholder="Last Name*" name="last_name" onChange={handleChange("last_name")} value={fields.last_name} />
                                {validationErrors.last_name && (
                                    <div className="text-danger err">{validationErrors.last_name}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Email*</label>
                            <div className="col-md-12">
                                <input type="email" className="form-control" id="exampleInputName1" placeholder="Email*" name="email" onChange={handleChange("email")} value={fields.email} />
                                {validationErrors.email && (
                                    <div className="text-danger err">{validationErrors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Mobile Number*</label>
                            <div className="col-md-12">
                                <PhoneInput
                                    enableAreaCodes={true}
                                    name="phone"
                                    className="form-phone_"
                                    country={'us'} // Set the default country, or the one relevant to your use case
                                    value={fields.mobileNumber}
                                    onChange={(value, country) => {
                                        setfields(prevState => ({
                                            ...prevState,
                                            mobileNumber: value,
                                            countryCode: country.dialCode,
                                        }));

                                    }}
                                    maxLength={15}
                                />
                            </div>
                        </div>
                        <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
                            <div className="form-group">
                                <label for="exampleInputEmail3">Profile Picture</label>
                                <input type="file" class="form-control file-upload-info" disabled="" placeholder="Upload Image" style={{ display: 'none' }} name="profileImage" onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                <div className="d-flex">
                                    <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" />
                                    <div className="image-cust">
                                    {previewImage ? (
                                        <img src={previewImage} alt="Preview" className="img-custom" />
                                    ) : editData?.profileImage ? (
                                        <img src={editData?.profileImage} alt="Preview" className="img-custom image-SIZE" />
                                    ) : (
                                        <></>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" onClick={(e) => handleSubmit(e)} disabled={showSubmitBtn ? true : false} title="Update" className="btn btn-gradient-primary me-2">{showSubmitBtn ? 'Wait..' : 'Update'}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleEditProfileClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};