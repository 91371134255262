import {getData, postData, postData_} from 'helpers/Helper'
import { APP_PATH, GET_CMS_LIST, UPDATE_CMS, VIEW_CMS_DETAIL, ADD_CMS, CMS_DLEETE } from './apiPath'
import { getCms, updateCms, viewCms, addCms, deleteCms} from 'store/actions/pages';
import { persist_store } from 'store/actions/index';

export function getCms_(value, callback) {
  let urlPath = `${APP_PATH}${GET_CMS_LIST}`;
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return postData_(urlPath, value)
      .then((data) => {
        dispatch(getCms(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        dispatch(persist_store({ loader: false }));
        console.error('Error fetching data:', error);
      });
  };
}

export function addCms_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${ADD_CMS}`, value)
    .then((data) => {
      dispatch(addCms(data, callback));
      return data;
    });
}


//view
export function viewCms_(value, callback) {
  let urlPath = `${APP_PATH}${VIEW_CMS_DETAIL}${value}`;
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return getData(urlPath, value)
      .then((data) => {
        dispatch(viewCms(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        dispatch(persist_store({ loader: false }));
        console.error('Error fetching data:', error);
      });
  };
}


export function updateCms_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${UPDATE_CMS}`, value)
    .then((data) => {
      dispatch(updateCms(data, callback));
      return data;
    });
}
export function deleteCms_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${CMS_DLEETE}`, value)
    .then((data) => {
      dispatch(deleteCms(data, callback));
      return data;
    });
}
