import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//imported
import * as Path from 'routes/paths';
import { STOCKS, PAGE_LIMIT } from 'helpers/viewContent';
import { DATE_FORMAT_TIME, FORMAT_TEXT_ } from "helpers/common";

//services
import { getList_ } from 'store/services/commonService';

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order:1,
    orderBy: 'createdAt'
  })

  //get data
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: 'log/getWithFilters',
        common: false,
      };
      setIsLoading(true)
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData()
  }, [filter])

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value
      });
    }
  };

  return (
    <>
      <Helmet title={STOCKS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">
          {STOCKS.CURRENT_MODULE}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={Path.manage_stock}>{STOCKS.CURRENT_MODULE}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Listing</li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                Listing
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th> Stock </th>
                      <th> Type </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                 
                  </tbody>
                </table>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Listing);