//action types
export const GET_BASICSETTING = "GET_BASICSETTING";
export const UPDATE_BASICSETTING = "UPDATE_BASICSETTING";

export function getBasicSetting(data, callback) {
  return {
    type: GET_BASICSETTING,
    payload: { data, callback },
  };
}

export function updateBasicSetting(data, callback) {
  return {
    type: UPDATE_BASICSETTING,
    payload: { data, callback },
  };
}
