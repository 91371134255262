import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { REVENUE } from 'helpers/viewContent';
import {  DATE_FORMAT_TIME } from "helpers/common";
//services
import {getDetail_} from 'store/services/commonService';

const Detail = ({dispatch, match}) => {
    let editkey = match.params.id;
    const [editData, setEditData] = useState({});
    async function getData() {
        try{
            let query = {
                url : "match/view",
                id : editkey
            }
           dispatch(actions.persist_store({ loader:true}));
           let res = await dispatch(getDetail_(query))
           setEditData(res?.body)
           dispatch(actions.persist_store({ loader:false}));
        }catch(err) {
           dispatch(actions.persist_store({ loader:false}));
           console.log(err);
       }
    }
    
  useEffect(() => {
    if(editkey){
        getData()
    }
  }, []);

    return(
        <>
            <Helmet title={REVENUE.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
                {REVENUE.CURRENT_VIEW}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.revenue}>{REVENUE.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Detail</li>
                </ol>
              </nav>
            </div>
            
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <p className="card-description"> Match info </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Title</label>
                                        <div className="col-sm-6 col-form-label">
                                           {editData?.match_title ? editData?.match_title : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                    <label className="col-sm-6 col-form-label">Match Location</label>
                                        <div className="col-sm-6 col-form-label">
                                           {editData?.location ? editData?.location : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Match Date</label>
                                        <div className="col-sm-6 col-form-label">
                                           {editData?.match_date ? DATE_FORMAT_TIME(editData?.match_date) : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Match Fee</label>
                                        <div className="col-sm-6 col-form-label">
                                            $ {editData?.match_request?.match_fee ? editData?.match_request?.match_fee : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Status</label>
                                        <div className="col-sm-6 col-form-label">
                                            <label style={{cursor:'pointer'}} className={`badge badge-gradient-${editData?.status === '1' ? 'info' : editData?.status === '2' ? 'success' : 'danger'}`}>{editData?.status === '1' ? 'Ongoing' : editData?.status === '2' ? 'Completed' : 'Pending'}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Created At</label>
                                        <div className="col-sm-6 col-form-label">
                                           {editData?.created_at ? DATE_FORMAT_TIME(editData?.created_at) : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="card-description"> User Detail : </p>
                            <hr/>
                            <p className="card-description"> Host Detail : </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Full Name</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.user?.name ? `${editData?.user?.name}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Email</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user?.email ? `${editData?.user?.email}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Phone</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user?.phone ? `${editData?.user?.country_code} ${editData?.user?.phone}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Age Range</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.user?.preferred_age ? `${editData?.user?.preferred_age}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Height</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.user?.height ? `${editData?.user?.height} inch` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Weight</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user?.weight ? `${editData?.user?.weight} kg` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Age</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user?.preferred_age ? `${editData?.user?.preferred_age}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Playing Surface</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user?.preferred_playing_surface ? `${editData?.user?.preferred_playing_surface}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Score</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player1_score ? `${editData?.match_request?.player1_score}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Image</label>
                                        <div className="col-sm-6 col-form-label">
                                        <a href={editData?.user?.image ? `${editData?.user?.image}` : "#"} target="_blank"><img src={`${editData?.user?.image}`}className="mb-2 rounded img-view" alt="image" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <p className="card-description"> Opponent Detail : </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Full Name</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.match_request?.player2?.name ? `${editData?.match_request?.player2?.name}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Email</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2?.email ? `${editData?.match_request?.player2?.email}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Phone</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2?.phone ? `${editData?.match_request?.player2?.country_code} ${editData?.match_request?.player2?.phone}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Age Range</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.match_request?.player2?.preferred_age ? `${editData?.match_request?.player2?.preferred_age}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Height</label>
                                        <div className="col-sm-6 col-form-label">
                                        {editData?.match_request?.player2?.height ? `${editData?.match_request?.player2?.height} inch` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Weight</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2?.weight ? `${editData?.match_request?.player2?.weight} kg` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Age</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2?.preferred_age ? `${editData?.match_request?.player2?.preferred_age}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Playing Surface</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2?.preferred_playing_surface ? `${editData?.match_request?.player2?.preferred_playing_surface}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Score</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.match_request?.player2_score ? `${editData?.match_request?.player2_score}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Image</label>
                                        <div className="col-sm-6 col-form-label">
                                        <a href={editData?.match_request?.player2?.image ? `${editData?.match_request?.player2?.image}` : "#"} target="_blank"><img src={`${editData?.match_request?.player2?.image}`}className="mb-2 rounded img-view" alt="image" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return{
    }
  };
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
  export default connect(
  mapStateToPros,
  mapDispatchToProps
  )(Detail);