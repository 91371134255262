
//action types
export const GET_LIST = 'GET_LIST';
export const GET_WITHOUT_PAGI_LIST = 'GET_WITHOUT_PAGI_LIST';
export const ADD_DATA = 'ADD_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const DELETE_DATA = 'DELETE_DATA';
export const GET_DETAIL = 'GET_DETAIL';
export const UPDATE_DETAIL_PUT = 'UPDATE_DETAIL_PUT';


export function getList(data, callback) {
    return {
      type: GET_LIST,
      payload : {data, callback},
    };
}
export function getWithoutPaginationList(data, callback) {
  return {
    type: GET_WITHOUT_PAGI_LIST,
    payload : {data, callback},
  };
}

export function getDetail(data, callback) {
  return {
    type: GET_DETAIL,
    payload : {data, callback},
  };
}

export function addData(data, callback) {
  return {
    type: ADD_DATA,
    payload : {data, callback},
  };
}

export function updateData(data, callback) {
  return {
    type: UPDATE_DATA,
    payload : {data, callback},
  };
}

export function deleteData(data, callback) {
  return {
    type: DELETE_DATA,
    payload : {data, callback},
  };
}

export function updatePutData(data, callback) {
  return {
    type: UPDATE_DETAIL_PUT,
    payload : {data, callback},
  };
}
