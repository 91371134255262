import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

//imported
import { AFFILIATE } from 'helpers/viewContent';
import {  DATE_FORMAT_TIME } from "helpers/common";
import * as Path from 'routes/paths';

const Listing = ({dispatch, itemsCountPerPage, totalItemsCount, loader}) => {

    //pagination
    const [activePage, setActivePage] = useState(1);
    const [tabIndex, setTabIndex] = useState(0);

    const data = [
      { name: 'Rohan', email: 'rohan@gmail.com', affiliate_code: '43234$d', amount: '$100', earning: '$566', created_at: '2024-02-08 12:21:32' },
      { name: 'John', email: 'john@gmail.com', affiliate_code: '83234$d', amount: '$100', earning: '$266', created_at: '2024-02-08 12:21:32' },
      { name: 'Emma', email: 'emma@example.com', affiliate_code: '73234$d', amount: '$150', earning: '$566', created_at: '2024-02-09 14:30:45' },
      { name: 'Sophia', email: 'sophia@example.com', affiliate_code: '13234$d', amount: '$120', earning: '$266', created_at: '2024-02-10 09:45:20' },
      { name: 'Michael', email: 'michael@example.com', affiliate_code: '53234$d', amount: '$180', earning: '$566', created_at: '2024-02-11 16:55:40' },
      { name: 'Olivia', email: 'olivia@example.com', affiliate_code: '63234$d', amount: '$90', earning: '$266', created_at: '2024-02-12 11:10:25' },
      { name: 'William', email: 'william@example.com', affiliate_code: '23234$d', amount: '$250', earning: '$566', created_at: '2024-02-13 13:40:30' },
      { name: 'Charlotte', email: 'charlotte@example.com', affiliate_code: '43234$d', amount: '$140', earning: '$266', created_at: '2024-02-14 10:25:55' },
      { name: 'Daniel', email: 'daniel@example.com', affiliate_code: '73234$d', amount: '$170', earning: '$566', created_at: '2024-02-15 15:15:45' },
      { name: 'Ava', email: 'ava@example.com', affiliate_code: '93234$d', amount: '$300', earning: '$266', created_at: '2024-02-16 08:20:35' },
      { name: 'Liam', email: 'liam@example.com', affiliate_code: '33234$d', amount: '$110', earning: '$566', created_at: '2024-02-17 12:30:20' },
      { name: 'Mia', email: 'mia@example.com', affiliate_code: '43234$d', amount: '$220', earning: '$266', created_at: '2024-02-18 14:55:10' },
      { name: 'Alexander', email: 'alexander@example.com', affiliate_code: '53234$d', amount: '$130', earning: '$566', created_at: '2024-02-19 09:45:30' },
      { name: 'Sophie', email: 'sophie@example.com', affiliate_code: '13234$d', amount: '$160', earning: '$266', created_at: '2024-02-20 11:20:25' },
      { name: 'Benjamin', email: 'benjamin@example.com', affiliate_code: '23234$d', amount: '$190', earning: '$566', created_at: '2024-02-21 16:35:40' },
      { name: 'Mia', email: 'mia@example.com', affiliate_code: '43234$d', amount: '$240', earning: '$266', created_at: '2024-02-22 10:10:15' },
      { name: 'Ethan', email: 'ethan@example.com', affiliate_code: '73234$d', amount: '$200', earning: '$566', created_at: '2024-02-23 14:20:30' },
      { name: 'Grace', email: 'grace@example.com', affiliate_code: '93234$d', amount: '$170', earning: '$266', created_at: '2024-02-24 08:45:55' },
      { name: 'Lucas', email: 'lucas@example.com', affiliate_code: '33234$d', amount: '$280', earning: '$566', created_at: '2024-02-25 12:55:45' },
      { name: 'Chloe', email: 'chloe@example.com', affiliate_code: '43234$d', amount: '$150', earning: '$266', created_at: '2024-02-26 11:30:35' }
    ];
    const [listing, setListing] = useState(data);

    useEffect(() => {
    }, [activePage, tabIndex]);
  
    return(
        <>
            <Helmet title={AFFILIATE.CURRENT_MODULE} />
            <div className="page-header">
            <h3 className="page-title">
              {AFFILIATE.CURRENT_MODULE}
            </h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={Path.affiliates}>{AFFILIATE.CURRENT_MODULE}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Listing</li>
                </ol>
              </nav>
            </div>
            <div className="row">
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card graph_card">
                  <div className="card-body">
                    <h4 className="card-title">
                    <div className="row">
                      <div className="col-md-8">
                        Filter By :
                      </div>
                      <div class="form-group col-md-4">
                        <div class="input-group">
                          <input 
                            className="form-control"  
                            autoComplete="chrome-off" 
                            name="searchUnique" 
                            type="text"  
                            placeholder="Search by name" 
                          />
                          &nbsp; <button type="button" className="btn btn-gradient-primary btn-sm"><i class="mdi mdi-close-circle"></i></button>
                        </div>
                        </div>
                    </div>
                    </h4>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                      <TabList>
                        <Tab>Affiliate</Tab>
                      </TabList>
                      <TabPanel>
                      <div class="table-responsive">
                        <table className="table common_th">
                          <thead>
                            <tr>
                              <th> Full Name <i className={`cursor-pointer mdi mdi-arrow-up`}></i></th>
                              <th> Email <i className={`cursor-pointer mdi mdi-arrow-up`}></i></th>
                              <th> Affiliate Code <i className={`cursor-pointer mdi mdi-arrow-up`}></i></th>
                              <th> Amount($) <i className={`cursor-pointer mdi mdi-arrow-up`}></i></th>
                              <th> Date <i className={`cursor-pointer mdi mdi-arrow-up`}></i></th>
                            </tr>
                          </thead>
                          <tbody>
                            {listing?.length> 0 && listing.map((list, i) => {
                              return(
                                <tr key={i}>
                                  <td>{list.name}</td>
                                  <td>{list.email}</td>
                                  <td>{list.affiliate_code}</td>
                                  <td>{list.amount}</td>
                                  <td className="py-1">{list?.created_at ? DATE_FORMAT_TIME(list?.created_at) : 'N/A'}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      </TabPanel>
                      {/* <TabPanel>
                      <h4 className="card-title">Referred Commission Setting</h4>
                        <form className="forms-sample">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label for="exampleInputName1">User Status</label>
                                    <Toggle
                                      className="form-control"
                                      id='cheese-status'
                                      // defaultChecked={this.state.cheeseIsReady}
                                      // onChange={this.handleCheeseChange} 
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="exampleInputName1">User Referred Earning</label>
                                    <input type="text" className="form-control" id="exampleInputName1" placeholder="$532" name="name"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label for="exampleInputName1">Vendor Status</label>
                                    <Toggle
                                      className="form-control"
                                      id='cheese-status'
                                      // defaultChecked={this.state.cheeseIsReady}
                                      // onChange={this.handleCheeseChange} 
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="exampleInputName1">Vendor Referred Earning</label>
                                    <input type="text" className="form-control" id="exampleInputName1" placeholder="$541" name="name"
                                    />
                                </div>
                            </div>
                          
                            <button type="submit" className="btn btn-gradient-primary me-2">Update</button>
                        </form>
                      </TabPanel> */}
                    </Tabs>
                    {/* {listing.length > 0 && (
                      <div className="pagination-box-review">
                          <ReactPagination
                            activePage={activePage}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalItemsCount}
                            handlePageChange={handlePageChange}
                          />
                      </div>
                    )} */}
                    {listing.length  == 0 &&
                      <div className="row">
                        <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
  return{
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(Listing);