import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
//services
import { getList_ } from "store/services/commonService";
import PopularStocksModal from "./PopularStocksModal";

const PopularStocks = ({
  tabIndex,
  dispatch,
  userSubPermissions,
  userRole,
}) => {
  const [listing, setListing] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentStockId, setCurrentStockId] = useState(null);
  const [isUpdateApi, setIsUpdateApi] = useState(false);

  //show popular stock modal
  const [stockMember, setStockMember] = useState(false);
  const handleStockClose = () => {
    setStockMember(false);
    setCurrentStockId(null);
  };
  const handleStockShow = () => setStockMember(true);

  //filter
  const [filter, setFilter] = useState({
    page: 1,
    limit: PAGE_LIMIT,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });
  async function getData() {
    try {
      let query = {
        ...filter,
        url: "popularStock/getPopularStocksWithFilters",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setIsUpdateApi(false);
      setListing(res?.data?.data);
      setTotalCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, [tabIndex, filter, isUpdateApi]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleStockShowEdit = (currentStockId) => {
    setStockMember(true);
    setCurrentStockId(currentStockId?._id);
  };
  return (
    <>
      <h4 class="card-title">
        <div class="fillte_main flex-wrap d-flex justify-content-between align-items-end">
          <div className="filter_name">Listing</div>
          {userRole === "SUBADMIN" && userSubPermissions !== undefined ? (
            <>
              {userSubPermissions?.create && (
                <div
                  class="d-flex flex-wrap d-flex justify-content-end align-items-end"
                  onClick={handleStockShow}
                >
                  <button type="button" class="btn btn-gradient-primary btn-lg">
                    Add Stock
                  </button>
                </div>
              )}
            </>
          ) : (
            userRole === "ADMIN" &&
            userSubPermissions === undefined && (
              <div
                class="d-flex flex-wrap d-flex justify-content-end align-items-end"
                onClick={handleStockShow}
              >
                <button type="button" class="btn btn-gradient-primary btn-lg">
                  Add Stock
                </button>
              </div>
            )
          )}
        </div>
      </h4>
      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th> Name </th>
              <th> Symbol </th>
              <th> Image </th>
              {userRole === "SUBADMIN" && userSubPermissions !== undefined ? (
                <>{userSubPermissions?.update && <th> Action </th>}</>
              ) : (
                userRole === "ADMIN" &&
                userSubPermissions === undefined && <th> Action </th>
              )}
            </tr>
          </thead>
          <tbody>
            {listing?.length > 0 &&
              listing.map((list, i) => {
                return (
                  <tr key={i} style={{ cursor: "pointer" }}>
                    <td>{list?.name ? list?.name : "N/A"}</td>
                    <td>{list?.symbol ? list?.symbol : "N/A"}</td>
                    <td>
                      <div className="symbol_img me-2">
                        <img
                          src={list?.imgUrl ? list?.imgUrl : SIDEBAR_LOGIN_LOGO}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </td>

                    <td>
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined ? (
                        <>
                          {userSubPermissions?.update && (
                            <button
                              type="button"
                              className="edit_card_cta btn btn-sm btn-gradient-primary"
                              title="Edit"
                              onClick={() => handleStockShowEdit(list)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_252_1049)">
                                  <path
                                    d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                                    fill="black"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_252_1049">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </button>
                          )}
                        </>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && (
                          <button
                            type="button"
                            className="edit_card_cta btn btn-sm btn-gradient-primary"
                            title="Edit"
                            onClick={() => handleStockShowEdit(list)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_252_1049)">
                                <path
                                  d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                                  fill="black"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_252_1049">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {listing.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT}
            totalItemsCount={totalCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {listing.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}
      <PopularStocksModal
        dispatch={dispatch}
        stockMember={stockMember}
        handleStockShow={handleStockShow}
        handleStockClose={handleStockClose}
        currentStockId={currentStockId}
        setIsUpdateApi={setIsUpdateApi}
        setStockMember={setStockMember}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.Pages.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PopularStocks);
