//action types
export const GET_FEATURE = "GET_FEATURE";
export const ADD_FEATURE = "ADD_FEATURE";
export const UPDATE_FEATURE = "UPDATE_FEATURE";
export const VIEW_FEATURE = "VIEW_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";

export function getFeature(data, callback) {
  return {
    type: GET_FEATURE,
    payload: { data, callback },
  };
}

export function addFeature(data, callback) {
  return {
    type: ADD_FEATURE,
    payload: { data, callback },
  };
}

export function updateFeature(data, callback) {
  return {
    type: UPDATE_FEATURE,
    payload: { data, callback },
  };
}

export function viewFeature(data, callback) {
  return {
    type: VIEW_FEATURE,
    payload: { data, callback },
  };
}

export function deleteFeature(data, callback) {
  return {
    type: DELETE_FEATURE,
    payload: { data, callback },
  };
}
