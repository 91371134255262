import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { CSVLink } from "react-csv";

export default ({
  searchValue,
  handleSearchChange,
  clearSerchFilter,
  options,
  SearchText,
  isSearchDate,
  field,
  setfields,
  isExportButton,
  isAddButton,
  isSelectBox,
  isInput,
  addLink,
  filter,
  setFilter,
  isAddModalButton,
  openModalAdd,
  isSelectOtherBox,
  selectSearchOptionOther,
  dropdownField,
  key,
  setKey,
  selectSearchOptionStatus,
  isSelectStatusBox,
  statusKey,
  csvData,
  isFutureHide,
  setActivePage,
  isStartEndDateFilter,
}) => {
  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  const handleChangeSelect = async (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      page: 1,
      [dropdownField]: value,
    });
  };

  const handleChangeSelectStatus = async (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [statusKey]: value,
    });
  };

  return (
    <div className="fillte_main flex-wrap d-flex justify-content-between align-items-center">
      <div className="filter_name">Filter By:</div>

      <div className="center_fillter d-flex flex-wrap gap-3 align-items-center">
        <div className="selector_fillter d-flex gap-3 align-items-center">
          {isSelectBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={dropdownField}
                onChange={(e) => handleChangeSelect(e)}
              >
                <option value="" selected>
                  Select{" "}
                </option>
                <option value="">All </option>
                {options?.length > 0 &&
                  options.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[dropdownField] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {isSelectStatusBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={statusKey}
                onChange={(e) => handleChangeSelectStatus(e)}
              >
                <option value="" selected>
                  Select Status
                </option>
                {selectSearchOptionStatus?.length > 0 &&
                  selectSearchOptionStatus.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[statusKey] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {isSelectOtherBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={dropdownField}
                onChange={(e) => handleChangeSelect(e)}
              >
                <option value="" selected disabled>
                  Select{" "}
                </option>
                <option value="">All </option>
                {selectSearchOptionOther?.length > 0 &&
                  selectSearchOptionOther.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[dropdownField] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
        </div>

        <div className="date_time_slector">
          {isSearchDate ? (
            <div class="form-group m-0">
              <div class="input-group">
                <Datetime
                  key={key}
                  value={field.search_date || null}
                  inputProps={{
                    placeholder: "YYYY-MM-DD",
                    readOnly: true,
                    className: "form-control class-time-date-time",
                  }}
                  name="search_date"
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  isValidDate={(current) => {
                    return current.isSameOrBefore(moment(), "day");
                  }}
                  onChange={(selectedDate) =>
                    handleChangePicker(selectedDate, "search_date")
                  }
                  closeOnSelect={true}
                />
                &nbsp;{" "}
                <button
                  type="button"
                  className="btn btn-gradient-primary btn-sm"
                  onClick={clearSerchFilter}
                >
                  <i class="mdi mdi-close-circle"></i>
                </button>
              </div>
            </div>
          ) : (
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  className="form-control"
                  autoComplete="chrome-off"
                  name="searchUnique"
                  type="text"
                  placeholder={SearchText}
                  value={filter?.search}
                  onChange={handleSearchChange}
                />
                &nbsp;{" "}
                <button
                  type="button"
                  className="btn btn-gradient-primary btn-sm"
                  onClick={clearSerchFilter}
                >
                  <i class="mdi mdi-close-circle"></i>
                </button>
              </div>
            </div>
          )}
        </div>
        {isStartEndDateFilter && (
          <div class="fillte_main flex-wrap d-flex gap-3 align-items-end">
            <div class="filter_drop_filter">
              {/* <h6>From Date</h6> */}
              <Datetime
                key={key}
                value={field.startDate || ""}
                inputProps={{
                  placeholder: "From Date DD/MM/YYYY",
                  readOnly: true,
                  className: "form-control class-time-date-time",
                }}
                name="startDate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                onChange={(selectedDate) =>
                  handleChangePicker(selectedDate, "startDate")
                }
                closeOnSelect={true}
              />
            </div>
            <div class="filter_drop_filter">
              {/* <h6>To Date</h6> */}
              <Datetime
                key={key}
                value={field.endDate || ""}
                inputProps={{
                  placeholder: "To Date DD/MM/YYYY",
                  readOnly: true,
                  className: "form-control class-time-date-time",
                }}
                name="endDate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                onChange={(selectedDate) =>
                  handleChangePicker(selectedDate, "endDate")
                }
                closeOnSelect={true}
              />
            </div>
            <div class="filter_drop_filter">
              <button
                type="button"
                class="btn btn-gradient-primary btn-lg px-3"
                onClick={() => {
                  setfields({
                    startDate: "",
                    endDate: "",
                  });
                  setKey((prevKey) => prevKey + 1);
                }}
              >
                Clear Filter
              </button>
            </div>
          </div>
        )}
        {isExportButton && (
          <div className="button_cta">
            <CSVLink data={csvData} className="btn btn-gradient-primary btn-lg">
              Export
            </CSVLink>
            {/* <button type="button" className="btn btn-gradient-primary btn-lg">Export</button> */}
          </div>
        )}
        {isAddButton && (
          <div className="button_cta">
            <Link to={addLink} title="Add">
              <button
                type="button"
                className="d-flex align-items-center btn btn-lg btn-gradient-primary"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z"
                    />
                  </svg>
                </span>
                Add
              </button>
            </Link>
          </div>
        )}
        {isAddModalButton && (
          <div className="button_cta">
            <Link to="#" title="Add" onClick={openModalAdd}>
              <button
                type="button"
                className="d-flex align-items-center btn btn-lg btn-gradient-primary"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z"
                    />
                  </svg>
                </span>
                Add
              </button>
            </Link>
          </div>
        )}
      </div>

      {/* <div className="col-md-2">
            <Link to={Path.user_add} title="Add"><button type="button" class="btn btn-lg btn-gradient-primary"> + Add</button></Link>
            </div> */}
    </div>
  );
};
