import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import * as actions from 'store/actions/index.js';
//services
import { addData_, getList_ } from 'store/services/commonService';

export default ({ assignMember, handleAssignClose, handleAddressShow, dispatch, setAssignMember, editData, setisUpdateProfile }) => {
    const [showBtn, setShowBtn] = useState(false);
    //get staff listing
    const [staffListing, setStaffListing] = useState([]);
    //submit setting data
    const assignMemberFunc = async (id) => {
        try {
            let query = {
                assignId: id,
                userId: editData?._id,
                url: "editUser"
            }
            setShowBtn(true)
            dispatch(actions.persist_store({ loader: true }));
            await dispatch(addData_(query));
            dispatch(actions.persist_store({ loader: false }));
            setShowBtn(false)
            setAssignMember(false)
            setisUpdateProfile(true)
        } catch (err) {
            setShowBtn(false)
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    //get staff listing
    const fetchAssignedStaffData = async () => {
        try {
            let query = {
                limit: 1000,
                orderBy: 'createdAt',
                url: 'getAllAdminList',
                search_string: '',
                page: 1,
                common: false
            };
            let res = await dispatch(getList_(query));
            setStaffListing(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        fetchAssignedStaffData()
    }, [])

    return (
        <>
            <Modal
                show={assignMember}
                onHide={handleAssignClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Members list
                    </Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div class="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th> Name </th>
                                    <th> Email </th>
                                    <th> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {staffListing?.length > 0 && staffListing.map((list, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{list?.name}</td>
                                            <td>{list?.email}</td>
                                            <td>
                                                {editData?.assignId === list._id ? (
                                                    <label title="Assigned">Assigned</label>
                                                ) : (
                                                <label className="btn btn-sm btn-gradient-primary" onClick={() => assignMemberFunc(list?._id)} style={{ cursor: 'pointer' }} title="Assign">Assign</label>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleAssignClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};