import React, { useState, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from 'yup';
//services
import { addData_ } from 'store/services/commonService';

const editorConfiguration = {
    toolbar: [
        'heading', '|',
        'bold', 'italic', 'link', '|',
        'bulletedList', 'numberedList', '|',
        'blockQuote', 'insertTable', '|',
        'mediaEmbed', 'imageInsert', 'fileBrowser', 'exportPdf', 'exportWord', 'exportXml', '|',
        'undo', 'redo'
    ],
    sourceEditing: {
        preview: true, // Enable the preview feature
        highlightActiveLine: true, // Enable highlighting the line the cursor is on
        highlightMatches: true // Enable highlighting matching tag pairs
    }
};
const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    message_type: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
});

const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

export default ({ show, handleClose, dispatch, setShow, editData }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [showBtn, setShowBtn] = useState(false);

    const [fields, setfields] = useState({
        title: "",
        message_type: "",
        message: "",
    })
    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    
    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                fields.email = editData?.email
                let query = {
                    ...fields,
                    url: "sendSupportMessage",
                    byAdmin:true,
                    byUser:false,
                    customerId:editData?._id
                }
                setShowBtn(true)
                await dispatch(addData_(query));
                setShowBtn(false)
                setShow(false)
                setfields({
                    title: "",
                    message: ""
                })
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setShowBtn(false)
            console.log(err);
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Send Message
                    </Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <div className="row">
                                <label for="exampleInputPassword4">Type*</label>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="">
                                        <input type="radio" className="" value={'email'} name="message_type" onChange={handleChange("message_type")} checked={fields.message_type === 'email' ? true : false} /> Email 
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="">
                                        <input type="radio" className=""  value={'push'} name="message_type" onChange={handleChange("message_type")} checked={fields.message_type === 'push' ? true : false}/> Push </label>
                                    </div>
                                </div>
                            </div>
                            {validationErrors.message_type && (
                                <div className="text-danger err">{validationErrors.message_type}</div>
                            )}
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Title*</label>
                            <input type="text" className="form-control" id="exampleInputName1" placeholder="Enter here" name="title" onChange={handleChange("title")} />
                            {validationErrors.title && (
                                <div className="text-danger err">{validationErrors.title}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label for="exampleTextarea1">Description*</label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={fields.message}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setfields(prevState => ({
                                        ...prevState,
                                        message: editor.getData(),
                                    }))
                                    setValidationErrors((prevErrors) => ({ ...prevErrors, message: "" }));
                                }}
                                className="ckeditor-control ckeditor-custom-height"
                            />
                            {validationErrors.message && (
                                <div className="text-danger err">{validationErrors.message}</div>
                            )}
                        </div>
                    </div>
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" onClick={(e) => handleSubmit(e)} disabled={showBtn ? true : false} title="Send" className="btn btn-gradient-primary me-2">{showBtn ? 'Wait..' : 'Send'}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};