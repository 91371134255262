import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
//services
import { updateData_ } from 'store/services/commonService';

export default ({ showAddress, handleAddressClose, editData, dispatch, setShowAddress, setisUpdateProfile }) => {

    const validationSchema = Yup.object().shape({
        location: Yup.string().required('Required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
    const [showSubmitBtn, setshowSubmitBtn] = useState(false);

    const [validationErrors, setValidationErrors] = useState({});
    const [fields, setfields] = useState({
        location: "",
    })
    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    //submit setting data
    const handleSubmitFunc = async () => {
        try {
            // evt.preventDefault();
            // const validation = await validateData(fields);
            // if (validation.isValid) {
                setshowSubmitBtn(true)
                let obj = {
                    url: 'editUser',
                    userId: editData?._id,
                    ...fields
                }
                await dispatch(updateData_(obj));
                setshowSubmitBtn(false)
                setShowAddress(false)
                setisUpdateProfile(true)
            // } else {
            //     setValidationErrors(validation.errors);
            // }
        } catch (err) {
            console.log(err);
            setshowSubmitBtn(false)
        }
    }

    useEffect(() => {
        let data = {
            location: editData?.location,
        }
        setfields(data)
    }, [editData])

    return (
        <>
            <Modal
                show={showAddress}
                onHide={handleAddressClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Address</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Addresss*</label>
                            <textarea type="text" name="location" onChange={handleChange("location")} value={fields.location} className="form-control" id="exampleInputName1" placeholder="Enter here" />
                            {validationErrors.location && (
                                <div className="text-danger err">{validationErrors.location}</div>
                            )}
                        </div>
                    </div>
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" onClick={() => handleSubmitFunc()} disabled={showSubmitBtn ? true : false} title="Update" className="btn btn-gradient-primary me-2">{showSubmitBtn ? 'Wait..' : 'Update'}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleAddressClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};