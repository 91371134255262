import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
//imported
import { USERS } from 'helpers/viewContent';
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { uploadFile_ } from 'helpers/uploadFile';
import { MAIN__DUMMY_ } from 'utils/constant'
//services
import { addData_, getDetail_, updateData_ } from 'store/services/commonService';

const AddEdit = ({ dispatch, match }) => {
    const fileInputRef = useRef(null);
    let editkey = match.params.id;
    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('Full name is required'),
        name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed in the name')
            .required('Full name is required'),
        gender: Yup.string().required('Gender is required'),
        birth_date: Yup.string().required('Birth Date is required'),
        phone: Yup.string().required('Phone number is required'),
        nationality: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed in the nationality').required('Nationality is required'),
        player_statistics: Yup.string().required('Player Statistics is required'),
        player_style: Yup.string().required('Playing Style is required'),
        height: Yup.string().required('Height is required'),
        image: Yup.string().optional(),
        weight: Yup.string().required('Weight is required'),
        preferred_playing_surface: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed').required('Preferred playing surfaces is required'),
        preferred_age: Yup.string().required('Preferred age range is required'),
        location: Yup.string().required('Location is required'),
        status: Yup.string().required('Status is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: !editkey
            ? Yup.string().min(6, 'Password must be at least 6 characters')
            : Yup.string(), // Password is not required if editKey exists

    });

    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };

    const isValidDate = (current) => {
        const currentDate = new Date();
        return current.isBefore(currentDate, "day");
    };

    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [editData, setEditData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageData, setImageData] = useState([]);
    const [changePassword, setChangePassword] = useState(false);
    const [fields, setfields] = useState({
        name: "",
        gender: "",
        birth_date: "",
        nationality: "",
        player_statistics: "",
        player_style: "",
        height: "",
        weight: "",
        image: "",
        bio: "",
        preferred_playing_surface: "",
        preferred_age: "",
        email: "",
        location: "",
        latitude: "30.67995000",
        longitude: "76.72211000",
        phone: "",
        country_code: "",
        password: "",
        status: "1",
    })
    //password
    const [showPassword, setShowPassword] = useState(false);

    //password show
    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    const changePasswordToogle = () => {
        setChangePassword(!changePassword);
    };

    const handleChangePicker = async (selectedDate, name) => {
        // Perform actions based on the selected date and field name
        let dateFormat = selectedDate ? selectedDate.format("YYYY-MM-DD") : "";
        setfields((prev) => ({
            ...prev,
            [name]: dateFormat,
        }));
        setValidationErrors({ [name]: "" });
        // Rest of your code...
    };

    const handleFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        let fileName = e.target.name;
        dispatch(actions.persist_store({ loader: true }));
        setIsShow(true)
        setSending(true)
        let data = await uploadFile_(dispatch, file, setValidationErrors, setSelectedFile, setPreviewImage, fileName)
        data = data?.length > 0 ? data : [];
        dispatch(actions.persist_store({ loader: false }));
        setIsShow(false)
        setSending(false)
        setImageData(data);
    }
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        await uploadFile_(file);
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };

    async function getData() {
        try {
            let query = {
                url: 'user/view',
                id: editkey
            }
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(getDetail_(query));
            setEditData(res?.body);
            setfields(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        if (editkey) {
            getData()
        }
    }, []);

    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                dispatch(actions.persist_store({ loader: true }));
                setIsShow(true)
                fields.image = imageData ? JSON.stringify(imageData) : fields.image
                fields.role = '1'
                let query = {}
                if (editkey) {
                    fields.password_update = changePassword
                    query = {
                        ...fields,
                        url: "user/edit",
                        id: editkey
                    }
                    await dispatch(updateData_(query));
                } else {
                    query = {
                        ...fields,
                        url: "user/add"
                    }
                    await dispatch(addData_(query));
                }
                // return
                setIsShow(false)
                dispatch(actions.persist_store({ loader: false }));
                history.push(Path.users)
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setIsShow(false)
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    return (
        <>
            <Helmet title={USERS.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    {USERS.CURRENT_MODULE}
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.users}>User</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add/Edit</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <h4 className="card-title">Add/Edit</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Full Name*</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder="Full Name*" name="name" onChange={handleChange("name")}
                                            value={fields.name}
                                        />
                                        {validationErrors.name && (
                                            <div className="text-danger err">{validationErrors.name}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">E-mail*</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder="E-mail*" name="email" onChange={handleChange("email")}
                                            value={fields.email}
                                        />
                                        {validationErrors.email && (
                                            <div className="text-danger err">{validationErrors.email}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Gender*</label>
                                        <div className="col-md-12">
                                            <select className="form-select" name="gender" onChange={handleChange("gender")}
                                                value={fields.gender}>
                                                <option value="" selected disabled>Select </option>
                                                <option value="1" selected={fields?.gender === '1' ? 'selected' : ''}>Male </option>
                                                <option value="2" selected={fields?.gender === '2' ? 'selected' : ''}>Female </option>
                                                <option value="3" selected={fields?.gender === '3' ? 'selected' : ''}>Other </option>
                                            </select>
                                            {validationErrors.gender && (
                                                <div className="text-danger err">{validationErrors.gender}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputEmail3">Date of Birth*</label>
                                        <Datetime
                                            value={fields.birth_date || ""}
                                            inputProps={{
                                                placeholder: "YYYY-MM-DD",
                                                readOnly: true,
                                            }}
                                            name="birth_date"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            isValidDate={isValidDate}
                                            onChange={(selectedDate) =>
                                                handleChangePicker(selectedDate, "birth_date")
                                            }
                                            closeOnSelect={true}
                                        />
                                        {validationErrors.birth_date && (
                                            <div className="text-danger err">{validationErrors.birth_date}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group  col-md-6">
                                        <label for="exampleInputPassword4">Nationality*</label>
                                        <input type="text" className="form-control" id="exampleInputPassword4" placeholder="Nationality*" name="nationality" onChange={handleChange("nationality")}
                                            value={fields.nationality} />
                                        {validationErrors.nationality && (
                                            <div className="text-danger err">{validationErrors.nationality}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Phone Number*</label>
                                        <div className="col-md-12">
                                            <PhoneInput
                                                enableAreaCodes={true}
                                                name="phone"
                                                className="form-phone_"
                                                country={'us'} // Set the default country, or the one relevant to your use case
                                                value={fields.phone} // Provide the value for the PhoneInput
                                                onChange={(value, country) => {
                                                    // Handle phone value change
                                                    setfields(prevState => ({
                                                        ...prevState,
                                                        phone: value,
                                                        country_code: country.dialCode,
                                                    }));
                                                    setValidationErrors({ phone: "" });
                                                }}
                                                maxLength={15}
                                            />
                                        </div>
                                        {validationErrors.phone && (
                                            <div className="text-danger err">{validationErrors.phone}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    {editkey &&
                                        <div className="form-group col-md-6">
                                            <label for="exampleInputName1">You want to change password</label>
                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" onClick={changePasswordToogle} /> {changePassword ? 'Yes' : 'No'} <i className="input-helper"></i></label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(changePassword || !editkey) &&
                                        <div className="form-group col-md-6">
                                            <label for="exampleInputName1">Password*</label>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password*" value={fields.password} onChange={handleChange("password")} />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-sm btn-facebook" type="button">
                                                                <i onClick={togglePasswordVisiblity} className={showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {validationErrors.password && (
                                                    <div className="text-danger err">{validationErrors.password}</div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="form-group  col-md-6">
                                        <label for="exampleInputName1">Player Statistics*</label>
                                        <div className="col-md-12">
                                            <select className="form-select" name="player_statistics" onChange={handleChange("player_statistics")}
                                                value={fields.player_statistics}>
                                                <option value="" selected disabled>Select </option>
                                                <option value="plying_hand" selected={fields?.player_statistics === 'plying_hand' ? 'selected' : ''}>Playing Hand: Right-handed / Left-handed </option>
                                                <option value="singles_doubles" selected={fields?.player_statistics === 'singles_doubles' ? 'selected' : ''}>Singles/Doubles Player </option>

                                            </select>
                                            {validationErrors.player_statistics && (
                                                <div className="text-danger err">{validationErrors.player_statistics}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group  col-md-6">
                                        <label for="exampleInputName1">Playing Style*</label>
                                        <div className="col-md-12">
                                            <select className="form-select" name="player_style" onChange={handleChange("player_style")}
                                                value={fields.player_style}>
                                                <option value="" selected disabled>Select </option>
                                                <option value="baseline" selected={fields?.player_style === 'baseline' ? 'selected' : ''}>Baseline </option>
                                                <option value="serve_volley" selected={fields?.player_style === 'serve_volley' ? 'selected' : ''}>Serve and Volley </option>

                                            </select>
                                            {validationErrors.player_style && (
                                                <div className="text-danger err">{validationErrors.player_style}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group  col-md-6">
                                        <label for="exampleInputName1">Height*</label>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="exampleInputName1" placeholder="inch" name="height" onChange={handleChange("height")}
                                                value={fields.height}
                                            />
                                            {validationErrors.height && (
                                                <div className="text-danger err">{validationErrors.height}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Weight*</label>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="exampleInputName1" placeholder="kg" name="weight" onChange={handleChange("weight")}
                                                value={fields.weight}
                                            />
                                            {validationErrors.weight && (
                                                <div className="text-danger err">{validationErrors.weight}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Preferred Playing Surfaces*</label>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="exampleInputName1" placeholder="Preferred Playing Surfaces*" name="preferred_playing_surface" onChange={handleChange("preferred_playing_surface")}
                                                value={fields.preferred_playing_surface}
                                            />
                                            {validationErrors.preferred_playing_surface && (
                                                <div className="text-danger err">{validationErrors.preferred_playing_surface}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Preferred Age Range*</label>
                                        <div className="col-md-12">
                                            <input type="number" className="form-control" id="exampleInputName1" placeholder="Preferred Age Range*" name="preferred_age" onChange={handleChange("preferred_age")}
                                                value={fields.preferred_age}
                                            />
                                            {validationErrors.preferred_age && (
                                                <div className="text-danger err">{validationErrors.preferred_age}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Location*</label>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="exampleInputName1" placeholder="Location*" name="location" onChange={handleChange("location")}
                                                value={fields.location}
                                            />
                                            {validationErrors.location && (
                                                <div className="text-danger err">{validationErrors.location}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Bio</label>
                                        <textarea type="text" className="form-control" id="exampleInputName1" placeholder="Bio" name="bio" onChange={handleChange("bio")}
                                            value={fields.bio}
                                        />
                                        {validationErrors.bio && (
                                            <div className="text-danger err">{validationErrors.bio}</div>
                                        )}
                                    </div>
                                </div>
                                <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
                                    <div className="form-group">
                                        <label for="exampleInputEmail3">Profile Picture</label>
                                        <input type="file" class="form-control file-upload-info" disabled="" placeholder="Upload Image" style={{ display: 'none' }} name="image" onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                        <div>
                                            {previewImage ? (
                                                <img src={previewImage} alt="Preview" className="img-custom" />
                                            ) : editData?.image ? (
                                                <img src={editData?.image} alt="Preview" className="img-custom" />
                                            ) : (
                                                <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" />
                                            )}
                                        </div>
                                        {validationErrors.image && (
                                            <div className="text-danger err">{validationErrors.image}</div>
                                        )}

                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <label for="exampleInputPassword4">Status*</label>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value={'1'} name="status" onChange={handleChange("status")} checked={fields.status === '1' ? true : false} /> Active
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value={'0'} name="status" onChange={handleChange("status")} checked={fields.status === '0' ? true : false} /> Inactive </label>
                                            </div>
                                        </div>
                                    </div>
                                    {validationErrors.status && (
                                        <div className="text-danger err">{validationErrors.status}</div>
                                    )}
                                </div>
                                <button type="submit" disabled={isShow ? true : false} className="btn btn-gradient-primary me-2">{isShow ? 'Wait..' : 'Submit'}</button>
                                <Link to={Path.users}><button className="btn btn-outline-secondary btn-lg">Back</button></Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return {
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(AddEdit);