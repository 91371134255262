import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
//services
import {updateData_} from 'store/services/commonService';
import  FileUploadInput  from 'components/shared/FileUploadInput'
import { UploadMediaFile } from "helpers/uploadFile";

export default ({showDocuments, handleDocumentsClose, editData, dispatch, setisUpdateProfile, setShowDocuments}) => {

    const validationSchema = Yup.object().shape({
        id_photo: Yup.string().required('Required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };

    const [showSubmitBtn, setshowSubmitBtn] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [files, setFiles] = useState(null);

    const [fields, setfields] = useState({
        id_photo: "",
    })

    const handleFileChangeUpload = async (fileVal) => {
        if(fileVal){
            let finalPath = fileVal?.file
            const shouldUpload = finalPath!==undefined && finalPath?.type.startsWith("image/");
            if(shouldUpload){
                let data = await UploadMediaFile(dispatch, finalPath, "users", "image")
                return data ? data?.url : {};
            }else{
                return fileVal;
            }
        }
    }

    //submit setting data
    const handleSubmit = async () => {
        try{
        //   evt.preventDefault();
        //   const validation = await validateData(fields);
        //   if (validation.isValid) {
                setshowSubmitBtn(true)
                let imageUrl = await handleFileChangeUpload(files);
                console.log(imageUrl, "imageUrl")
                if(imageUrl){
                    let obj = {
                        url : 'editUser',
                        userId:editData?._id,
                        id_photo : imageUrl ? imageUrl: fields?.id_photo,
                    }
                    await dispatch(updateData_(obj));
                    setshowSubmitBtn(false)
                    setShowDocuments(false)
                    setisUpdateProfile(true)
                }
            // } else {
            //     setValidationErrors(validation.errors);
            // }
            } catch (err) {
                console.log(err);
                setshowSubmitBtn(false)
            }
    }
    
    useEffect(() => {
        setFiles(`${editData?.id_photo}`)
        setfields({
            id_photo:editData?.id_photo
        })
    }, [editData])

    return(
        <>
            <Modal
                show={showDocuments}
                onHide={handleDocumentsClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Edit Document</Modal.Title>
                </Modal.Header>
                <hr/>
                <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <FileUploadInput dispatch={dispatch} files={files} setFiles={setFiles} fields={fields?.id_photo}/>
                    </div>
                </div>
                <hr />
                </Modal.Body>
                <Modal.Footer>
                <button type="submit"  onClick={() => handleSubmit()}  disabled={showSubmitBtn ? true : false} title="Update" className="btn btn-gradient-primary me-2">{showSubmitBtn ? 'Wait..' : 'Update'}
                </button>
                <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleDocumentsClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
          
        </>

    );
};