import {
    GET_TESTIMONY,
    ADD_TESTIMONY,
    UPDATE_TESTIMONY,
    VIEW_TESTIMONY,
    DELETE_TESTIMONY,
  } from "store/actions/testimony";
  
  const initialState = {
    isAuthenticated: false,
    loader: false,
    testimonyList: [],
    testimonyDetail: {},
    totalItemsCount: 0,
  };
  
  const getTestimony = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      testimonyList: data?.data?.data,
      totalItemsCount: data?.data?.totalcount,
    };
    return stateObj;
  };
  
  const addTestimony = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const updateTestimony = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const viewTestimony = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      testimonyDetail: payload,
    };
    return stateObj;
  };
  
  const deleteTestimony = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const Testimony = (state = initialState, { type, payload = null }) => {
    switch (type) {
      case GET_TESTIMONY:
        return getTestimony(state, payload);
      case ADD_TESTIMONY:
        return addTestimony(state, payload);
      case UPDATE_TESTIMONY:
        return updateTestimony(state, payload);
      case VIEW_TESTIMONY:
        return viewTestimony(state, payload);
      case DELETE_TESTIMONY:
        return deleteTestimony(state, payload);
      default:
        return state;
    }
  };
  export default Testimony;
  