import React, { useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Field, reduxForm, reset } from "redux-form";

//imported
import LoadingButton from "components/shared/LoadingButton";
import { MAIN_LOGO, SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { FORGOT_PASSWORD } from "helpers/viewContent";
import * as Path from "routes/paths";
//services
import { forgotPassword_ } from "store/services/authService";

const renderField = ({
  input,
  placeholder,
  type,
  autoComplete,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      className={`form-control ${touched && error ? `is-invalid` : ""}`}
    />

    {touched && error && <span className="invalid-feedback">{error}</span>}
  </>
);

const ForgotPassword = (props) => {
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { handleSubmit, submitting } = props;

  const [values, setValues] = useState({
    email: "",
  });

  const handleSubmit_ = async (values) => {
    try {
      setSending(true);
      await props.dispatch(forgotPassword_(values)).then((res) => {
        setSending(false);
        props.dispatch(reset("forgotPassword"));
      });
    } catch (err) {
      console.log(err);
      setSending(false);
    }
  };

  return (
    <>
      <Helmet title={FORGOT_PASSWORD.CURRENT_MODULE} />
      <div
        className=" authMainWrpper login-background-color d-flex align-items-center justify-content-center flex-column w-100"
        style={{ minHeight: "100vh" }}
      >
        <div className=" py-4 justify-content-center row align-items-center auth login-background-color w-100">
          <div className="col-lg-8">
            <div className="authLayout bg-white">
              <div className="row align-items-center">
                <div className="col-6 p-0 imgWrpper d-lg-block d-none">
                  <img src={SIDEBAR_LOGIN_LOGO} className=" img-fluid mainImg h-100 w-100" />
                </div>
                <div className="col-lg-6">
                  <div className="auth-form-light text-left  py-5 ps-lg-0  px-3">
                    <div className="brand-logo">
                      <img src={MAIN_LOGO} className="loginLogo" />
                    </div>
                    <h4>{FORGOT_PASSWORD.CURRENT_MODULE}</h4>
                    <h6 className="font-weight-light">{FORGOT_PASSWORD.MAIN_CONTENT}</h6>
                    <form
                      className="pt-3"
                      onSubmit={handleSubmit(handleSubmit_)}
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <Field
                          name="email"
                          component={renderField}
                          placeholder="E-Mail*"
                          type="email"
                          autoComplete="off"
                        />
                      </div>
                      <div className="mt-3">
                        <LoadingButton
                          type="submit"
                          className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn col-md-12"
                          loading={sending}
                          disabled={submitting}
                        >
                          {!sending ? FORGOT_PASSWORD.BUTTON : "Loading..."}
                        </LoadingButton>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <div className="form-check"></div>
                        <Link to={Path.login} className="auth-link text-black">
                          Back to login?
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  const errors = {};
  //email
  if (!values.email) {
    errors.email = "Email Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const ForgotPasswordForm = reduxForm({
  form: "forgotPassword",
  validate,
})(ForgotPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
