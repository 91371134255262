import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BLOGS } from "helpers/viewContent";
import { DATE_FORMAT_TIME } from "helpers/common";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
//services
import { viewBlog_ } from "store/services/blog";

const Detail = ({ dispatch, match }) => {
  let editkey = match.params.id;
  const [editData, setEditData] = useState({});
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(viewBlog_(editkey));
      setEditData(res?.data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);

  return (
    <>
      <Helmet title={BLOGS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{BLOGS.CURRENT_VIEW}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.blogs}>Blog</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              {/* <p className="card-description"> Category info </p> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Title</label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.title ? editData?.title : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Status</label>
                    <div className="col-sm-6 col-form-label">
                      <p
                        className={`badge badge-gradient-${
                          editData?.status == "active" ? "success" : "danger"
                        }`}
                      >
                        {editData?.status == "active" ? "Active" : "Inactive"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.description ? editData?.description : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Created At
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.createdAt
                        ? DATE_FORMAT_TIME(editData?.createdAt)
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Detail);
