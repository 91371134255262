import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Datetime from "react-datetime";
//imported
import ReactPagination from "components/shared/ReactPagination";
import { TRADES, PAGE_LIMIT_VAL } from "helpers/viewContent";
import TradeModal from "./TradeModal";
import SearchFilter from "components/shared/SearchFilter";
import {
  DATE_FORMAT_TIME_,
  closeTradesDatacsv,
  tradesDatacsv,
  tradesSchduleDatacsv,
} from "helpers/common";
//services
import { getList_ } from "store/services/commonService";
import Exposure from "./Exposure";
import moment from "moment";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateApi, setisUpdateApi] = useState(false);
  const [csvDataP, setCsvDataP] = useState([]);
  const [closeTradeCSV, setCloseTradeCSV] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [listingSchedule, setListingSchedule] = useState([]);
  const [listing, setListing] = useState([]);
  const [closeTrades, setCloseTrades] = useState([]);
  const [orderListing, setOrderListing] = useState([]);

  const [currentValue, setCurrentValue] = useState(null);
  //show modal
  const [show, setShow] = useState(false);
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT_VAL,
    search: "",
    status: "opened",
    order: 1,
    orderBy: "createdAt",
  });
  const [field, setfields] = useState({
    startDate: "",
    endDate: "",
  });
  const [key, setKey] = useState(0);

  useEffect(() => {
    clearSerchFilter();
    setfields({
      startDate: "",
      endDate: "",
    });
  }, [tabIndex]);

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemClientName = list?.userDetails?.first_name.toLowerCase();
      const itemaccountId = list?.userDetails?.accountId.toLowerCase();
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm)
      );
    });
  }

  const handleClose = () => {
    setShow(false);
    setCurrentValue(null);
  };
  const handleShow = (list) => {
    setShow(true);
    setCurrentValue(list);
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,

        startDate: field.startDate,
        endDate: field.endDate,
        url: "getUserTradesWithFilter",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      // let arrP = await tradesDatacsv(res?.data?.data);
      // setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchData_ = async () => {
    try {
      let query = {
        search: "",
        status: "",
        order: 1,
        orderBy: "createdAt",
        startDate: "",
        endDate: "",
        url: "getUsersTradesWithOutPaginationFilter",
        search_string: "",
        common: false,
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      let arrP = await tradesDatacsv(res?.data?.data);
      setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchScheduleData = async () => {
    try {
      let query = {
        ...filter,
        startDate: field.startDate,
        endDate: field.endDate,
        url: "getScheduledOrders",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListingSchedule(res?.data?.data);
      let arrP = await tradesSchduleDatacsv(res?.data?.data);
      setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const closeTradeData = async () => {
    try {
      let query = {
        ...filter,
        startDate: field.startDate,
        endDate: field.endDate,
        url: "get/closeCancelTrade",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setCloseTrades(res?.data?.data);
      let arrP = await closeTradesDatacsv(res?.data?.data);
      setCloseTradeCSV(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    // if (tabIndex === 1) {
    //   closeTradeData();
    // } else if (tabIndex === 2) {
    //   fetchOrderData();
    //   // fetchScheduleData();
    // } else {
    //   fetchData();
    //   // fetchScheduleData();
    // }
    fetchOrderData();
  }, [filter, isUpdateApi, tabIndex, field]);

  const fetchOrderData = async () => {
    try {
      let { status, ...filterWithoutStatus } = filter;

      let query = {
        ...filterWithoutStatus,
        startDate: field.startDate,
        endDate: field.endDate,
        url: "account/activitiesJfx",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setOrderListing(res?.data?.data);
      // let arrP = await tradesDatacsv(res?.data?.data);
      // setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "opened",
    });
  };
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    fetchData_();
  }, []);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const selectSearchOption = [
    {
      id: "1",
      value: "opened",
      name: "Open Trades",
    },
    {
      id: "2",
      value: "pending",
      name: "Pending Trades",
    },
    {
      id: "3",
      value: "schedule",
      name: "Schedule Trades",
    },
  ];
  //render html
  // const renderList_ = () => {
  //   return (
  //     listToDisplay?.length > 0 &&
  //     listToDisplay.map((list, i) => (
  //       <tr key={i} style={{ cursor: "pointer" }} title="View">
  //         <td>
  //           {list?.userDetails?.first_name
  //             ? list?.userDetails?.first_name
  //             : "N/A"}
  //         </td>
  //         <td
  //           onClick={() =>
  //             list?.userDetails?.accountId
  //               ? handleShow(list)
  //               : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>
  //             {list?.userDetails?.accountId
  //               ? list?.userDetails?.accountId
  //               : "N/A"}
  //           </b>
  //         </td>

  //         <td
  //           onClick={() =>
  //             list?.createdAt ? handleShow(list) : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>
  //             {list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "N/A"}
  //           </b>
  //         </td>
  //         {/* <td className="green_status">
  //           $
  //           {list?.userDetails?.walletCredit
  //             ? (list.userDetails?.walletCredit).toFixed(2)
  //             : "0"}
  //         </td> */}
  //         {/* <td>
  //           $
  //           {list?.userDetails?.equity
  //             ? (list.userDetails?.equity).toFixed(2)
  //             : "0"}
  //         </td> */}
  //         <td>{list.shortName ? list?.shortName : list.name}</td>
  //         <td>{list.symbol ? list?.symbol : "N/A"}</td>
  //         {filter.status !== "opened" && (
  //           <td>${list?.stockPrice ? list?.stockPrice?.toFixed(2) : "0"}</td>
  //         )}
  //         {filter.status !== "opened" && (
  //           <td className="green_status">
  //             {list?.commission ? list?.commission?.toFixed(2) : "0"}
  //           </td>
  //         )}
  //         {filter.status !== "opened" ? (
  //           <td>${list?.orderPrice ? list?.orderPrice?.toFixed(2) : "0"}</td>
  //         ) : (
  //           <td>
  //             ${list?.avg_entry_price ? list?.avg_entry_price?.toFixed(2) : "0"}
  //           </td>
  //         )}

  //         {/* <td
  //           onClick={() => renderTradesGraph(list?.symbol)}
  //           className="cursor-pointer"
  //           title="View"
  //         >
  //           <b>{list?.symbol}</b>
  //         </td> */}
  //         {/* <td>${list?.boughtPrice ? (list?.boughtPrice).toFixed(2) : "0"}</td> */}
  //         <td>{list?.qty ? list?.qty : "0"}</td>
  //         <td>${list?.amount ? list?.amount?.toFixed(2) : "0"}</td>
  //         {filter.status !== "pending" && (
  //           <td>
  //             <label
  //               className={
  //                 list?.unrealized_pl && list.unrealized_pl < 0
  //                   ? "badge badge-danger"
  //                   : "badge badge-success"
  //               }
  //             >
  //               {list?.unrealized_pl ? list?.unrealized_pl?.toFixed(2) : "0"}
  //             </label>
  //           </td>
  //         )}

  //         {/* <td>{list?.qty ? list.qty.toFixed(2) : "0"}</td> */}
  //       </tr>
  //     ))
  //   );
  // };
  // //render html schedule
  // const renderListS_ = () => {
  //   return (
  //     listingSchedule?.length > 0 &&
  //     listingSchedule.map((list, i) => (
  //       <tr key={i} style={{ cursor: "pointer" }} title="View">
  //         <td>
  //           {list?.userDetails?.first_name
  //             ? list?.userDetails?.first_name
  //             : "N/A"}
  //         </td>
  //         <td
  //           onClick={() =>
  //             list?.userDetails?.accountId
  //               ? handleShow(list)
  //               : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>
  //             {list?.userDetails?.accountId
  //               ? list?.userDetails?.accountId
  //               : "N/A"}
  //           </b>
  //         </td>
  //         <td>{list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "-"}</td>
  //         <td className="green_status">
  //           {list?.commission ? list?.commission?.toFixed(2) : "0"}
  //         </td>
  //         <td>
  //           $
  //           {list?.orderPrice !== 0
  //             ? list?.orderPrice?.toFixed(2)
  //             : list?.price?.toFixed(2)}
  //         </td>
  //         {/* <td>${list?.currentPrice ? (list?.currentPrice).toFixed(2) : "0"}</td> */}
  //         <td>{list.stockName ? list?.stockName : "N/A"}</td>
  //         <td>{list.symbol ? list?.symbol : "N/A"}</td>
  //         <td>${list?.stockPrice ? list?.stockPrice?.toFixed(2) : "0"}</td>
  //         {/* <td
  //           onClick={() => renderTradesGraph(list?.symbol)}
  //           className="cursor-pointer"
  //           title="View"
  //         >
  //           <b>{list?.symbol}</b>
  //         </td> */}
  //         {/* <td>{list?.scheduledType ? list?.scheduledType : "0"}</td> */}
  //         {/* <td>
  //           <label className="badge badge-success payment_status">
  //             {list.status}
  //           </label>
  //         </td> */}
  //         {/* <td>{list.qty ? (list?.qty).toFixed(2) : "0"}</td> */}
  //       </tr>
  //     ))
  //   );
  // };

  const getStatus = (status, list) => {
    if (status === "accepted") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "closed") {
      return <label className={"badge badge-close"}>{status}</label>;
    } else if (status === "accepted" && list?.type === "sell") {
      return <label className={"badge badge-close"}>closed</label>;
    } else {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };

  // const renderCloseList_ = () => {
  //   return (
  //     closeTrades?.length > 0 &&
  //     closeTrades?.map((list, i) => (
  //       <tr key={i} style={{ cursor: "pointer" }} title="View">
  //         <td>
  //           {list?.userDetails?.first_name
  //             ? list?.userDetails?.first_name
  //             : "N/A"}
  //         </td>
  //         <td
  //           onClick={() =>
  //             list?.userDetails?.accountId
  //               ? handleShow(list)
  //               : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>
  //             {list?.userDetails?.accountId
  //               ? list?.userDetails?.accountId
  //               : "N/A"}
  //           </b>
  //         </td>
  //         <td
  //           onClick={() =>
  //             list?._id ? handleShow(list) : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>{list?.orderNumber ? list?.orderNumber : "N/A"}</b>
  //         </td>
  //         <td
  //           onClick={() =>
  //             list?.createdAt ? handleShow(list) : console.log("not exists")
  //           }
  //           title="view"
  //           className="cursor-pointer"
  //         >
  //           <b>
  //             {list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "N/A"}
  //           </b>
  //         </td>
  //         <td>{list.symbol ? list?.symbol : "N/A"}</td>
  //         <td>
  //           ${list?.requestAmount ? list?.requestAmount?.toFixed(2) : "0"}
  //         </td>
  //         <td className="green_status">
  //           {list?.commission ? list?.commission?.toFixed(2) : "0"}
  //         </td>
  //         <td>${list?.requestPrice ? list?.requestPrice?.toFixed(2) : "0"}</td>
  //         <td>{list?.qty ? list?.qty : "0"}</td>
  //         <td>{getStatus(list.status, list)}</td>
  //       </tr>
  //     ))
  //   );
  // };
  const renderOrderList_ = () => {
    return (
      orderListing?.length > 0 &&
      orderListing?.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            <b>
              {list?.activity_mysql_response?.account_id
                ? list?.activity_mysql_response?.account_id
                : "N/A"}
            </b>
          </td>
          <td>
            <b>
              {list?.activity_data?.order_id
                ? list?.activity_data?.order_id
                : "N/A"}
            </b>
          </td>
          <td>
            <b>
              {list?.activity_data?.transaction_time
                ? DATE_FORMAT_TIME_(list?.activity_data?.transaction_time)
                : "N/A"}
            </b>
          </td>
          <td>
            {list.activity_data?.symbol ? list?.activity_data?.symbol : "N/A"}
          </td>
          <td>
            {list?.activity_data?.side ? list?.activity_data?.side : "N/A"}
          </td>
          <td>
            {list?.activity_data?.qty
              ? parseFloat(list?.activity_data?.qty)
              : "0"}
          </td>
          <td>
            $
            {list?.activity_data?.price
              ? parseFloat(list?.activity_data?.price)
              : "0"}
          </td>
          <td>
            <label className="badge badge-success">
              {list?.activity_data?.order_status
                ? list?.activity_data?.order_status
                : "N/A"}
            </label>
          </td>
          {/* <td>
            <b>
              {list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "N/A"}
            </b>
          </td> */}
        </tr>
      ))
    );
  };

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {/* <Tab>Open Trades</Tab>
          <Tab>Close Trades</Tab> */}
          <Tab>Orders</Tab>
          {/* <Tab>Overall Exposure</Tab> */}
        </TabList>{" "}
        <TabPanel>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={searchValue}
                      handleSearchChange={handleSearchChange}
                      clearSerchFilter={clearSerchFilter}
                      SearchText="Search by Symbol/AccountID/OrderID"
                      isSearchDate={false}
                      isExportButton={false}
                      isAddButton={false}
                      isInput={true}
                      filter={filter}
                      setFilter={setFilter}
                      isSelectOtherBox={false}
                      dropdownField="status"
                      csvData={closeTradeCSV}
                      setActivePage={setActivePage}
                      isStartEndDateFilter={true}
                      setfields={setfields}
                      field={field}
                      key={key}
                      setKey={setKey}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("account_id")}>
                            Account ID{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "account_id"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "account_id"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("order_id")}>
                            Trade Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "order_id"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "order_id"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("transaction_time")
                            }
                          >
                            {" "}
                            Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transaction_time"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transaction_time"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("symbol")}>
                            {" "}
                            Symbol{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("side")}>
                            {" "}
                            Side
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "side"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "side"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("qty")}>
                            Quantity{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>

                          <th onClick={() => handleSortingFunc("price")}>
                            Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "price"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "price"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th>Status </th>
                          {/* <th onClick={() => handleSortingFunc("createdAt")}>
                            Created At{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th> */}
                        </tr>
                      </thead>

                      <tbody>{renderOrderList_()}</tbody>
                    </table>
                  </div>

                  {/* {closeTrades.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT_VAL}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {closeTrades.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        {/* <TabPanel>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={searchValue}
                      handleSearchChange={handleSearchChange}
                      clearSerchFilter={clearSerchFilter}
                      options={selectSearchOption}
                      SearchText="Search by client/stock name"
                      isSearchDate={false}
                      isExportButton={true}
                      isAddButton={false}
                      // isSelectBox={true}
                      isInput={true}
                      filter={filter}
                      setFilter={setFilter}
                      isSelectOtherBox={false}
                      dropdownField="status"
                      csvData={csvDataP}
                      setActivePage={setActivePage}
                      isStartEndDateFilter={true}
                      setfields={setfields}
                      field={field}
                      key={key}
                      setKey={setKey}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      {filter.status === "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("first_name")}>
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("createdAt")}>
                              {" "}
                              Registration Date{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "createdAt"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "createdAt"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("commission")}>
                              {" "}
                              Commission{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>

                            <th onClick={() => handleSortingFunc("orderPrice")}>
                              Open Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>

                            <th onClick={() => handleSortingFunc("name")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("stockPrice")}>
                              {" "}
                              Stock Amount
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("symbol")}>
                              {" "}
                              Symbol{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                          </tr>
                        </thead>
                      )}
                      {filter.status !== "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("user_name")}>
                              {" "}
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("createdAt")}>
                              {" "}
                              Order Date{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "createdAt"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "createdAt"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>

                            <th onClick={() => handleSortingFunc("shortName")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "shortName"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "shortName"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("symbol")}>
                              {" "}
                              Symbol{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>

                            {filter.status !== "opened" && (
                              <th
                                onClick={() => handleSortingFunc("stockPrice")}
                              >
                                {" "}
                                Stock Amount
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "stockPrice"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "stockPrice"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                            )}

                            {filter.status !== "opened" && (
                              <th
                                onClick={() => handleSortingFunc("commission")}
                              >
                                Commission{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "commission"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "commission"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                            )}
                            <th
                              onClick={() =>
                                filter.status !== "opened"
                                  ? handleSortingFunc("orderPrice")
                                  : handleSortingFunc("avg_entry_price")
                              }
                            >
                              Average Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy ===
                                    (filter.status !== "opened"
                                      ? "orderPrice"
                                      : "avg_entry_price")
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy ===
                                    (filter.status !== "opened"
                                      ? "orderPrice"
                                      : "avg_entry_price")
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("qty")}>
                              {" "}
                              Qty
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("amount")}>
                              Amount{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "amount"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "amount"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            {filter.status !== "pending" && <th> Open P/L</th>}
                          </tr>
                        </thead>
                      )}
                      {filter.status === "schedule" && (
                        <tbody>{renderListS_()}</tbody>
                      )}
                      {filter.status !== "schedule" && (
                        <tbody>{renderList_()}</tbody>
                      )}
                    </table>
                  </div>{" "}
                  {filter.status !== "schedule" && (
                    <>
                      {listing.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT_VAL}
                            totalItemsCount={totalItemsCount}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listing.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {filter.status === "schedule" && (
                    <>
                      {listingSchedule?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT_VAL}
                            totalItemsCount={totalItemsCount}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listingSchedule?.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel> */}
        {/* <TabPanel>

          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={searchValue}
                      handleSearchChange={handleSearchChange}
                      clearSerchFilter={clearSerchFilter}
                      SearchText="Search by client/stock name"
                      isSearchDate={false}
                      isExportButton={true}
                      isAddButton={false}
                      isInput={true}
                      filter={filter}
                      setFilter={setFilter}
                      isSelectOtherBox={false}
                      dropdownField="status"
                      csvData={closeTradeCSV}
                      setActivePage={setActivePage}
                      isStartEndDateFilter={true}
                      setfields={setfields}
                      field={field}
                      key={key}
                      setKey={setKey}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("user_name")}>
                            Client's Name{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "user_name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "user_name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("accountId")}>
                            Account Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("_id")}>
                            Trade Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "_id"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "_id"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            {" "}
                            Order Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("symbol")}>
                            {" "}
                            Symbol{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("stockPrice")}>
                            {" "}
                            Stock Amount
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "stockPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "stockPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("commission")}>
                            Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "commission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "commission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>

                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Order Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Qty</th>
                          <th onClick={() => handleSortingFunc("status")}>
                            Status{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                        </tr>
                      </thead>

                      <tbody>{renderCloseList_()}</tbody>
                    </table>
                  </div>

                  {closeTrades.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT_VAL}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {closeTrades.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel> */}
      </Tabs>
      <TradeModal
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        dispatch={dispatch}
        setisUpdateApi={setisUpdateApi}
        filterType={filter?.status}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
