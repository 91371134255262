//action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

//auth
export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

//check auth
export function authCheck() {
    return {
      type: AUTH_CHECK,
    };
}

//auth logout
export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

export function profile(payload) {
  return {
    type: PROFILE,
    payload
  };
}

export function updateProfile(payload) {
  return {
    type: UPDATE_PROFILE,
    payload
  };
}

export function updatePassword(payload) {
  return {
    type: UPDATE_PASSWORD,
    payload
  };
}
//forgot password
export function forgotPassword(payload) {
  return {
    type: FORGOT_PASSWORD,
    payload
  };
}

//reset form password
export function resetPasswordForm(payload) {
  return {
    type: RESET_PASSWORD_FORM,
    payload
  };
}

//reset password
export function resetPassword(payload) {
  return {
    type: RESET_PASSWORD,
    payload
  };
}