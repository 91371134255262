import React, { useState, useCallback } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
// imported
import * as Path from 'routes/paths';
import { CHANGE_PASSWORD } from 'helpers/viewContent';
//services
import { updatePassword_ } from 'store/services/authService';

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required").min(6, "Password must be at most 6 characters long"),
    newPassword: Yup.string().required("Required").min(6, "Password must be at most 6 characters long").notOneOf([Yup.ref("oldPassword")], "New password must be different from the old password"),
    confirmPassword: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});


const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

const ChangePassword = ({ dispatch, userProfile }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);

    const [fields, setfields] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })
    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                setSending(true)
                await dispatch(updatePassword_(fields));
                setSending(false)
                setfields({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                })
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            console.log(err);
            setSending(false)
        }
    }

    return (
        <>
            <Helmet title={CHANGE_PASSWORD.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    Change Password
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.change_password}>{CHANGE_PASSWORD.CURRENT_MODULE}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"></li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <div className="card graph_card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label for="exampleInputName1">Old Password*</label>
                                    <input type="password" className="form-control" id="exampleInputName1" placeholder="******" name="oldPassword" onChange={handleChange("oldPassword")}
                                        value={fields.oldPassword}
                                    />
                                    {validationErrors.oldPassword && (
                                        <div className="text-danger err">{validationErrors.oldPassword}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputName1">New Password*</label>
                                    <input type="password" className="form-control" id="exampleInputName1" placeholder="******" name="newPassword" onChange={handleChange("newPassword")}
                                        value={fields.newPassword}
                                    />
                                    {validationErrors.newPassword && (
                                        <div className="text-danger err">{validationErrors.newPassword}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputName1">Confirm Password*</label>
                                    <input type="password" className="form-control" id="exampleInputName1" placeholder="******" name="confirmPassword" onChange={handleChange("confirmPassword")}
                                        value={fields.confirmPassword}
                                    />
                                    {validationErrors.confirmPassword && (
                                        <div className="text-danger err">{validationErrors.confirmPassword}</div>
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" disabled={sending ? true : false} className="btn btn-gradient-primary btn-lg btn-block">{sending ? 'Loading...' : 'Update'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
const mapStateToPros = (state) => {
    return {
        userProfile: state.Auth.user
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(ChangePassword);