import React, { useState, useEffect, } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
//imported
import { useModules } from "helpers/modules";
import { MAIN_LOGO, MAIN_LOGO_DUMMY_ } from "utils/constant";
import * as actions from "store/actions/index.js";

const Sidebar = ({ dispatch, isOpen, user, userProfile, activeModuleNameVal}) => {
  let userRole = user?.user ? user?.user : userProfile;

  const [modules, setModules] = useState(useModules);
  const [dbmodules, setDbModules] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [suburls, setSuburls] = useState(null);

  //history
  const history = useHistory();

  const pathname = history.location.pathname.split("/");

  const filtered = pathname.filter(function (el) {
    if (el !== "") {
      return el;
    }
  });

  let path = "/";

  if (filtered.length >= 3) {
    path += filtered[0] + "/" + filtered[1] + "/" + filtered[2];
  } else {
    path += filtered[0] + "/" + filtered[1] ?? "";
  }
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  useEffect(() => {
    let userPermission =
      userProfile && userProfile?.permissions?.length > 0
        ? JSON.parse(userProfile?.permissions)
        : {};
    let userData =
      user?.user?.permissions && user?.user?.permissions?.length > 0
        ? JSON.parse(user?.user?.permissions)
        : userPermission;
    setUserPermissions(userData);
  }, [userRole]);

  const hasPermission = (moduleName, action) => {
    return userPermissions[moduleName]?.[action] || false;
  };
  const [addModuleCss, setAddModuleCss] = useState(false);
  const onClickToggleCloseOpenTab = () => {
    setAddModuleCss(!addModuleCss);
  };
  const updateSidebarOption = (mod, i) =>{
    dispatch(actions.persist_store({ activeModuleNameVal: mod }));
  }
  const updateSidebarSubOption = (mod) =>{
    dispatch(actions.persist_store({ activeModuleNameVal: mod }));
  }
  const updateSidebarOptionTRemoved = () =>{
    dispatch(actions.persist_store({ activeModuleNameVal: null }));
  }

 // Function to toggle the dropdown state
  const toggleDropdown = (index) => {
    setActiveDropdownIndex(activeDropdownIndex === index ? null : index);
  };
  useEffect(() => {
    // Find the index of the dropdown associated with the current pathname
    const index = modules.findIndex(mod => mod?.sub_modules_urls?.some(subMod => subMod.url === path));
    if (index !== -1) {
      setActiveDropdownIndex(index);
    }
  }, [path, modules]);

  return (
    <>
      <nav
        className={`sidebar sidebar-offcanvas sidebar-back ${isOpen ? "active" : ""
          }`}
        id="sidebar"
      >
        <a className="navbar-brand brand-logo" href="#">
          <img src={MAIN_LOGO} alt="logo" style={{ height: "60px" }} />
        </a>

        <ul className="nav">
          {modules?.length > 0 &&
            modules.map((mod, i) => {
              dbmodules.filter((val) => {
                if (val === mod.model_name) {
                  mod.is_permission = false;
                }
                if(mod.model_name === 'landingPage'){
                  // console.log("here", suburls)
                  mod.model_name = suburls
                }
              });
              let submodules = mod.sub_modules ? mod?.sub_modules : "";
              const shouldShowModule =
                !mod.is_permission ||
                (hasPermission(mod.model_name, "view") &&
                  userRole?.role === "SUBADMIN");
              if (shouldShowModule) {
                return (
                  <>
                    {/* {!mod.is_dropdown && (
                      <li
                        key={i}
                        className={classNames("nav-item", {
                          active:
                            path === mod.url ||
                            path === submodules?.add ||
                            path === submodules?.edit ||
                            path === submodules?.edit_form ||
                            path === submodules?.view ||
                            path === submodules?.view_form,
                        })}
                      >
                        <Link
                          className="nav-link"
                          to={mod.url}
                          title={mod.name}
                        >
                          <span className="menu-title">{mod.name}</span>
                          <i className={mod.icon}></i>
                        </Link>
                      </li>
                    )}
                    {mod.is_dropdown && (
                      <li
                        className={classNames("nav-item newDropdown", {
                          active:
                            path === mod.url ||
                            path === submodules?.add ||
                            path === submodules?.edit ||
                            path === submodules?.edit_form ||
                            path === submodules?.view ||
                            path === submodules?.view_form,
                        })}
                      >
                        <Link
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickToggleCloseOpenTab();
                          }}
                          to="#"
                          className={`nav-link ${!addModuleCss ? "collapsed" : "dropdown-sk"
                            }`}
                          title={mod.name}
                        >
                          <span className="menu-title">{mod.name}</span>
                          <i className="menu-arrow"></i>
                          <i className={mod.icon}></i>
                        </Link>

                        <div
                          className={`collapse ${addModuleCss ? "show " : ""}`}
                          id="ui-basic"
                        >
                          <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                              <Link
                                className="nav-link dropdown-sk"
                                to={mod?.sub_modules_urls?.url_1}
                              >
                                {mod?.sub_name_1}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )} */}
                     {!mod.is_dropdown && (
                      <li
                        className={classNames("nav-item ", {
                          active:
                            path === mod.url ||
                            path === submodules?.add ||
                            path === submodules?.edit ||
                            path === submodules?.edit_form ||
                            path === submodules?.view ||
                            path === submodules?.view_form,
                        })}
                        onClick={(event) => {
                          event.preventDefault();
                          updateSidebarOptionTRemoved()
                        }}
                      >
                        <Link
                          className="nav-link "
                          to={mod.url}
                          title={mod.name}
                        >
                          <span className="menu-title">{mod.name}</span>
                          <i className={mod.icon}></i>
                        </Link>
                      </li>
                    )}
                    {mod.is_dropdown && (
                      <li class="nav-item">
                        <Link class="nav-link collapsed" title={mod.name} to="#"  onClick={() => {
                          updateSidebarOption(mod.sub_model_name, i)
                          toggleDropdown(i);
                          }}>
                          <span class="menu-title mm-link">{mod.name}</span>
                          <i class="menu-arrow"></i>
                          <i class="mdi mdi-format-list-bulleted menu-icon"></i>
                        </Link>
                          <div className={`collapse ${activeDropdownIndex === i ? 'show' : ''}`}>
                          {mod?.sub_modules_urls?.length>0 && mod?.sub_modules_urls.map((subMod) => {
                            return(
                              <Link onClick={() => {
                                  updateSidebarSubOption(subMod.sub_model_name)
                                }} className={`nav-link ${(mod?.sub_model_name === activeModuleNameVal && subMod.model_name === pathname[2]) ? 'active' : ''}`} title={subMod?.name} to={subMod?.url}style={{ marginLeft: 16, marginRight: 16 }}><span class="menu-title">
                                {subMod?.name}</span>
                              <i class={subMod?.icon}></i>
                              </Link>
                            )
                          })}
                        </div>
                      </li>
                    )}
                  </>
                );
              } else if (mod.is_permission && userRole?.role === "ADMIN") {
            
                return (
                  <>
                    {!mod.is_dropdown && (
                      <li
                        className={classNames("nav-item ", {
                          active:
                            path === mod.url ||
                            path === submodules?.add ||
                            path === submodules?.edit ||
                            path === submodules?.edit_form ||
                            path === submodules?.view ||
                            path === submodules?.view_form,
                        })}
                        onClick={(event) => {
                          event.preventDefault();
                          updateSidebarOptionTRemoved()
                        }}
                      >
                        <Link
                          className="nav-link "
                          to={mod.url}
                          title={mod.name}
                        >
                          <span className="menu-title">{mod.name}</span>
                          <i className={mod.icon}></i>
                        </Link>
                      </li>
                    )}
                    {mod.is_dropdown && (
                      <li class="nav-item">
                        <Link class="nav-link collapsed" title={mod.name} to="#"  onClick={() => {
                          updateSidebarOption(mod.sub_model_name, i)
                          toggleDropdown(i);
                          }}>
                          <span class="menu-title mm-link">{mod.name}</span>
                          <i class="menu-arrow"></i>
                          <i class="mdi mdi-format-list-bulleted menu-icon"></i>
                        </Link>
                          <div className={`collapse ${activeDropdownIndex === i ? 'show' : ''}`}>
                          {mod?.sub_modules_urls?.length>0 && mod?.sub_modules_urls.map((subMod) => {
                            return(
                              <Link onClick={() => {
                                  updateSidebarSubOption(subMod.sub_model_name)
                                }} className={`nav-link ${(mod?.sub_model_name === activeModuleNameVal && subMod.model_name === pathname[2]) ? 'active' : ''}`} title={subMod?.name} to={subMod?.url}style={{ marginLeft: 16, marginRight: 16 }}><span class="menu-title">
                                {subMod?.name}</span>
                              <i class={subMod?.icon}></i>
                              </Link>
                            )
                          })}
                        </div>
                      </li>
                    )}
                  </>
                );
              }
            })}
        </ul>
      </nav>
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    loader: state.persistStore.loader,
    activeModuleNameVal: state.persistStore.activeModuleNameVal,
    user: state.Auth,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Sidebar);
