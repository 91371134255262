import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FORMAT_TEXT, DATE_FORMAT } from "helpers/common";
import { getList_ } from 'store/services/commonService';

export default ({ dispatch, openModalHistoryAdd, setisDeleted, isDeleted }) => {

    //get data
    const [pendingtaskList, setpendingtaskList] = useState([]);
    const [todaytaskList, settodaytaskList] = useState([]);
    const [tomorrowtaskList, settomorrowtaskList] = useState([]);
    let pendingtask = pendingtaskList?.length > 0 ? pendingtaskList[0] : ''
    let todaytask = todaytaskList?.length > 0 ? todaytaskList[0] : ''
    let tomorrowtask = tomorrowtaskList?.length > 0 ? tomorrowtaskList[0] : ''
    //fetch pending requests
    const fetchpendingData = async () => {
        try {
            let query = {
                page: 1,
                limit: 5,
                orderBy: 'createdAt',
                type: 'overdue',
                url: 'task/getWithFilter',
                common: false,
            };
            let res = await dispatch(getList_(query));
            setisDeleted(false)
            setpendingtaskList(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    }
    //fetch today requests
    const fetchtodayData = async () => {
        try {
            let query = {
                page: 1,
                limit: 5,
                orderBy: 'createdAt',
                type: 'today',
                url: 'task/getWithFilter',
                common: false,
            };
            let res = await dispatch(getList_(query));
            setisDeleted(false)
            settodaytaskList(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    }
    //fetch future requests
    const fetchTomorrowData = async () => {
        try {
            let query = {
                page: 1,
                limit: 5,
                orderBy: 'createdAt',
                type: 'tomorrow',
                url: 'task/getWithFilter',
                common: false,
            };
            let res = await dispatch(getList_(query));
            setisDeleted(false)
            settomorrowtaskList(res?.data?.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchpendingData()
        fetchtodayData()
        fetchTomorrowData()
    }, [isDeleted])

    return (
        <>
            <div className="row">
                <div className="col-md-4 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <h4 className="card-title"> <i class="mdi mdi-checkbox-blank-circle primary"></i> Overdue Task</h4>
                            {pendingtask ? (
                                <>
                                    <div className="media">
                                        <div className="media-body task primary">
                                            <p className="card-text">{DATE_FORMAT(pendingtask?.endDate)} - {pendingtask?.subjectDetails?.name !== null ? `(${pendingtask?.subjectDetails?.name})` : FORMAT_TEXT(pendingtask?.subjectDetails?.email)} </p>
                                            {pendingtask?.title}
                                        </div>
                                    </div>
                                    <Link to="#" onClick={() => openModalHistoryAdd('overdue', pendingtaskList)}><p className="float-right">view more...</p></Link>
                                </>
                            ) : (
                                <div className="media-body task primary">
                                    <p>No more task available...</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title"><i class="mdi mdi-checkbox-blank-circle success"></i>  Today Task</h4>
                            {todaytask ? (
                                <>
                                    <div className="media">
                                        <div className="media-body task success">
                                            <p className="card-text">{DATE_FORMAT(todaytask?.endDate)} - {todaytask?.subjectDetails?.name !== null ? `(${todaytask?.subjectDetails?.name})` : FORMAT_TEXT(todaytask?.subjectDetails?.email)} </p>
                                            {todaytask?.title}
                                        </div>
                                    </div>
                                    <Link to="#" onClick={() => openModalHistoryAdd('today', todaytaskList)}><p className="float-right">view more...</p></Link>
                                </>
                            ) : (
                                <div className="media-body task success">
                                    <p>No more task available...</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title"><i class="mdi mdi-checkbox-blank-circle info"></i>  Tomorrow To-dos</h4>
                            {tomorrowtask ? (
                                <>
                                    <div className="media">
                                        <div className="media-body task info">
                                            <p className="card-text">{DATE_FORMAT(tomorrowtask?.endDate)} - {tomorrowtask?.subjectDetails?.name !== null ? `(${tomorrowtask?.subjectDetails?.name})` : FORMAT_TEXT(tomorrowtask?.subjectDetails?.email)} </p>
                                            {tomorrowtask?.title}
                                        </div>
                                    </div>
                                    <Link to="#" onClick={() => openModalHistoryAdd('tomorrow', tomorrowtaskList)}><p className="float-right">view more...</p></Link>
                                </>
                            ) : (
                                <div className="media-body task info">
                                    <p>No more task available...</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};