//action types
export const GET_TESTIMONY = "GET_TESTIMONY";
export const ADD_TESTIMONY = "ADD_TESTIMONY";
export const UPDATE_TESTIMONY = "UPDATE_TESTIMONY";
export const VIEW_TESTIMONY = "VIEW_TESTIMONY";
export const DELETE_TESTIMONY = "DELETE_TESTIMONY";

export function getTestimony(data, callback) {
  return {
    type: GET_TESTIMONY,
    payload: { data, callback },
  };
}

export function addTestimony(data, callback) {
  return {
    type: ADD_TESTIMONY,
    payload: { data, callback },
  };
}

export function updateTestimony(data, callback) {
  return {
    type: UPDATE_TESTIMONY,
    payload: { data, callback },
  };
}

export function viewTestimony(data, callback) {
  return {
    type: VIEW_TESTIMONY,
    payload: { data, callback },
  };
}

export function deleteTestimony(data, callback) {
  return {
    type: DELETE_TESTIMONY,
    payload: { data, callback },
  };
}
