import React, { useState, useEffect, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { PAGE_LIMIT_ } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import { debounce } from "lodash";

import * as actions from "store/actions/index.js";
//services
import {
  addData_,
  getList_,
  updatePutData_,
} from "store/services/commonService";

export default ({
  dispatch,
  stockMember,
  handleStockShow,
  handleStockClose,
  currentStockId,
  setIsUpdateApi,
  setStockMember,
}) => {
  //filter
  const [filter, setFilter] = useState({
    page: 1,
    limit: PAGE_LIMIT_,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [showBtn, setShowBtn] = useState(false);
  //get stocks listing
  const [stockListing, setStockListing] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  //filter
  let listToDisplay = stockListing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = stockListing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      return itemName?.includes(searchTerm);
    });
  }

  //submit setting data
  const getPopularStockValUpdate = async (id) => {
    try {
      let query = {};
      dispatch(actions.persist_store({ loader: true }));
      if (currentStockId !== null) {
        query = {
          url: `popularStock/update/${currentStockId}`,
          stockId: id,
        };
        await dispatch(updatePutData_(query));
      } else {
        query = {
          url: "popularStock/create",
          stockId: id,
        };
        await dispatch(addData_(query));
      }
      dispatch(actions.persist_store({ loader: false }));
      setStockMember(false);
      setIsUpdateApi(true);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  };

  //get stocks listing
  const fetchedAssignedStocks = async () => {
    try {
      let query = {
        ...filter,
        status: "active",
        url: "popularStock/getStocksWithFilters",
      };
      let res = await dispatch(getList_(query));
      setStockListing(res?.data?.data);
      setTotalCount(res?.data?.totalcount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchedAssignedStocks();
  }, [filter]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      search: "",
    });
  };

  return (
    <>
      <Modal
        show={stockMember}
        onHide={handleStockClose}
        backdrop="static"
        keyboard={false}
        className="modalWidthCstm"
      >
        <Modal.Header>
          <Modal.Title>Stock list</Modal.Title>
          <h4 class="card-title">
            <div class="fillte_main flex-wrap d-flex justify-content-between align-items-end">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    className="form-control"
                    autoComplete="chrome-off"
                    name="searchUnique"
                    type="text"
                    placeholder="search by name"
                    value={filter?.search}
                    onChange={handleSearchChange}
                  />
                  &nbsp;{" "}
                  <button
                    onClick={clearSerchFilter}
                    type="button"
                    className="btn btn-gradient-primary btn-sm"
                  >
                    <i class="mdi mdi-close-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </h4>
        </Modal.Header>

        <Modal.Body>
          <div class="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> Name </th>
                  <th> Symbol </th>
                </tr>
              </thead>
              <tbody>
                {listToDisplay?.length > 0 &&
                  listToDisplay.map((list, i) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        key={i}
                        title="Submit"
                        onClick={() => getPopularStockValUpdate(list?._id)}
                      >
                        <td>{list?.name}</td>
                        <td>{list?.symbol}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <hr />
          {stockListing.length > 0 && (
            <div className="pagination-box-review">
              <ReactPagination
                activePage={filter?.page}
                itemsCountPerPage={PAGE_LIMIT_}
                totalItemsCount={totalCount}
                handlePageChange={handlePageChange}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-lg btn-outline-secondary"
            title="Cancel"
            onClick={handleStockClose}
          >
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
