import React, { useState, useCallback } from "react";
import { PAGE_LIMIT } from 'helpers/viewContent';
import ReactPagination from "components/shared/ReactPagination";
import { DATE_FORMAT_TIME_NOTES, DATE_FORMAT_FOR_DB } from "helpers/common";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as actions from 'store/actions/index.js';
import * as Yup from 'yup';
//services
import { addData_ } from 'store/services/commonService';

const validationSchema = Yup.object().shape({
    note: Yup.string().required('Required'),
});

const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};
export default ({ dispatch, editkey, setisUpdateProfile, editData }) => {
    //pagination
    const [activePage, setActivePage] = useState(1);
    const [filter, setFilter] = useState({
        page: activePage,
        limit: PAGE_LIMIT,
        search: '',
        orderBy: 'createdAt',
        subjectId: editkey,
        type: ''
    })
    const [validationErrors, setValidationErrors] = useState({});

    const [commentFields, setCommentFields] = useState({
        note: "",
        date: new Date(),
        customerId: editkey,
    })
    const handleChange = useCallback(
        name => evt => {
            setCommentFields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    const handleChangePicker = async (selectedDate, name) => {
        // Perform actions based on the selected date and field name
        let dateFormat = selectedDate ? selectedDate.format("YYYY-MM-DD") : "";
        setCommentFields((prev) => ({
            ...prev,
            [name]: dateFormat,
        }));
    };
    //submit comment data
    const handleSubmitComment = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(commentFields);
            if (validation.isValid) {
                dispatch(actions.persist_store({ loader: true }));
                if (commentFields.date === '') {
                    delete commentFields.date
                }
                let dateValue = commentFields?.date
                let query = {
                    ...commentFields,
                    customerId: editkey,
                    date: DATE_FORMAT_FOR_DB(dateValue),
                    url: "add/comment"
                }
                await dispatch(addData_(query));
                setisUpdateProfile(true)
                setCommentFields({
                    note: "",
                    date: new Date(),
                    customerId: "",
                })
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    // const currentItems =  editData?.comments;

    const currentItems = editData?.comments?.length > 0 && editData?.comments?.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = pageNumber => setActivePage(pageNumber);
    return (
        <>
            <div className="col-lg-8 grid-margin stretch-card">
                <div className="col-md-12">
                    <div className="card graph_card border_custom">
                        <div className="card-body">
                            <h3 className="card-title margin-bottom">Backoffice Comment</h3>

                            <form className="forms-sample" onSubmit={handleSubmitComment}>
                                <div className="form-group col-md-12">
                                    <label for="exampleInputEmail3">Comment Date (Optional)</label>
                                    <Datetime
                                        value={commentFields.date || ""}
                                        inputProps={{
                                            placeholder: "YYYY-MM-DD",
                                            readOnly: true,
                                        }}
                                        name="date"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        onChange={(selectedDate) =>
                                            handleChangePicker(selectedDate, "date")
                                        }
                                        closeOnSelect={true}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label for="exampleInputName1">Comment*</label>
                                    <textarea type="text" className="form-control text-area-height" id="exampleInputName1" placeholder="Comment" name="note" onChange={handleChange("note")}
                                        value={commentFields.note}
                                    />
                                    {validationErrors.note && (
                                        <div className="text-danger err">{validationErrors.note}</div>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-gradient-primary me-2">Comment</button>
                            </form>
                            <div className="media">
                                {currentItems?.length > 0 && currentItems?.map((tab, i) => {
                                    return (
                                        <>
                                            <div className="media-body task-notes" key={i}>
                                                <div className="d-flex task-notes-list justify-content-between">
                                                    <div className="d-flex align-items-center mr-4 text-muted font-weight-light">
                                                        <i className="mdi mdi-account-outline icon-sm mr-2"></i>
                                                        <span>{tab?.userDetails?.name}</span>
                                                    </div>
                                                    {tab?.date &&
                                                        <div className="d-flex align-items-center text-muted font-weight-light float-right">
                                                            <i className="mdi mdi-clock icon-sm mr-2"></i>
                                                            <span>{DATE_FORMAT_TIME_NOTES(tab?.date)}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="">
                                                    <p className="task-notes-list">Comment : {tab?.note}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    )
                                })}
                            </div>
                            {editData?.comments?.length > 0 &&
                                <div className="pagination-box-review">
                                    <ReactPagination
                                        activePage={activePage}
                                        itemsCountPerPage={5}
                                        totalItemsCount={editData?.comments?.length}
                                        handlePageChange={handlePageChange}
                                    />
                                </div>
                            }
                            {editData?.comments?.length == 0 &&
                                <div className="row">
                                    <div className="col-md-6 pt-5">
                                        <span>No more comments...</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};