import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// imported
import { PROFILE } from 'helpers/viewContent';
import { MAIN__DUMMY_ } from 'utils/constant'
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { uploadFile_ } from 'helpers/uploadFile';
//services
import { updateProfile_, profile_ } from 'store/services/authService';

const validationSchema = Yup.object().shape({
    name: Yup.string().optional(),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

const Profile = ({ dispatch, userProfile }) => {
    const [initialFields, setInitialFields] = useState({});

    const fileInputRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageData, setImageData] = useState({});
    const [fields, setfields] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        countryCode: "",
        profileImage: ""
    })
    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    const handleFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        let fileName = e.target.name;
        setSending(true)
        let data = await uploadFile_(dispatch, file, setValidationErrors, setSelectedFile, setPreviewImage, fileName)
        data = data ? data?.url : {};
        setSending(false)
        setImageData(data);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        await uploadFile_(file);
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };

    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                let editedFields = {}
                // Check if the name field has changed
                if (initialFields.name !== fields.name) {
                    editedFields = {
                        name: fields.name,
                    };
                } else if(initialFields.email !== fields.email){
                    editedFields = {
                        email: fields.email
                    }
                }else if(initialFields.mobileNumber !== fields.mobileNumber){
                    const phoneNumberWithoutPlus = fields.mobileNumber
                    ? fields.mobileNumber.substring(fields?.countryCode?.length)
                    : null;
                    const codeWithoutPlus = fields?.countryCode;
                    editedFields = {
                        mobileNumber: phoneNumberWithoutPlus,
                        countryCode: fields.countryCode
                            ? codeWithoutPlus?.startsWith("+")
                                ? codeWithoutPlus
                                : "+" + codeWithoutPlus
                            : null,
                    }
                }else if(initialFields.profileImage !== imageData){
                    editedFields = {
                        profileImage: imageData ? imageData : fields?.profileImage,
                    }
                }
                setSending(true);
                editedFields = {
                    ...editedFields,
                    userId: userProfile?._id,
                }
                console.log(editedFields)
                await dispatch(updateProfile_(editedFields));
                setSending(false);
                getData();
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            console.log(err);
            setSending(false);
        }
    };
    
    useEffect(() => {
        let data = {
            name: userProfile?.name,
            email: userProfile?.email,
            mobileNumber: `${userProfile?.countryCode}${userProfile?.mobileNumber}`,
            profileImage: `${userProfile?.profileImage}`,
            countryCode: `${userProfile?.countryCode}`
        }
        setfields(data)
        setInitialFields(data);
        setImageData(`${userProfile?.profileImage}`)
    }, [userProfile])

    async function getData() {
        try {
            dispatch(actions.persist_store({ userProfile: null }));
            let res = await dispatch(profile_());
            dispatch(actions.persist_store({ userProfile: res?.data }));

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Helmet title={PROFILE.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    Edit Profile
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.profile}>{PROFILE.CURRENT_MODULE}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"></li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card graph_card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputName1">Name*</label>
                                            <input type="text" className="form-control" id="exampleInputName1" placeholder="Name" name="name" onChange={handleChange("name")}
                                                value={fields.name}
                                            />
                                            {validationErrors.name && (
                                                <div className="text-danger err">{validationErrors.name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail3">Email Address*</label>
                                            <input type="email" className="form-control" id="exampleInputEmail3" placeholder="Email" name="email" onChange={handleChange("email")}
                                                value={fields.email} />
                                            {validationErrors.email && (
                                                <div className="text-danger err">{validationErrors.email}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail3">Mobile Number</label>
                                            <PhoneInput
                                                enableAreaCodes={true}
                                                name="mobileNumber"
                                                className="form-phone_"
                                                country={'us'} // Set the default country, or the one relevant to your use case
                                                value={fields.mobileNumber} // Provide the value for the PhoneInput
                                                onChange={(value, country) => {
                                                    // Handle phone value change
                                                    setfields(prevState => ({
                                                        ...prevState,
                                                        mobileNumber: value,
                                                        countryCode: country.dialCode,
                                                    }));
                                                }}
                                                maxLength={15}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
                                            <div className="form-group">
                                                <label for="exampleInputEmail3">Profile Image</label>
                                                <input type="file" class="form-control file-upload-info" disabled="" placeholder="Upload Image" style={{ display: 'none' }} name="profileImage" onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                                <div>
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Preview" className="img-custom" />
                                                    ) : userProfile?.profileImage ? (
                                                        <img src={userProfile?.profileImage} alt="Preview" className="img-custom" />
                                                    ) : (
                                                        <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" disabled={sending ? true : false} className="btn btn-gradient-primary btn-lg btn-block">{sending ? 'Loading...' : 'Submit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToPros = (state) => {
    return {
        userProfile: state?.persistStore?.userProfile === '{}' ? state.persistStore.userProfile : state.Auth.user,
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(Profile);