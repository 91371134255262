import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPagination from "components/shared/ReactPagination";
import { debounce } from "lodash";
import Datetime from "react-datetime";
import moment from "moment";

import "react-datetime/css/react-datetime.css";
//imported
import * as Path from "routes/paths";
import { REVENUE, PAGE_LIMIT } from "helpers/viewContent";
import SearchFilter from "components/shared/SearchFilter";
import { getList_ } from "store/services/commonService";
import {
  DATE_FORMAT_TIME_,
  DATE_FORMAT_FOR_DB,
  FORMAT_TEXT,
} from "helpers/common";
import EditRevenu from "./EditRevenu";

const Management = ({ dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);

  //check users revenues
  const [checkedUserList, setCheckedUserList] = useState([]);

  const [revenueRecord, setRevenueRecord] = useState([]);
  const [checkUncheck, setCheckUncheck] = useState(false);

  //show modal
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    setRevenueRecord([]);
  };
  const handleModalShow = () => {
    setShowModal(true);
  };

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    // status: "",
    type: "",
    value: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [listing, setListing] = useState([]);
  const [fields, setfields] = useState({
    startDate: "",
    endDate: "",
  });

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemEmail = list?.email?.toLowerCase();
      return itemName?.includes(searchTerm) || itemEmail?.includes(searchTerm);
    });
  }
  const [key, setKey] = useState(0);

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        value: parseInt(filter?.value),
        url: "getUsersWithFilter",
      };
      setIsLoading(true);
      console.log(query);
      let res = await dispatch(getList_(query));
      console.log(res);
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, fields]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };
  const handleSearchSelectChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      [event.target.name]: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      type: "all",
      value: "",
    });
    setfields({
      search_date: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  let arrIds = [];
  const getCheckedUserId = (e, id, type) => {
    const { checked } = e.target;
    if (type === "single") {
      // updateReveneFunction(id, "update_all_revenue")
    } else if (type === "multiple") {
      if (checked == true) {
        setRevenueRecord([...revenueRecord, id]);
        setCheckUncheck(!checkUncheck);
      } else {
        let index = revenueRecord.indexOf(id);
        if (index > -1) {
          revenueRecord.splice(index, 1);
          setRevenueRecord(revenueRecord);
          setCheckUncheck(!checkUncheck);
        }
      }
    } else {
      if (checked == true) {
        listing.map((item) => {
          return arrIds.push(item._id);
        });
        setCheckUncheck(!checkUncheck);
        setRevenueRecord(arrIds);
      } else {
        arrIds = [];
        setRevenueRecord([]);
        setCheckUncheck(!checkUncheck);
      }
    }
    return arrIds;
  };

  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  return (
    <>
      <h4 class="card-title">
        <div class="fillte_main flex-wrap d-flex justify-content-between align-items-end">
          <div class="fillte_main flex-wrap d-flex gap-3 align-items-end">
            {/* <div class="filter_drop_filter">
              <h6>From Date</h6>
              <Datetime
                key={key}
                value={fields.startDate || ""}
                inputProps={{
                  placeholder: "DD/MM/YYYY",
                  readOnly: true,
                  className: "form-control class-time-date-time",
                }}
                name="startDate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                isValidDate={(current) => {
                  return current.isSameOrBefore(moment(), "day");
                }}
                onChange={(selectedDate) =>
                  handleChangePicker(selectedDate, "startDate")
                }
                closeOnSelect={true}
              />
            </div> */}
            {/* <div class="filter_drop_filter">
              <h6>To Date</h6>
              <Datetime
                key={key}
                value={fields.endDate || ""}
                inputProps={{
                  placeholder: "DD/MM/YYYY",
                  readOnly: true,
                  className: "form-control class-time-date-time",
                }}
                name="endDate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                isValidDate={(current) => {
                  // Parse the current date string in DD/MM/YYYY format to a moment object
                  const currentDate = moment(current, "DD/MM/YYYY", true);
                  // Parse the start date string in DD/MM/YYYY format to a moment object
                  const startDate = moment(
                    fields?.startDate,
                    "DD/MM/YYYY",
                    true
                  );
                  // Check if the current date is the same as or after the start date
                  return (
                    currentDate.isValid() &&
                    startDate.isValid() &&
                    currentDate.isSameOrAfter(startDate, "day")
                  );
                }}
                onChange={(selectedDate) =>
                  handleChangePicker(selectedDate, "endDate")
                }
                closeOnSelect={true}
              />
            </div> */}
            <div class="selector_fillter d-flex gap-3 align-items-center">
              <div class="filter_drop_filter">
                <h6>Type</h6>
                <select
                  class="form-select filler_search"
                  onChange={(e) => handleSearchSelectChange(e)}
                  name="type"
                >
                  <option value="" disabled={true}>
                    Select{" "}
                  </option>
                  <option
                    value="all"
                    elected={filter?.type === "all" ? "selected" : ""}
                  >
                    All{" "}
                  </option>
                  <option
                    value="trade"
                    selected={filter?.type === "trade" ? "selected" : ""}
                  >
                    Trading Fee{" "}
                  </option>
                  <option
                    value="deposit"
                    selected={filter?.type === "deposit" ? "selected" : ""}
                  >
                    Deposit Fee{" "}
                  </option>
                  <option
                    value="withdraw"
                    selected={filter?.type === "withdraw" ? "selected" : ""}
                  >
                    Withdrawal Fee{" "}
                  </option>
                  <option
                    value="fee"
                    selected={filter?.type === "fee" ? "selected" : ""}
                  >
                    Exchange Fee{" "}
                  </option>
                </select>
              </div>
            </div>
            <div class="selector_fillter d-flex gap-3 align-items-center">
              <div class="filter_drop_filter">
                <h6>Revenue</h6>
                <select
                  class="form-select filler_search"
                  name="value"
                  onChange={(e) => handleSearchSelectChange(e)}
                >
                  <option
                    value=""
                    selected={filter?.value === "" ? "selected" : ""}
                  >
                    Select{" "}
                  </option>
                  <option
                    value={1}
                    selected={filter?.value === 1 ? "selected" : ""}
                  >
                    1%{" "}
                  </option>
                  <option
                    value={2}
                    selected={filter?.value === 2 ? "selected" : ""}
                  >
                    2%{" "}
                  </option>
                  <option
                    value={3}
                    selected={filter?.value === 3 ? "selected" : ""}
                  >
                    3%{" "}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  className="form-control"
                  autoComplete="chrome-off"
                  name="searchUnique"
                  type="text"
                  placeholder="search by name/email"
                  value={filter?.search}
                  onChange={handleSearchChange}
                />
                &nbsp;{" "}
                <button
                  onClick={clearSerchFilter}
                  type="button"
                  className="btn btn-gradient-primary btn-sm"
                >
                  <i class="mdi mdi-close-circle"></i>
                </button>
              </div>
            </div>
            <div class="filter_drop_filter">
              <button
                type="button"
                class="btn btn-gradient-primary btn-lg px-3"
                onClick={clearSerchFilter}
              >
                Clear Filter
              </button>
            </div>
            {revenueRecord?.length > 0 && (
              <div
                class="d-flex flex-wrap d-flex justify-content-end align-items-end"
                onClick={handleModalShow}
              >
                <button type="button" class="btn btn-gradient-primary btn-lg">
                  Update Revenue
                </button>
              </div>
            )}
          </div>
        </div>
      </h4>

      <div class="table-responsive">
        <table className="table common_th">
          <thead>
            <tr>
              <th>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="select_box"
                    onClick={(e) => getCheckedUserId(e, "", "all")}
                    className="custom-control me-2"
                  />
                  Select
                </div>
              </th>
              <th onClick={() => handleSortingFunc("name")}>
                {" "}
                Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("last_name")}>
                {" "}
                Last Name{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "last_name"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "last_name"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("email")}>
                {" "}
                Email Id{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "email"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "email"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>

              <th onClick={() => handleSortingFunc("tradeCommission")}>
                {" "}
                Trade Commission(%){" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 &&
                    filter?.orderBy === "tradeCommission"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 &&
                    filter?.orderBy === "tradeCommission"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("withdrawCommission")}>
                {" "}
                Withdraw Commission(%)
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 &&
                    filter?.orderBy === "withdrawCommission"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 &&
                    filter?.orderBy === "withdrawCommission"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("depositCommission")}>
                {" "}
                Deposit Commission(%){" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 &&
                    filter?.orderBy === "depositCommission"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 &&
                    filter?.orderBy === "depositCommission"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("fee")}>
                {" "}
                Exchange Fee(%){" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "fee"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "fee"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("equity")}>
                {" "}
                Wallet Equity{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "equity"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "equity"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
              <th onClick={() => handleSortingFunc("createdAt")}>
                {" "}
                Registration Date{" "}
                <i
                  className={`cursor-pointer mdi mdi-arrow-${
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "up"
                      : "down"
                  }`}
                  title={
                    filter?.order === -1 && filter?.orderBy === "createdAt"
                      ? "Desc"
                      : "Asc"
                  }
                ></i>{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {listToDisplay?.length > 0 &&
              listToDisplay.map((list, i) => {
                const isChecked = checkedUserList?.includes(list?._id); // Check if the ID is in checkedUserList
                return (
                  <tr key={i} style={{ cursor: "pointer" }}>
                    <td>
                      <input
                        type="checkbox"
                        name="selection"
                        className="custom-control"
                        onClick={(e) =>
                          getCheckedUserId(e, list._id, "multiple")
                        }
                        checked={
                          revenueRecord.includes(list._id) ? "checked" : ""
                        }
                      />
                    </td>
                    <td>
                      {list?.first_name ? FORMAT_TEXT(list?.first_name) : "N/A"}
                    </td>
                    <td>
                      {list?.last_name ? FORMAT_TEXT(list?.last_name) : "N/A"}
                    </td>
                    <td>{list?.email ? FORMAT_TEXT(list?.email) : "N/A"}</td>
                    <td>
                      {" "}
                      {list?.tradeCommission ? `${list?.tradeCommission}` : "0"}
                    </td>
                    <td>
                      {" "}
                      {list?.withdrawCommission
                        ? `${list?.withdrawCommission}`
                        : "0"}
                    </td>
                    <td>
                      {" "}
                      {list?.depositCommission
                        ? `${list?.depositCommission}`
                        : "0"}
                    </td>
                    <td>{list?.fee ? list?.fee : "0"}</td>
                    <td>${list.equity ? list.equity.toFixed(2) : "0"}</td>
                    <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {listing.length > 0 && (
        <div className="pagination-box-review">
          <ReactPagination
            activePage={filter?.page}
            itemsCountPerPage={PAGE_LIMIT}
            totalItemsCount={totalItemsCount}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
      {listing.length == 0 && (
        <div className="row">
          <div className="col-md-6 pt-5">
            <span>No data found...</span>
          </div>
        </div>
      )}

      <EditRevenu
        showModal={showModal}
        handleModalClose={handleModalClose}
        dispatch={dispatch}
        revenueRecord={revenueRecord}
        setShowModal={setShowModal}
        setRevenueRecord={setRevenueRecord}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Management);
