import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import persistStore from "./persistStore";
//imported
import Auth from "../reducers/Auth";
import File from "../reducers/File";
import Dashboard from "../reducers/Dashboard";
import Common from "../reducers/Common";
import Faq from "../reducers/Faq";
import Pages from "../reducers/Pages";
import Blogs from "./Blogs";
import Jobs from "./Jobs";
import Testimony from "./Testimony";
import Feature from "./Feature";


const RootReducer = combineReducers({
  Auth,
  File,
  Dashboard,
  Common,
  Faq,
  Blogs,
  Pages,
  Jobs,
  Testimony,
  Feature,
  persistStore,
  form: formReducer,
});
export default RootReducer;
