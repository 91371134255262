import { uploadImage_ } from "store/services/fileService";
import { ERROR_MESSAGE } from "helpers/constantsMessage";

export async function UploadMediaFile(dispatch, file, folder, type) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    // formData.append('folder', folder);
    // formData.append('type', type);

    let res = await dispatch(uploadImage_(formData));
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function uploadFile_(
  dispatch,
  file,
  setValidationErrors,
  setSelectedFile,
  setPreviewImage,
  fileName
) {
  try {
    // Perform file type validation
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Check if file size is within the allowed limit (5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file && allowedTypes.includes(file?.type) && file?.size <= maxSize) {
      setValidationErrors({});

      // Update selected file and show preview
      setSelectedFile(file);

      try {
        let data = await UploadMediaFile(dispatch, file, "users", "image");
        if (file?.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          setPreviewImage(null);
          setValidationErrors((prev) => ({
            ...prev,
            [fileName]: "",
          }));
        }

        return data;
      } catch (error) {
        console.error("UploadMediaFile error:", error);
        throw error;
      }
    } else {
      // Show an error if the file type is not allowed or exceeds size limit
      setSelectedFile(null);
      setPreviewImage(null);

      let errorMessage = "";
      if (!allowedTypes.includes(file?.type)) {
        errorMessage =
          "Invalid file type. Please choose a valid image (JPEG, PNG, JPG).";
      } else if (file?.size > maxSize) {
        errorMessage =
          "File size exceeds the limit of 5MB. Please choose a smaller file.";
      }
      setValidationErrors((prev) => ({
        ...prev,
        [fileName]: errorMessage,
      }));
      await ERROR_MESSAGE(errorMessage);
    }
  } catch (error) {
    // Handle any additional error logic if needed
    console.error("uploadFile_ error:", error);
    throw error;
  }
}

export async function uploadFileMulti_(
  dispatch,
  file,
  setSelectedFile,
  setPreviewImage,
  setValidationErrors,
  fileName
) {
  try {
    // Perform file type validation
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Check if file size is within the allowed limit (5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file && allowedTypes.includes(file?.type) && file?.size <= maxSize) {
      setValidationErrors({});
      let fileType;
      if (file?.type.startsWith("image/")) {
        fileType = "0"; //image
      } else {
        fileType = "1"; //video
      }
      // Update selected file and show preview
      setSelectedFile(file);

      try {
        let data = await UploadMediaFile(dispatch, file, "post", "image");

        if (file?.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage((prev) => ({
              ...prev,
              [fileName]: reader.result,
            }));
          };
          reader.readAsDataURL(file);
        } else {
          setPreviewImage((prev) => ({
            ...prev,
            [fileName]: "",
          }));
          setValidationErrors((prev) => ({
            ...prev,
            [fileName]: "",
          }));
        }

        return data;
      } catch (error) {
        console.error("UploadMediaFile error:", error);
        throw error;
      }
    } else {
      // Show an error if the file type is not allowed or exceeds size limit
      setSelectedFile(null);
      let errorMessage = "";
      if (!allowedTypes.includes(file?.type)) {
        errorMessage =
          "Invalid file type. Please choose a valid image (JPEG, PNG, JPG).";
      } else if (file?.size > maxSize) {
        errorMessage =
          "File size exceeds the limit of 5MB. Please choose a smaller file.";
      }

      setValidationErrors((prev) => ({
        ...prev,
        [fileName]: errorMessage,
      }));
      setPreviewImage((prev) => ({
        ...prev,
        [fileName]: "",
      }));
    }
  } catch (error) {
    // Handle any additional error logic if needed
    console.error("uploadFile_ error:", error);
    throw error;
  }
}

export async function uploadFileVideoMulti_(
  dispatch,
  file,
  setValidationErrors,
  setSelectedFile,
  setPreviewImage,
  fileName,
  setSelectedFileType
) {
  try {
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "video/mp4",
      "video/mpeg",
      "video/webm",
    ];
    // Check if file size is within the allowed limit (5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file && allowedTypes.includes(file?.type) && file?.size <= maxSize) {
      setValidationErrors({});
      let fileType;
      if (file?.type.startsWith("image/")) {
        fileType = "0"; // image
      } else {
        fileType = "1"; // unknown type
      }
      setSelectedFileType(fileType);

      // Update selected file and show preview
      setSelectedFile(file);

      try {
        let data = await UploadMediaFile(
          dispatch,
          file,
          "post",
          fileType === "0" ? "image" : "video"
        );

        // if (fileType === '0') {
        // For images, show preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage((prev) => ({
            ...prev,
            [fileName]: reader.result,
          }));
        };
        reader.readAsDataURL(file);
        // } else {
        //     // For videos, don't show preview
        //     setPreviewImage(prev => ({
        //         ...prev,
        //         [fileName]: "",
        //     }));
        // }

        return data;
      } catch (error) {
        console.error("UploadMediaFile error:", error);
        throw error;
      }
    } else {
      // Show an error if the file type is not allowed or exceeds size limit
      setSelectedFile(null);
      setSelectedFileType(null);
      setPreviewImage((prev) => ({
        ...prev,
        [fileName]: "",
      }));
      setValidationErrors((prev) => ({
        ...prev,
        [fileName]: "",
      }));
      let errorMessage = "";
      if (!allowedTypes.includes(file.type)) {
        errorMessage =
          "Invalid file type. Please choose a valid image (JPEG, PNG, JPG) or video (MP4, MPEG, QuickTime).";
      } else if (file.size > maxSize) {
        errorMessage =
          "File size exceeds the limit of 5MB. Please choose a smaller file.";
      }
      setValidationErrors((prev) => ({
        ...prev,
        [fileName]: errorMessage,
      }));
    }
  } catch (error) {
    // Handle any additional error logic if needed
    console.error("uploadFile_ error:", error);
    throw error;
  }
}
