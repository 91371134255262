import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

//imported
import { PROMOTIONS } from 'helpers/viewContent';
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { MAIN__DUMMY_ } from 'utils/constant'
import { uploadFile_ } from 'helpers/uploadFile';
//services
import { addData_, getDetail_, updateData_ } from 'store/services/commonService';

const AddEdit = ({ dispatch, match }) => {
    let editkey = match.params.id;
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed in the name')
            .required('Name is required'),
        // store: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed in the store')
        // .required('Store is required'),
        // vendor: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, 'Only text characters are allowed in the vendor')
        // .required('Vendor is required'),
        status: Yup.string().required('Status is required'),
        image: Yup.string().required('Image is required'),
    });

    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };

    const fileInputRef = useRef(null);

    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [editData, setEditData] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageData, setImageData] = useState({});

    const [fields, setfields] = useState({
        name: "",
        // store: "",
        // vendor: "",
        image: "",
        status: "active",
    })

    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    async function getData() {
        try {
            let query = {
                url: 'promotion/getById',
                id: editkey
            }
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(getDetail_(query));
            setEditData(res?.data);
            setfields(res?.data)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        if (editkey) {
            getData()
        }
    }, []);
    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                setIsShow(true)
                let query = {}
                if (editkey) {
                    query = {
                        ...fields,
                        image: imageData ? imageData : fields?.image,
                        url: "promotion/update",
                        id: editkey
                    }
                    await dispatch(updateData_(query));
                } else {
                    query = {
                        ...fields,
                        url: "promotion/add"
                    }
                    await dispatch(addData_(query));
                }
                setIsShow(false)
                history.push(Path.promotion)
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setIsShow(false)
            console.log(err);
        }
    }

    //image
    const handleFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        let fileName = e.target.name;
        setSending(true)
        let data = await uploadFile_(dispatch, file, setValidationErrors, setSelectedFile, setPreviewImage, fileName)
        data = data ? data?.url : {};
        setfields(prevState => ({
            ...prevState,
            image: data,
        }))
        setEditData(data)
        setSending(false)
        setImageData(data);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        await uploadFile_(file);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <Helmet title={PROMOTIONS.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    {PROMOTIONS.CURRENT_MODULE}
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.promotion}>Promotion</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add/Edit</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <h4 className="card-title">Add/Edit</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Name*</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder=" Name*" name="name" onChange={handleChange("name")}
                                            value={fields.name}
                                        />
                                        {validationErrors.name && (
                                            <div className="text-danger err">{validationErrors.name}</div>
                                        )}
                                    </div>
                                    {/* <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Store*</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder=" Store*" name="store" onChange={handleChange("store")}
                                        value={fields.store}
                                        />
                                        {validationErrors.store && (
                                            <div className="text-danger err">{validationErrors.store}</div>
                                        )}
                                    </div> */}
                                    <div className="col-md-6">
                                        <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
                                            <div className="form-group">
                                                <label for="exampleInputEmail3">Image*</label>
                                                <input type="file" class="form-control file-upload-info" disabled="" placeholder="Upload Image" style={{ display: 'none' }} name="image" onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                                <div>
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Preview" className="img-custom" />
                                                    ) : editData?.image ? (
                                                        <img src={editData?.image} alt="Preview" className="img-custom" />
                                                    ) : (
                                                        <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" />
                                                    )}
                                                    {/* <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" /> */}
                                                </div>
                                                {validationErrors.image && (
                                                    <div className="text-danger err">{validationErrors.image}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Vendor*</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder=" Vendor*" name="vendor" onChange={handleChange("vendor")}
                                        value={fields.vendor}
                                        />
                                        {validationErrors.vendor && (
                                            <div className="text-danger err">{validationErrors.vendor}</div>
                                        )}
                                    </div> */}

                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <label for="exampleInputPassword4">Status*</label>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value="active" name="status" onChange={handleChange("status")} checked={fields.status === "active" ? true : false} /> Active
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value="inactive" name="status" onChange={handleChange("status")} checked={fields.status === "inactive" ? true : false} /> Inactive </label>
                                            </div>
                                        </div>
                                    </div>
                                    {validationErrors.status && (
                                        <div className="text-danger err">{validationErrors.status}</div>
                                    )}
                                </div>
                                <button type="submit" disabled={isShow ? true : false} className="btn btn-gradient-primary me-2">{isShow ? 'Wait..' : 'Submit'}
                                </button>
                                <Link to={Path.promotion}>
                                    <button className="btn btn-outline-secondary btn-lg">Back</button>
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return {
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(AddEdit);