import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IMG_LOGO_1, SIDEBAR_LOGIN_LOGO } from "utils/constant";
import { updateData_ } from "store/services/commonService";

export default ({
  show,
  handleClose,
  setShow,
  currentValue,
  setCurrentValue,
  setisUpdateApi,
  dispatch,
}) => {
  const [showSubmitBtn, setshowSubmitBtn] = useState(false);
  console.log(currentValue);

  const CancelCloseTrade = async (value, type) => {
    try {
      console.log(value);
      setshowSubmitBtn(true);
      let obj = {
        url: type === "cancel" ? "cancelOrder" : "closePosition",
      };
      if (type === "cancel") {
        obj = {
          ...obj,
          orderId: value?.id,
          accountId: value?.userDetails?.accountId,
        };
      } else {
        obj = {
          ...obj,
          symbol: value?.symbol,
          accountId: value?.userDetails?.accountId,
        };
      }
      await dispatch(updateData_(obj));
      setshowSubmitBtn(false);
      setShow(false);
      setisUpdateApi(true);
    } catch (err) {
      console.log(err);
      setshowSubmitBtn(false);
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="axis_bank m-auto">
            <img
              src={
                currentValue?.userDetails?.profileImage
                  ? currentValue?.userDetails?.profileImage
                  : SIDEBAR_LOGIN_LOGO
              }
              alt=""
              className="img-fluid"
            />
          </div>
          {/* <h2 className="text-center my-2">Axis Bank ltd.</h2> */}
          <div className="axix_modal_content my-3">
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Holder Name</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.accountHolderName}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Nickname</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.nickname}
                  </label>
                </li>
              </ul>
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Email</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.userDetails?.email}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account Number</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.accountNumber}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Routing Number</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.routingNumber}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Status</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {currentValue?.status}
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
