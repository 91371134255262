import React, { useState, useEffect, useCallback } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

import { getDashboard_ } from "store/services/dashboardService";

export default ({
  dashboardList,
  dispatch,
  selectedInterval,
  setSelectedInterval,
  earningData,
  setearningData,
}) => {
  const currentYear = new Date().getFullYear();
  const futureYears = Array.from(
    { length: 5 },
    (_, index) => currentYear + index
  );
  const data = {
    monthly: selectedInterval === "monthly" && {
      uData:
        earningData?.response?.length > 0
          ? earningData?.response
          : Array(12).fill(0),
      xLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    weekly: selectedInterval === "weekly" && {
      uData:
        earningData?.response?.length > 0
          ? earningData?.response
          : Array(7).fill(0),
      xLabels: Array.from({ length: 7 }, (_, index) => `${index + 1}`),
    },
    yearly: selectedInterval === "yearly" && {
      uData:
        earningData?.response?.length > 0
          ? earningData?.response
          : Array(futureYears.length).fill(0),
      xLabels: futureYears.length > 0 ? futureYears.map(String) : [],
    },
    daily: selectedInterval === "daily" && {
      uData:
        earningData?.response?.length > 0
          ? earningData?.response
          : Array(31).fill(0),
      xLabels: Array.from({ length: 31 }, (_, index) => `${index + 1}`),
    },
  };

  const handleIntervalChange_ = async (e) => {
    try {
      setSelectedInterval(e.target.value);
      let query = {
        filter: e.target.value,
        type: "earning",
      };
      setearningData({ response: "" });
      let res = await dispatch(getDashboard_(query));
      if (res) {
        setearningData({ response: res?.data?.data?.earningData?.response });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label for="exampleInputName1">
                    <b>Revenue Generated</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="selector_fillter d-flex gap-3 align-items-center">
                    <h5 className="me-2 mb-0">Filter By:</h5>
                    <div class="filter_drop_filter">
                      <select
                        className="form-select filler_search"
                        value={selectedInterval}
                        name="interval"
                        onChange={(e) => handleIntervalChange_(e)}
                      >
                        <option value="" disabled selected>
                          {" "}
                          Select Interval{" "}
                        </option>
                        <option
                          value="daily"
                          selected={
                            selectedInterval === "daily" ? "selected" : ""
                          }
                        >
                          Daily
                        </option>
                        <option
                          value="weekly"
                          selected={
                            selectedInterval === "weekly" ? "selected" : ""
                          }
                        >
                          Weekly
                        </option>
                        <option
                          value="monthly"
                          selected={
                            selectedInterval === "monthly" ? "selected" : ""
                          }
                        >
                          Monthly
                        </option>
                        <option
                          value="yearly"
                          selected={
                            selectedInterval === "yearly" ? "selected" : ""
                          }
                        >
                          Yearly
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {earningData?.response?.length > 0 && data[selectedInterval] && (
                <LineChart
                  height={410}
                  series={[
                    {
                      data: data[selectedInterval].uData,
                      color: "#e77476",
                      label: "Revenue Sum",
                      area: true,
                    },
                  ]}
                  xAxis={[
                    { data: data[selectedInterval].xLabels, scaleType: "band" },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
