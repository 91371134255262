
//action types
export const GET_FAQ = 'GET_FAQ';
export const ADD_FAQ = 'ADD_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const VIEW_FAQ = 'VIEW_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const UPDATE_STATUS_FAQ = 'UPDATE_STATUS_FAQ';

export function getFAQ(data, callback) {
    return {
      type: GET_FAQ,
      payload : {data, callback},
    };
}

export function addFAQ(data, callback) {
  return {
    type: ADD_FAQ,
    payload : {data, callback},
  };
}

export function updateFAQ(data, callback) {
  return {
    type: UPDATE_FAQ,
    payload : {data, callback},
  };
}

export function viewFAQ(data, callback) {
  return {
    type: VIEW_FAQ,
    payload : {data, callback},
  };
}

export function deleteFAQ(data, callback) {
  return {
    type: DELETE_FAQ,
    payload : {data, callback},
  };
}
export function updateStatusFAQ(data, callback) {
  return {
    type: UPDATE_STATUS_FAQ,
    payload : {data, callback},
  };
}