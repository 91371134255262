import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import { JOBS } from "helpers/viewContent";
import * as Path from "routes/paths";
//services
import { addJob_, updateJob_, viewJob_ } from "store/services/jobs";

const AddEdit = ({ dispatch, match }) => {
  let editkey = match.params.id;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    indonesiaName: Yup.string().required("Indonesia Name is required"),
    indonesiaDesignation: Yup.string().required(
      "Indonesia Designation is required"
    ),
    location: Yup.string().required("Location is required"),
    type: Yup.string().required("Type is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [fields, setfields] = useState({
    name: "",
    location: "",
    indonesiaName: "",
    indonesiaDesignation: "",
    type: "",
    designation: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  async function getDataView() {
    try {
      let res = await dispatch(viewJob_(editkey));
      setfields(res?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (editkey) {
      getDataView();
    }
  }, []);

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          ...fields,
        };
        setIsShow(true);
        if (editkey) {
          obj = {
            ...fields,
            jobId: editkey,
          };
          await dispatch(updateJob_(obj));
        } else {
          await dispatch(addJob_(obj));
        }
        setIsShow(false);
        history.push(Path.jobs);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  return (
    <>
      <Helmet title={JOBS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{JOBS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.jobs}>Job</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add/Edit
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add/Edit</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Name*"
                      name="name"
                      onChange={handleChange("name")}
                      value={fields.name}
                      maxLength={50}
                    />
                    {validationErrors.name && (
                      <div className="text-danger err">
                        {validationErrors.name}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Indonesia Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Name*"
                      name="indonesiaName"
                      onChange={handleChange("indonesiaName")}
                      value={fields.indonesiaName}
                      maxLength={50}
                    />
                    {validationErrors.indonesiaName && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaName}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="exampleInputJobType">Job Type*</label>
                    <select
                      className="form-control"
                      id="exampleInputJobType"
                      name="type"
                      onChange={handleChange("type")}
                      value={fields.type}
                      style={{
                        padding: "1rem 0.75rem",
                        backgroundColor: "white",
                      }}
                    >
                      <option value="">Select Job Type</option>
                      <option value="parttime">Part-time</option>
                      <option value="fulltime">Full-time</option>
                    </select>
                    {validationErrors.type && (
                      <div className="text-danger err">
                        {validationErrors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Designation*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Designation*"
                      name="designation"
                      onChange={handleChange("designation")}
                      value={fields.designation}
                      maxLength={50}
                    />
                    {validationErrors.designation && (
                      <div className="text-danger err">
                        {validationErrors.designation}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">
                      Indonesia Designation*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Designation*"
                      name="indonesiaDesignation"
                      onChange={handleChange("indonesiaDesignation")}
                      value={fields.indonesiaDesignation}
                      maxLength={50}
                    />
                    {validationErrors.indonesiaDesignation && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaDesignation}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">Address*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="address*"
                      name="location"
                      onChange={handleChange("location")}
                      value={fields.location}
                    />
                    {validationErrors.location && (
                      <div className="text-danger err">
                        {validationErrors.location}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Wait.." : "Submit"}
                </button>
                <Link to={Path.jobs}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
