import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//imported
import { PAYMENTS } from 'helpers/viewContent';
import { DATE_FORMAT, DATE_FORMAT_TIME } from "helpers/common";
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
//services
import { getDetail_ } from 'store/services/commonService';


const Detail = ({ dispatch, match }) => {
    let editkey = match.params.id;
    const [editData, setEditData] = useState({});
    async function getData() {
        try {
            let query = {
                url: "coach/view",
                id: editkey
            }
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(getDetail_(query))
            setEditData(res?.body)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        if (editkey) {
            getData()
        }
    }, []);
    return (
        <>
            <Helmet title={PAYMENTS.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    {PAYMENTS.CURRENT_VIEW}
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.payment}>Mangae Payemnt</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Detail</li>
                    </ol>
                </nav>
            </div>

            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <p className="card-description"> Personal info </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Full Name</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.name ? editData?.name : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Email</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.email ? editData?.email : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Nationality</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.nationality ? editData?.nationality : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Date of Birth</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.birth_date ? DATE_FORMAT(editData?.birth_date) : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Phone</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.phone ? `${editData?.country_code} ${editData?.phone}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Age Range</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.preferred_age ? `${editData?.preferred_age}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Gender</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.gender ? editData.gender === '1' ? 'Male' : editData?.gender === '2' ? 'Female' : editData?.gender === '3' ? 'Other' : 'N/A' : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Preferred Playing Surfaces</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.preferred_playing_surface ? `${editData?.preferred_playing_surface}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Player Statistics</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.player_statistics ? `${editData?.player_statistics === 'plying_hand' ? 'Right-handed / Left-handed' : 'Singles/Doubles Player'} ` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Playing Style</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.player_style ? `${editData?.player_style === 'serve_volley' ? 'Serve and Volley' : 'Baseline'} ` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="card-description"> Physical Attributes : </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Height</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.height ? `${editData?.height} inch` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Weight</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.weight ? `${editData?.weight} kg` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Status</label>
                                        <div className="col-sm-6 col-form-label">
                                            <p className={`badge badge-gradient-${editData?.status == 1 ? 'success' : 'danger'}`}>{editData?.status == 1 ? 'Active' : 'Inactive'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Location</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.location ? `${editData?.location}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Number of Followers</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.number_of_followers ? `${editData?.number_of_followers}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Number of Following</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.number_of_following ? `${editData?.number_of_following}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Image</label>
                                        <div className="col-sm-6 col-form-label">
                                            <a href={editData?.image ? `${editData?.image}` : "#"} target="_blank"><img src={`${editData?.image}`} className="mb-2 rounded img-view" alt="image" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Created At</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.created_at ? DATE_FORMAT_TIME(editData?.created_at) : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="card-description">Bio : </p>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="col-sm-12 col-form-label">
                                        {editData?.bio ? editData?.bio : 'N/A'}
                                    </div>
                                </div>
                            </div>
                            <p className="card-description">Academy Detail : </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Academy Name</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user_detail?.academy_name ? `${editData?.user_detail?.academy_name}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Academy Location</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user_detail?.location ? `${editData?.user_detail?.location}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Academy Description</label>
                                        <div className="col-sm-6 col-form-label">
                                            {editData?.user_detail?.academy_description ? `${editData?.user_detail?.academy_description}` : 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-6 col-form-label">Academy Image</label>
                                        <div className="col-sm-6 col-form-label">
                                            <a href={editData?.user_detail?.image ? `${editData?.user_detail?.image}` : "#"} target="_blank"><img src={`${editData?.user_detail?.image}`} className="mb-2 rounded img-view" alt="image" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return {
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(Detail);