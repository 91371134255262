import React, { useState, useEffect, useMemo } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
//imported
import { PROMOTIONS, PAGE_LIMIT } from 'helpers/viewContent';
import { DELETE_ALERT } from "helpers/constantsMessage"
import { MAIN_DUMMY_ } from 'utils/constant'
import ReactPagination from "components/shared/ReactPagination";
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import SearchFilter from "components/shared/SearchFilter";
import { DATE_FORMAT_TIME, FORMAT_TEXT } from "helpers/common";
//services
import { getList_, deleteData_ } from 'store/services/commonService';

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader, userProfile, user }) => {
  let userPermission = userProfile && userProfile?.permissions?.length > 0 ? JSON.parse(userProfile?.permissions) : {}
  let userData = user?.permissions && user?.permissions?.length > 0 ? JSON.parse(user?.permissions) : userPermission
  let userRole = userProfile ? userProfile?.role : user?.role
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: '',
    order:1,
    orderBy: 'createdAt'
  })
  //listing data
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //filter
  let listToDisplay = listing
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list.name.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }

  //get data
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: 'promotion/getWithFilter',
        common: false,
      };
      setIsLoading(true)
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData()
  }, [filter])

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue
    })
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: ""
    })
  }

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page
    })
    window.scrollTo(0, 0);
  };

  //delete
  const deleteDataFunction_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              url: 'promotion/remove',
              id: id,
              status: "deleted",
              common: false
            }
            await dispatch(deleteData_(query)).then(() => {
              fetchData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const selectSearchOption = [
    {
      id: '1', value: 'active', name: 'Active'
    },
    {
      id: '2', value: 'inactive', name: 'Inactive'
    }
  ]

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value
      });
    }
  };
  

  useEffect(() => {
    let objPer = userData['promotions']
    setuserSubPermissions(objPer)
  }, [userProfile])

  //render html
  const renderList_ = () => {
    return listToDisplay?.length > 0 && listToDisplay.map((list, i) => <tr key={i}>
      <td>
        <img src={list?.image ? list?.image : MAIN_DUMMY_} class="mr-2" alt="image" /> {list?.name ? FORMAT_TEXT(list?.name) : ''}
      </td>
      <td><label style={{ cursor: 'pointer' }} className={`badge badge-${list?.status === 'active' ? 'success payment_status' : 'danger red_col'}`}>{list?.status === 'active' ? 'Active' : list?.status === 'inactive' ? 'Inactive' : 'Deleted'}</label></td>
      <td>{list?.createdAt ? DATE_FORMAT_TIME(list?.createdAt) : 'N/A'}</td>
      <td>
        {(userRole === 'SUBADMIN' && userSubPermissions !== undefined) ? (
          <>
            {userSubPermissions?.update &&
              <>
                <Link to={`${Path.promotion_edit}/${list?._id}`} ><label class="btn btn-sm" style={{ cursor: 'pointer' }} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link>
                &nbsp;&nbsp;
              </>
            }
            {userSubPermissions?.delete &&
              <>
                <label className="btn btn-sm" onClick={() => deleteDataFunction_(list?._id)} style={{ cursor: 'pointer' }} title="Delete"><i className="mdi mdi-delete"></i></label>
              </>
            }
          </>
        ) : (userRole === 'ADMIN' && userSubPermissions === undefined &&
          <>
            <Link to={`${Path.promotion_edit}/${list?._id}`} ><label class="btn btn-sm" style={{ cursor: 'pointer' }} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link>
            &nbsp;&nbsp;
            <label className="btn btn-sm" onClick={() => deleteDataFunction_(list?._id)} style={{ cursor: 'pointer' }} title="Delete"><i className="mdi mdi-delete"></i></label>
          </>
        )}
      </td>
    </tr>);
  };

  return (
    <>
      <Helmet title={PROMOTIONS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">
          {PROMOTIONS.CURRENT_MODULE}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={Path.promotion}>{PROMOTIONS.CURRENT_MODULE}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Listing</li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <SearchFilter
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  clearSerchFilter={clearSerchFilter}
                  options={selectSearchOption}
                  SearchText="Search by name"
                  isSearchDate={false}
                  isExportButton={false}
                  isAddButton={userRole === 'SUBADMIN' && userSubPermissions !== undefined && userSubPermissions?.create ? true : userRole === 'ADMIN' && userSubPermissions === undefined ? true : false}
                  isSelectBox={true}
                  isInput={true}
                  addLink={Path.promotion_add}
                  filter={filter}
                  setFilter={setFilter}
                  dropdownField='status'
                />
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th onClick={() => handleSortingFunc('name')}>Name<i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'name' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'name' ? 'Desc' :'Asc')}></i> </th>
                      <th> Status </th>
                      <th onClick={() => handleSortingFunc('createdAt')}>Created At <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'createdAt' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'createdAt' ? 'Desc' :'Asc')}></i> </th>
                      {(userRole === 'SUBADMIN' && userSubPermissions !== undefined) && (userSubPermissions?.delete || userSubPermissions?.update) ? (
                        <th> Action</th>
                      ) : (userRole === 'ADMIN' && userSubPermissions === undefined &&
                        <th> Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {renderList_()}
                  </tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing?.length == 0 &&
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Listing);

