import {
  getData,
  postData,
  postData_,
  deleteDataMethod,
  putData,
} from "helpers/Helper";
import {
  APP_PATH,
  GET_JOB,
  ADD_JOB,
  UPDATE_JOB,
  VIEW_JOB,
  DELETE_JOB,
} from "./apiPath";
import {
  getJob,
  addJob,
  updateJob,
  viewJob,
  deleteJob,
} from "store/actions/jobs";
import { persist_store } from "store/actions/index";

export function getJob_(value, callback) {
  const { page, limit, url, common } = value || {};
  let urlPath = `${APP_PATH}${GET_JOB}`;
  //   if (page !== undefined && limit !== undefined) {
  //     urlPath += `?page=${page}&limit=${limit}`;
  //   }
  return async (dispatch) => {
    // Set loader state to true before making the API call
    dispatch(persist_store({ loader: true }));
    // Make the API call
    try {
      const data = await postData_(urlPath, value);

      // Dispatch the getList action with the data received from the API
      dispatch(getJob(data, callback));
      // Set loader state to false after the API call is complete
      dispatch(persist_store({ loader: false }));
      return data;
    } catch (error) {
      // In case of an error, also set loader state to false
      dispatch(persist_store({ loader: false }));
      // You might want to handle the error here or propagate it further
      console.error("Error fetching data:", error);
    }
  };
}

//add
export function addJob_(value, callback) {
  return (dispatch) =>
    postData(`${APP_PATH}${ADD_JOB}`, value).then((data) => {
      dispatch(addJob(data, callback));
      return data;
    });
}

//view
export function viewJob_(value, callback) {
  let urlPath = `${APP_PATH}${VIEW_JOB}${value}`;
  return async (dispatch) => {
    dispatch(persist_store({ loader: true }));
    try {
      const data = await getData(urlPath, value);
      dispatch(viewJob(data, callback));
      dispatch(persist_store({ loader: false }));
      return data;
    } catch (error) {
      dispatch(persist_store({ loader: false }));
      console.error("Error fetching data:", error);
    }
  };
}

export function updateJob_(value, callback) {
  return (dispatch) =>
    putData(`${APP_PATH}${UPDATE_JOB}${value?.jobId}`, value).then((data) => {
      dispatch(updateJob(data, callback));
      return data;
    });
}

export function deleteJob_(value, callback) {
  let url = `${APP_PATH}${DELETE_JOB}${value?.jobId}`;
  return (dispatch) =>
    deleteDataMethod(url).then((data) => {
      dispatch(deleteJob(data, callback));
      return data;
    });
}
