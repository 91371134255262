import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import { PAGE_LIMIT } from "helpers/viewContent";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { CMS } from "helpers/viewContent";
//services
import { getCms_ } from "store/services/pagesService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PopularStocks from "./PopularStocks";
import Industries from "./Industries";
import ContentList from "./ContentList";
import StocksList from "./StocksList";

const Listing = ({ dispatch, userProfile, user, indexApp }) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  const [tabIndex, setTabIndex] = useState(indexApp ? indexApp : 0);
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getCms_(filter));
      setListing(res?.data?.data);
      setTotalCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    let objPer = userData["cms"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  const setIndexFunction = (index) => {
    setTabIndex(index);
    dispatch(actions.persist_store({ indexApp: null }));
  };

  useEffect(() => {
    let objPer = userData["cms"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={CMS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{CMS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.cms}>{CMS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => setIndexFunction(index)}
      >
        <TabList>
          <Tab>Popular Stocks</Tab>
          <Tab>Industries</Tab>
          <Tab>Stock List</Tab>
          <Tab>T&C</Tab>
        </TabList>
        <TabPanel>
          <PopularStocks
            tabIndex={tabIndex}
            dispatch={dispatch}
            userSubPermissions={userSubPermissions}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel>
          <Industries
            tabIndex={tabIndex}
            dispatch={dispatch}
            userSubPermissions={userSubPermissions}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel>
          <StocksList
            tabIndex={tabIndex}
            dispatch={dispatch}
            userSubPermissions={userSubPermissions}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel>
          <ContentList
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            dispatch={dispatch}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.Pages.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
    indexApp: state?.persistStore?.indexApp,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
