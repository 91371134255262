import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import * as actions from 'store/actions/index.js';
//services
import { addData_ } from 'store/services/commonService';

const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
});

const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

export default ({ operation, handleOperationClose, handleOperationShow, dispatch, setOperation, editData }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [showBtn, setShowBtn] = useState(false);

    const [fields, setfields] = useState({
        amount: "",
        description: "",
        type:""
    })
    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    
    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                fields.email = editData?.email
                let query = {
                    url: "send/money",
                    to_account:editData?.accountId,
                    entry_type:'JNLC',
                    ...fields,
                }
                setShowBtn(true)
                await dispatch(addData_(query));
                setShowBtn(false)
                setOperation(false)
                setfields({
                    amount: "",
                    description: "",
                    type:""
                })
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setShowBtn(false)
            console.log(err);
        }
    }

    return (
        <>
            <Modal
                show={operation}
                onHide={handleOperationClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Balance Operation
                    </Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Amount*</label>
                            <input type="number" className="form-control" id="exampleInputName1" placeholder="$20" name="amount" onChange={handleChange("amount")} />
                            {validationErrors.amount && (
                                <div className="text-danger err">{validationErrors.amount}</div>
                            )}
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Type*</label>
                            <div className="col-md-12">
                                <select className="form-select" name="type" onChange={handleChange("type")}>
                                    <option value="" selected disabled>Select </option>
                                    <option value="credit" selected="">Credit </option>
                                    <option value="debit" selected="">Debit </option>
                                 
                                </select>
                                {validationErrors.type && (
                                    <div className="text-danger err">{validationErrors.type}</div>
                                )}
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                            <label for="exampleInputName1">Description*</label>
                            <textarea className="form-control" id="exampleInputName1" name="description" onChange={handleChange("description")} />
                            {validationErrors.description && (
                                <div className="text-danger err">{validationErrors.description}</div>
                            )}
                        </div>
                    </div>
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" onClick={(e) => handleSubmit(e)} disabled={showBtn ? true : false} title="Send" className="btn btn-gradient-primary me-2">{showBtn ? 'Wait..' : 'Pay'}
                    </button>
                    <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleOperationClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};