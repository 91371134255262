import { UPLOAD_IMAGE, DELETE_UPLOAD_IMAGE} from 'store/actions/file';

const initialState = {
  isAuthenticated: false,
  fileData: [],
  loader:false,
};

//upload image
const uploadImage = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      fileData:payload,
    };
    return stateObj;
  };
  
  //delete image
  const deleteUploadImage = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      fileData:payload,
    };
    return stateObj;
  };


const File = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case UPLOAD_IMAGE:
          return uploadImage(state, payload);
        case DELETE_UPLOAD_IMAGE:
          return deleteUploadImage(state, payload);
 
        default:
        return state;
    };
}
export default File;
