import { getData, postData, postData_, putData } from "helpers/Helper";
import { APP_PATH, COMMON_PATH } from "./apiPath";
import {
  getList,
  addData,
  updateData,
  deleteData,
  getDetail,
  getWithoutPaginationList,
  updatePutData,
} from "store/actions/common";
import { persist_store } from "store/actions/index";
//list
export function getList_(value, callback) {
  let urlPath = `${
    value?.common ? `${COMMON_PATH}/${value?.url}` : `${APP_PATH}/${value?.url}`
  }`;
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return postData_(urlPath, value)
      .then((data) => {
        dispatch(getList(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch((error) => {
        dispatch(persist_store({ loader: false }));
        console.error("Error fetching data:", error);
      });
  };
}

export function getWithoutPaginationList_(value, callback) {
  let urlPath = `${APP_PATH}/${value?.url}`;
  return (dispatch) =>
    getData(urlPath, value).then((data) => {
      dispatch(getWithoutPaginationList(data, callback));
      return data;
    });
}
//add
export function addData_(value, callback) {
  let urlPath = `${
    value?.common ? `${COMMON_PATH}/${value?.url}` : `${APP_PATH}/${value?.url}`
  }`;
  return (dispatch) =>
    postData(urlPath, value).then((data) => {
      dispatch(addData(data, callback));
      return data;
    });
}

export function updateData_(value, callback) {
  let urlPath = `${
    value?.common ? `${COMMON_PATH}/${value?.url}` : `${APP_PATH}/${value?.url}`
  }`;
  return (dispatch) =>
    postData(urlPath, value).then((data) => {
      dispatch(updateData(data, callback));
      return data;
    });
}

export function updatePutData_(value, callback) {
  let urlPath = `${
    value?.common ? `${COMMON_PATH}/${value?.url}` : `${APP_PATH}/${value?.url}`
  }`;
  return (dispatch) =>
    putData(urlPath, value).then((data) => {
      dispatch(updatePutData(data, callback));
      return data;
    });
}

export function deleteData_(value, callback) {
  let urlPath = `${
    value?.common ? `${COMMON_PATH}/${value?.url}` : `${APP_PATH}/${value?.url}`
  }`;
  return (dispatch) =>
    postData(urlPath, value).then((data) => {
      dispatch(deleteData(data, callback));
      return data;
    });
}
export function getDetail_(value, callback) {
  let urlPath = `${
    value?.common
      ? `${COMMON_PATH}/${value?.url}/${value?.id}`
      : `${APP_PATH}/${value?.url}/${value?.id}`
  }`;
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return getData(urlPath)
      .then((data) => {
        dispatch(getDetail(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch((error) => {
        dispatch(persist_store({ loader: false }));
        console.error("Error fetching data:", error);
      });
  };
}
