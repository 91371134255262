import {postData_} from 'helpers/Helper'
import { APP_PATH, GET_DASHBOARD_} from './apiPath'
import { getDashboard } from 'store/actions/dashboard';
import { persist_store } from 'store/actions/index';

export function getDashboard_(value, callback) {
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return postData_(`${GET_DASHBOARD_}`, value)
      .then((data) => {
        dispatch(getDashboard(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        dispatch(persist_store({ loader: false }));
        console.error('Error fetching data:', error);
      });
  };
}
