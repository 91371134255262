import {
  getData,
  postData,
  postData_,
  deleteDataMethod,
  putData,
} from "helpers/Helper";
import {
  APP_PATH,
  GET_FEATURE,
  ADD_FEATURE,
  UPDATE_FEATURE,
  VIEW_FEATURE,
  DELETE_FEATURE,
} from "./apiPath";
import {
  getFeature,
  addFeature,
  updateFeature,
  viewFeature,
  deleteFeature,
} from "store/actions/feature";
import { persist_store } from "store/actions/index";

export function getFeature_(value, callback) {
  const { page, limit, url, common } = value || {};
  let urlPath = `${APP_PATH}${GET_FEATURE}`;
  //   if (page !== undefined && limit !== undefined) {
  //     urlPath += `?page=${page}&limit=${limit}`;
  //   }
  return async (dispatch) => {
    // Set loader state to true before making the API call
    dispatch(persist_store({ loader: true }));
    // Make the API call
    try {
      const data = await postData_(urlPath, value);

      // Dispatch the getList action with the data received from the API
      dispatch(getFeature(data, callback));
      // Set loader state to false after the API call is complete
      dispatch(persist_store({ loader: false }));
      return data;
    } catch (error) {
      // In case of an error, also set loader state to false
      dispatch(persist_store({ loader: false }));
      // You might want to handle the error here or propagate it further
      console.error("Error fetching data:", error);
    }
  };
}

//add
export function addFeature_(value, callback) {
  return (dispatch) =>
    postData(`${APP_PATH}${ADD_FEATURE}`, value).then((data) => {
      dispatch(addFeature(data, callback));
      return data;
    });
}

//view
export function viewFeature_(value, callback) {
  let urlPath = `${APP_PATH}${VIEW_FEATURE}${value}`;
  return async (dispatch) => {
    dispatch(persist_store({ loader: true }));
    try {
      const data = await getData(urlPath, value);
      dispatch(viewFeature(data, callback));
      dispatch(persist_store({ loader: false }));
      return data;
    } catch (error) {
      dispatch(persist_store({ loader: false }));
      console.error("Error fetching data:", error);
    }
  };
}

export function updateFeature_(value, callback) {
  return (dispatch) =>
    putData(`${APP_PATH}${UPDATE_FEATURE}${value?.featureId}`, value).then(
      (data) => {
        dispatch(updateFeature(data, callback));
        return data;
      }
    );
}

export function deleteFeature_(value, callback) {
  let url = `${APP_PATH}${DELETE_FEATURE}${value?.featureId}`;
  return (dispatch) =>
    deleteDataMethod(url).then((data) => {
      dispatch(deleteFeature(data, callback));
      return data;
    });
}
