import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//imported
import ReactPagination from "components/shared/ReactPagination";

import { PAYMENTS, PAGE_LIMIT } from "helpers/viewContent";
import * as Path from "routes/paths";
import PaymentModal from "./PaymentModal";
import SearchFilter from "components/shared/SearchFilter";
import {
  DATE_FORMAT_TIME_,
  DATE_FORMAT_FOR_DB_,
  paymentDatacsv,
  FORMAT_TEXT_VALUE,
} from "helpers/common";
//services
import { getList_, updateData_ } from "store/services/commonService";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader }) => {
  //history
  const history = useHistory();
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [csvDataP, setCsvDataP] = useState([]);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [disabledButtonsCancel, setDisabledButtonsCancel] = useState([]);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    type: "",
    order: 1,
    userId: "",
    startDate: "",
    orderBy: "createdAt",
  });
  const [fields, setfields] = useState({
    search_date: "",
  });
  // console.log(fields.search_date);
  let searchFilterDate = fields.search_date;
  const [listing, setListing] = useState([]);
  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list.name.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }
  //show modal
  const [show, setShow] = useState(false);
  const [currenTransaction, setCurrenTransaction] = useState(null);
  const [key, setKey] = useState(0);
  const handleClose = () => {
    setShow(false);
    setCurrenTransaction(null);
  };
  const handleShow = (list) => {
    setShow(true);
    setCurrenTransaction(list);
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getUserTransactionsWithFilter",
        startDate: fields?.search_date ? searchFilterDate : "",
        type: tabIndex == 1 ? "withdrawl" : tabIndex == 2 ? "incoming" : "",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      let arrP = await paymentDatacsv(res?.data?.data);
      setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter, tabIndex, fields]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
    setfields({
      search_date: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const selectSearchOption = [
    {
      id: "1",
      value: "pending",
      name: "Pending",
    },
    {
      id: "2",
      value: "active",
      name: "Active",
    },
  ];

  //approve reject button
  const approveTransaction = async (data, status, index) => {
    try {
      // Create a new array to update disabledButtons state
      const updatedDisabledButtons = [...disabledButtons];
      // Disable the button at the specified index
      updatedDisabledButtons[index] = true;
      // Update the state to disable the button
      setDisabledButtons(updatedDisabledButtons);

      let query = {
        url: "approveOrRejectWithrawRequest",
        transactionId: data?._id,
        status: status,
      };
      let res = await dispatch(updateData_(query));
      updatedDisabledButtons[index] = false;
      setDisabledButtons(updatedDisabledButtons);
      fetchData();
      // setIsSubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };
  const RejectTransaction = async (data, status, index) => {
    try {
      // Create a new array to update disabledButtons state
      const updatedDisabledButtons = [...disabledButtonsCancel];
      // Disable the button at the specified index
      updatedDisabledButtons[index] = true;
      // Update the state to disable the button
      setDisabledButtonsCancel(updatedDisabledButtons);

      let query = {
        url: "approveOrRejectWithrawRequest",
        transactionId: data?._id,
        status: status,
      };
      let res = await dispatch(updateData_(query));
      updatedDisabledButtons[index] = false;
      setDisabledButtonsCancel(updatedDisabledButtons);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  //render html
  const renderList_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td
            onClick={() =>
              list?.userDetails?.first_name
                ? history.push(`${Path.userview}/${list?.userDetails?._id}`)
                : console.log("no value")
            }
            title="User View"
          >
            <b>
              {list?.userDetails?.first_name
                ? list?.userDetails?.first_name
                : "N/A"}
            </b>
          </td>
          <td
            onClick={() =>
              list.transactionId ? handleShow(list) : console.log("no value")
            }
            title="View"
          >
            <b>{list.transactionId ? list.transactionId : "N/A"}</b>
          </td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
          <td>
            $
            {list.amount != null && !isNaN(list.amount)
              ? list.amount.toFixed(2)
              : "0"}
          </td>
          <td>{list.transactionType}</td>
          <td>
            <label className="badge badge-success payment_status">
              {FORMAT_TEXT_VALUE(list.status)}
            </label>
          </td>
        </tr>
      ))
    );
  };

  //render withdrwal html
  const renderListW_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }}>
          <td
            onClick={() =>
              list?.userDetails?.first_name
                ? history.push(`${Path.userview}/${list?.userDetails?._id}`)
                : console.log("no value")
            }
            title="User View"
          >
            <b>
              {list?.userDetails?.first_name
                ? list?.userDetails?.first_name
                : "N/A"}
            </b>
          </td>
          <td
            onClick={() =>
              list.userDetails?.accountId
                ? handleShow(list)
                : console.log("no value")
            }
          >
            <b>
              {list?.userDetails?.accountId
                ? list?.userDetails?.accountId
                : "N/A"}
            </b>
          </td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
          <td>
            $
            {list.amount != null && !isNaN(list.amount)
              ? parseInt(list.amount).toFixed(2)
              : "0"}
          </td>
          <td>{list.transactionType}</td>
          <td>
            <label className="badge badge-success payment_status">
              {FORMAT_TEXT_VALUE(list.status)}
            </label>
          </td>
          <td>
            {list?.status && list?.status === "pending" ? (
              <>
                <button
                  type="submit"
                  onClick={() => approveTransaction(list, "approved", i)}
                  className="btn btn-sm btn-gradient-primary"
                  title="Incomplete"
                  disabled={disabledButtons[i]}
                >
                  Approve
                </button>
                &nbsp; &nbsp;
                <button
                  type="submit"
                  onClick={() => RejectTransaction(list, "rejected", i)}
                  className="btn btn-sm btn-gradient-primary"
                  title="Incomplete"
                  disabled={disabledButtonsCancel[i]}
                >
                  Reject
                </button>
              </>
            ) : (
              <>
                <label className="badge badge-success payment_status">
                  {list?.status ? list?.status : "N/A"}
                </label>
              </>
            )}
          </td>
        </tr>
      ))
    );
  };

  return (
    <>
      <Helmet title={PAYMENTS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{PAYMENTS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.payment}>{PAYMENTS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <SearchFilter
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  clearSerchFilter={clearSerchFilter}
                  options={selectSearchOption}
                  SearchText="Search by name"
                  isSearchDate={true}
                  setfields={setfields}
                  field={fields}
                  isExportButton={true}
                  isAddButton={false}
                  isSelectBox={true}
                  filter={filter}
                  setFilter={setFilter}
                  dropdownField="status"
                  key={key}
                  csvData={csvDataP}
                  isFutureHide={true}
                />
              </h4>

              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Manage Payments</Tab>
                  <Tab>Withdrawal</Tab>
                  <Tab>Deposit</Tab>
                </TabList>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("name")}>
                            {" "}
                            Customer{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() => handleSortingFunc("transactionId")}
                          >
                            {" "}
                            Transaction Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            {" "}
                            Date & Time{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("amount")}>
                            {" "}
                            Transaction Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Type </th>
                          <th> Status </th>
                        </tr>
                      </thead>
                      <tbody>{renderList_()}</tbody>
                    </table>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("first_name")}>
                            {" "}
                            Customer{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "first_name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "first_name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("accountId")}>
                            {" "}
                            Transaction ID{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            {" "}
                            Date & Time{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("amount")}>
                            {" "}
                            Transaction Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Transaction Type </th>
                          <th> Status </th>
                          {tabIndex === 1 && (
                            <>
                              <th> Action </th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>{renderListW_()}</tbody>
                    </table>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("name")}>
                            {" "}
                            Customer{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() => handleSortingFunc("transactionId")}
                          >
                            {" "}
                            Transaction Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            {" "}
                            Date & Time{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("amount")}>
                            {" "}
                            Transaction Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Type </th>
                          <th> Status </th>
                        </tr>
                      </thead>
                      <tbody>{renderList_()}</tbody>
                    </table>
                  </div>
                </TabPanel>
              </Tabs>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <PaymentModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        currenTransaction={currenTransaction}
        tabIndex={tabIndex}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
