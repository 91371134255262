import React, { useState, useEffect, useCallback, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import { ERROR_MESSAGE } from "helpers/constantsMessage";

import { PAGE_LIMIT_ } from 'helpers/viewContent';
import ReactPagination from "components/shared/ReactPagination";
import { debounce } from 'lodash';

import * as actions from 'store/actions/index.js';
//services
import { addData_, getList_, updatePutData_, updateData_ } from 'store/services/commonService';
import  FileUploadInput  from 'components/shared/FileUploadInput'
import { UploadMediaFile } from "helpers/uploadFile";

export default ({ dispatch, industryModal, handleIndustryShow, handleIndustryClose, currentIndustryId, setIsUpdateApi, setIndustryModal, setCurrentIndustryId }) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
    const [showSubmitBtn, setshowSubmitBtn] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [files, setFiles] = useState(null);

    const [fields, setfields] = useState({
        name:"",
        imgUrl: "",
    })
    const handleChange = useCallback(
        name => evt => {
          setfields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
          setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    const handleFileChangeUpload = async (fileVal) => {
        if(fileVal){
            let finalPath = fileVal?.file
            const shouldUpload = finalPath!==undefined && finalPath?.type.startsWith("image/");
            if(shouldUpload){
                let data = await UploadMediaFile(dispatch, finalPath, "users", "image")
                return data ? data?.url : {};
            }else{
                return fileVal;
            }
        }
    }
    //submit data
    const handleSubmit = async (evt ) => {
        try{
          evt.preventDefault();
          const validation = await validateData(fields);
          if (validation.isValid) {
                let imagePath = await handleFileChangeUpload(files);
                console.log(imagePath, "imagePath")
                if(imagePath==='null'){
                    await ERROR_MESSAGE("Image is required.")

                }else{
                    let obj={}
                    setshowSubmitBtn(true)
                    if(currentIndustryId){
                        obj = {
                            url : 'industry/update',
                            ...fields,
                            id:currentIndustryId?._id,
                            imgUrl : imagePath ? imagePath: fields?.imgUrl,
                        }
                        await dispatch(updateData_(obj));
                        setCurrentIndustryId(null)
                    }else{
                        obj = {
                            url : 'industry/add',
                            ...fields,
                            imgUrl : imagePath ? imagePath: fields?.imgUrl,
                        }
                        await dispatch(addData_(obj));
                    }
                    setshowSubmitBtn(false)
                    setIndustryModal(false)
                    setIsUpdateApi(true)
                    setFiles({})
                }
            } else {
                setValidationErrors(validation.errors);
            }
            } catch (err) {
                console.log(err);
                setshowSubmitBtn(false)
            }
    }
    console.log(currentIndustryId, "currentIndustryId")
    
    useEffect(() => {
        setFiles(`${currentIndustryId?.imgUrl ? currentIndustryId?.imgUrl : null}`)
        setfields({
            name:currentIndustryId?.name,
            imgUrl:currentIndustryId?.imgUrl ? currentIndustryId?.imgUrl : null
        })
    }, [currentIndustryId])

    return (
        <>
            <Modal
                show={industryModal}
                onHide={handleIndustryClose}
                backdrop="static"
                keyboard={false}
                className="modalWidthCstm"
            >
                <Modal.Header>
                    <Modal.Title>
                        Add/Edit
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="form-group">
                        <label for="exampleInputName1">Name*</label>
                        <input type="text" className="form-control" id="exampleInputName1" placeholder="Name" name="name" onChange={handleChange("name")}
                            value={fields.name}
                        />
                        {validationErrors.name && (
                            <div className="text-danger err">{validationErrors.name}</div>
                        )}
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <FileUploadInput dispatch={dispatch} files={files} setFiles={setFiles} fields={fields?.imgUrl}/>
                        </div>
                    </div>
                    {/* {validationErrors.imgUrl && (
                        <div className="text-danger err">{validationErrors.imgUrl}</div>
                    )} */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit"  disabled={showSubmitBtn ? true : false} title="Update" className="btn btn-gradient-primary me-2">{showSubmitBtn ? 'Wait..' : 'Update'}
                        </button>   
                        <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={handleIndustryClose}>Cancel </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    );
};