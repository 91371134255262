import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { LOGS } from "helpers/viewContent";
import { DATE_FORMAT_TIME } from "helpers/common";
//services
import { getDetail_ } from "store/services/commonService";

const Detail = ({ dispatch, match, userProfile, user }) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  let editkey = match.params.id;
  const [editData, setEditData] = useState({});
  async function getData() {
    try {
      let query = {
        url: "log/detail",
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setEditData(res?.data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getData();
    }
  }, []);
  let usersId = editData?.updateData?.userId
    ? editData?.updateData?.userId
    : editData?.updateData?.customerId;

  let LinkRedirection =
    editData?.type === "edit_user"
      ? `${Path.userview}/${usersId}`
      : editData?.type === "comment_added"
      ? `${Path.userview}/${usersId}`
      : editData?.type === "promotion_added"
      ? `${Path.promotion}`
      : editData?.type === "task_added"
      ? `${Path.backoffice}`
      : editData?.type === "edit_admin_profile"
      ? `${Path.profile}`
      : editData?.type === "task_update"
      ? `${Path.backoffice}`
      : editData?.type === "content_update"
      ? `${Path.cms_form}/${editData?.updateData?._id}`
      : editData?.type === "promotion_update"
      ? `${Path.promotion_edit}/${editData?.updateData?._id}`
      : "";

  useEffect(() => {
    let objPer = userData["manage_logs"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={LOGS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{LOGS.CURRENT_VIEW}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.manage_logs}>{LOGS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description"> Logs info </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Text</label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.text ? editData?.text : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">Type</label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.type ? editData?.type : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-6 col-form-label">
                      Created At
                    </label>
                    <div className="col-sm-6 col-form-label">
                      {editData?.createdAt
                        ? DATE_FORMAT_TIME(editData?.createdAt)
                        : "N/A"}
                    </div>
                  </div>
                </div>
                {userRole === "SUBADMIN" && userSubPermissions !== undefined ? (
                  <>
                    {userSubPermissions?.update && (
                      <>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-6 col-form-label">
                              Module View
                            </label>
                            <div className="col-sm-6 col-form-label">
                              <Link to={`${LinkRedirection}`}>Click here</Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  userRole === "ADMIN" &&
                  userSubPermissions === undefined && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-6 col-form-label">
                            Module View
                          </label>
                          <div className="col-sm-6 col-form-label">
                            <Link to={`${LinkRedirection}`}>Click here</Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Detail);
