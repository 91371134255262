import axios from "axios";
//imported
import store from "../store";
import { authLogout } from "../store/actions/auth";
const token = localStorage.getItem("token");
const adminid = localStorage.getItem("adminid");

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.headers.common.token = `${token}`;
axios.defaults.headers.common.adminid = `${JSON.parse(adminid)}`;
// axios.defaults.headers.post["Content-Type"] =
// "application/x-www-form-urlencoded";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = true;
// Add a request interceptor to handle raw JSON data
axios.interceptors.request.use((request) => {
  if (
    request.headers["Content-Type"] === "application/json" &&
    request.data &&
    typeof request.data !== "string"
  ) {
    request.data = JSON.stringify(request.data);
  }
  return request;
});

// Create instance
const axiosInstance = axios.create();

// Axios wrapper to handle error
const axiosWrapper = (apiCall) =>
  apiCall.then((res) => res.data).catch((err) => Promise.reject(err));

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response?.data?.status === "failure" &&
      response?.data?.message === "Not Authorized!"
    ) {
      store.dispatch(authLogout());
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
