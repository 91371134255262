import React, { useState, useEffect, useMemo } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import ReactPagination from "components/shared/ReactPagination";
import { DELETE_ALERT } from "helpers/constantsMessage"
import { SYSTMENACCESS, PAGE_LIMIT } from 'helpers/viewContent';
import { FORMAT_TEXT, DATE_FORMAT_TIME } from "helpers/common";
//services
import { getList_, deleteData_ } from 'store/services/commonService';

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader, userProfile, user }) => {
  let userPermission = userProfile && userProfile?.permissions?.length > 0 ? JSON.parse(userProfile?.permissions) : {}
  let userData = user?.permissions && user?.permissions?.length > 0 ? JSON.parse(user?.permissions) : userPermission
  let userRole = userProfile ? userProfile?.role : user?.role
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: '',
    status: '',
    order:1,
    orderBy: 'createdAt'
  })
  const [listing, setListing] = useState([]);
  //filter
  let listToDisplay = listing
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list.name.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: 'getAllAdminList',
        common: false,
      };
      setIsLoading(true)
      let res = await dispatch(getList_(query));
      //console.log(res?.data?.data)
      setListing(res?.data?.data);
      setIsLoading(false)
      dispatch(actions.persist_store({ subadminId: null }));
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData()
  }, [filter])

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue
    })
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: ""
    })
  }
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page
    })
    window.scrollTo(0, 0);
  };
  //delete
  const deleteDataFunction_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              url: 'removeUser',
              userId: id
            }
            await dispatch(deleteData_(query)).then(() => {
              fetchData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  useEffect(() => {
    let objPer = userData['system_access']
    setuserSubPermissions(objPer)
  }, [userProfile, userRole])

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value
      });
    }
  };

  //render html
  const renderList_ = () => {
    return listToDisplay?.length > 0 && listToDisplay.map((list, i) => <tr key={i}>
      <td>
        {list?.name ? FORMAT_TEXT(list?.name) : 'N/A'}
      </td>
      <td>
        {list?.email ? FORMAT_TEXT(list?.email) : 'N/A'}
      </td>
      <td>
        {list?.role ? FORMAT_TEXT(list?.role) : 'N/A'}
      </td>
      <td>{list?.mobileNumber ? `${list?.countryCode} ${list?.mobileNumber}` : 'N/A'}</td>
      <td><label style={{ cursor: 'pointer' }} className={`badge badge-${list?.status === 'active' ? 'success payment_status' : 'danger red_col'}`}>{list?.status === 'active' ? 'Active' : list?.status === 'inactive' ? 'Inactive' : 'Deleted'}</label></td>
      <td>{list?.createdAt ? DATE_FORMAT_TIME(list?.createdAt) : 'N/A'}</td>
      {/* <td>
          <Link to={`${Path.system_access_edit}/${list?._id}`} ><label class="btn btn-sm" style={{cursor:'pointer'}} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link>
          &nbsp;&nbsp;
          <label className="btn btn-sm"  onClick={() => deleteDataFunction_(list?._id)} style={{cursor:'pointer'}} title="Delete"><i className="mdi mdi-delete"></i></label>
        </td> */}
      <td>
        {(userRole === 'SUBADMIN' && userSubPermissions !== undefined) ? (
          <>
            {userSubPermissions?.update &&
              <>
                <Link to={`${Path.system_access_edit}/${list?._id}`} ><label class="btn btn-sm" style={{ cursor: 'pointer' }} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link>
                &nbsp;&nbsp;
              </>
            }
            {userSubPermissions?.delete &&
              <>
                <label className="btn btn-sm" onClick={() => deleteDataFunction_(list?._id)} style={{ cursor: 'pointer' }} title="Delete"><i className="mdi mdi-delete"></i></label>
              </>
            }
          </>
        ) : (userRole === 'ADMIN' && userSubPermissions === undefined &&
          <>
            <Link to={`${Path.system_access_edit}/${list?._id}`} ><label class="btn btn-sm" style={{ cursor: 'pointer' }} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link>
            &nbsp;&nbsp;
            <label className="btn btn-sm" onClick={() => deleteDataFunction_(list?._id)} style={{ cursor: 'pointer' }} title="Delete"><i className="mdi mdi-delete"></i></label>
          </>
        )}
      </td>
    </tr>);
  };

  return (
    <>
      <Helmet title={SYSTMENACCESS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">
          {SYSTMENACCESS.CURRENT_MODULE}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={Path.system_access}>{SYSTMENACCESS.CURRENT_MODULE}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Listing</li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="fillte_main flex-wrap d-flex justify-content-between align-items-center">

                  <div className="filter_name">
                    Listing
                  </div>
                  <div className='center_fillter d-flex flex-wrap gap-3 align-items-center'>
                    <div class="date_time_slector">
                      <div class="input-group m-0">
                        <input
                          className="form-control"
                          autoComplete="chrome-off"
                          name="searchUnique"
                          type="text"
                          placeholder="Search by name"
                          value={filter?.search}
                          onChange={handleSearchChange}
                        />
                        &nbsp; <button type="button" className="btn btn-gradient-primary btn-sm" onClick={clearSerchFilter}><i class="mdi mdi-close-circle"></i></button>
                      </div>
                    </div>
                    {(userRole === 'SUBADMIN' && userSubPermissions !== undefined && userSubPermissions?.create) ? (
                      <div className="button_cta">
                        <Link to={Path.system_access_add} title="Add"><button type="button" class="d-flex align-items-center btn btn-lg btn-gradient-primary">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z" /></svg>
                          </span>
                          Add</button></Link>
                      </div>
                    ) : (userRole === 'ADMIN' && userSubPermissions === undefined &&
                      <div className="button_cta">
                        <Link to={Path.system_access_add} title="Add"><button type="button" class="d-flex align-items-center btn btn-lg btn-gradient-primary">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z" /></svg>
                          </span>
                          Add</button></Link>
                      </div>
                    )}
                  </div>
                </div>
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th onClick={() => handleSortingFunc('name')}> Name <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'name' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'name' ? 'Desc' :'Asc')}></i> </th>
                      <th onClick={() => handleSortingFunc('email')}> Email <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'email' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'email' ? 'Desc' :'Asc')}></i> </th>
                      <th> Role </th>
                      <th> Mobile Number </th>
                      <th> Status </th>
                      <th onClick={() => handleSortingFunc('createdAt')}>  Created At <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'createdAt' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'createdAt' ? 'Desc' :'Asc')}></i> </th>
                      {(userRole === 'SUBADMIN' && userSubPermissions !== undefined) && (userSubPermissions?.delete || userSubPermissions?.update) ? (
                        <th> Action</th>
                      ) : (userRole === 'ADMIN' && userSubPermissions === undefined &&
                        <th> Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {renderList_()}
                  </tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 &&
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Listing);