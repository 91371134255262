import {postMediaData} from 'helpers/Helper'
import { COMMON_PATH, UPLOAD_IMAGE_PATH, DELETE_IMAGE_PATH} from './apiPath'
import { uploadImage, deleteUploadImage,} from 'store/actions/file';
import { persist_store } from 'store/actions/index';

//upload image
export function uploadImage_(formData, callback) {
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return postMediaData(`${COMMON_PATH}${UPLOAD_IMAGE_PATH}`, formData)
      .then((data) => {
        dispatch(uploadImage(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        dispatch(persist_store({ loader: false }));
        console.error('Error fetching data:', error);
      });
  };
}


  //delete image
export function deleteUploadImage_(value, callback) {
    return (dispatch) => postMediaData(`${COMMON_PATH}${DELETE_IMAGE_PATH}`, value)
      .then((data) => {
        dispatch(deleteUploadImage(data, callback));
        return data;
    });
 }
