import React, { useState, useEffect, useCallback } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

import { getDashboard_ } from "store/services/dashboardService";

export default ({
  dashboardList,
  dispatch,
  selectedInterval2,
  setSelectedInterval2,
  transactionData,
  settransactionData,
}) => {
  const currentYear = new Date().getFullYear();
  const futureYears = Array.from(
    { length: 5 },
    (_, index) => currentYear + index
  );
  const data = {
    monthly: selectedInterval2 === "monthly" && {
      uData:
        transactionData?.response?.length > 0
          ? transactionData?.response
          : Array(12).fill(0),
      xLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    weekly: selectedInterval2 === "weekly" && {
      uData:
        transactionData?.response?.length > 0
          ? transactionData?.response
          : Array(7).fill(0),
      xLabels: Array.from({ length: 7 }, (_, index) => `${index + 1}`),
    },
    yearly: selectedInterval2 === "yearly" && {
      uData:
        transactionData?.response?.length > 0
          ? transactionData?.response
          : Array(futureYears.length).fill(0),
      xLabels: futureYears.length > 0 ? futureYears.map(String) : [],
    },
    daily: selectedInterval2 === "daily" && {
      uData:
        transactionData?.response?.length > 0
          ? transactionData?.response
          : Array(31).fill(0),
      xLabels: Array.from({ length: 31 }, (_, index) => `${index + 1}`),
    },
  };

  const handleIntervalChange_ = async (e) => {
    try {
      setSelectedInterval2(e.target.value);
      let query = {
        filter: e.target.value,
        type: "transaction",
      };
      settransactionData({ response: "" });
      let res = await dispatch(getDashboard_(query));
      if (res) {
        settransactionData({
          response: res?.data?.data?.transactionData?.response,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card p-0">
          <div className="card graph_card p-0">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-6">
                  <label for="exampleInputName1">
                    <b>Deposit</b>
                  </label>
                </div>
                <div className="col-md-6">
                  <div className="selector_fillter d-flex gap-3 align-items-center">
                    <div class="filter_drop_filter">
                      <select
                        className="form-select filler_search"
                        value={selectedInterval2}
                        name="interval"
                        onChange={(e) => handleIntervalChange_(e)}
                      >
                        <option value="" disabled selected>
                          {" "}
                          Select Interval{" "}
                        </option>
                        <option
                          value="daily"
                          selected={
                            selectedInterval2 === "daily" ? "selected" : ""
                          }
                        >
                          Daily
                        </option>
                        <option
                          value="weekly"
                          selected={
                            selectedInterval2 === "weekly" ? "selected" : ""
                          }
                        >
                          Weekly
                        </option>
                        <option
                          value="monthly"
                          selected={
                            selectedInterval2 === "monthly" ? "selected" : ""
                          }
                        >
                          Monthly
                        </option>
                        <option
                          value="yearly"
                          selected={
                            selectedInterval2 === "yearly" ? "selected" : ""
                          }
                        >
                          Yearly
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {transactionData?.response?.length > 0 &&
                data[selectedInterval2] && (
                  <LineChart
                    height={410}
                    series={[
                      {
                        data: data[selectedInterval2].uData,
                        color: "#e77476",
                        label: "Deposit Sum",
                        area: true,
                      },
                    ]}
                    xAxis={[
                      {
                        data: data[selectedInterval2].xLabels,
                        scaleType: "band",
                      },
                    ]}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
