import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import { BLOGS } from "helpers/viewContent";
import * as Path from "routes/paths";
import { MAIN__DUMMY_ } from "utils/constant";
import { uploadFile_ } from "helpers/uploadFile";
//services
import { addBlog_, updateBlog_, viewBlog_ } from "store/services/blog";

const AddEdit = ({ dispatch, match, location }) => {
  let editkey = match.params.id;
  const urlParams = new URLSearchParams(location?.search);
  const position = urlParams.get("position") || "";
  const totalCount = urlParams.get("totalCount") || "";

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      // .matches(/^[A-Za-z\s]+$/, "Only text characters are allowed in the title")
      .required("Title is required"),
    indonesiaTitle: Yup.string().required("Indonesia Title is required"),
    header: Yup.string().required("Header is required"),
    indonesiaHeader: Yup.string().required("Indonesia Header is required"),
    description: Yup.string().required("Description is required"),
    indonesiaDescription: Yup.string().required(
      "Indonesia Description is required"
    ),
    // status: Yup.string().required("Status is required"),
    url: Yup.string().required("Image is required"),
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const fileInputRef = useRef(null);

  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [editData, setEditData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageData, setImageData] = useState({});

  const [fields, setfields] = useState({
    title: "",
    indonesiaTitle: "",
    indonesiaDescription: "",
    description: "",
    url: "",
    indonesiaHeader: "",
    header: "",
    count: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  async function getDataView() {
    try {
      let res = await dispatch(viewBlog_(editkey));
      setfields(res?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (editkey) {
      getDataView();
    }
  }, []);

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          ...fields,
        };
        setIsShow(true);
        if (editkey) {
          obj = {
            ...fields,
            blogId: editkey,
          };
          await dispatch(updateBlog_(obj));
        } else {
          await dispatch(addBlog_(obj));
        }
        setIsShow(false);
        history.push(Path.blogs);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  //image
  const handleFileChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let fileName = e.target.name;
    setSending(true);
    let data = await uploadFile_(
      dispatch,
      file,
      setValidationErrors,
      setSelectedFile,
      setPreviewImage,
      fileName
    );
    data = data ? data?.url : {};
    setfields((prevState) => ({
      ...prevState,
      url: data,
    }));
    setEditData(data);
    setSending(false);
    setImageData(data);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await uploadFile_(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Helmet title={BLOGS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{BLOGS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.blogs}>Blog</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add/Edit
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">Add/Edit</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Title*"
                      name="title"
                      onChange={handleChange("title")}
                      value={fields.title}
                    />
                    {validationErrors.title && (
                      <div className="text-danger err">
                        {validationErrors.title}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Indonesia Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Title*"
                      name="indonesiaTitle"
                      onChange={handleChange("indonesiaTitle")}
                      value={fields.indonesiaTitle}
                    />
                    {validationErrors.indonesiaTitle && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaTitle}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Header*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Header*"
                      name="header"
                      onChange={handleChange("header")}
                      value={fields.header}
                      maxLength={50}
                    />
                    {validationErrors.header && (
                      <div className="text-danger err">
                        {validationErrors.header}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Indonesia Header*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Header*"
                      name="indonesiaHeader"
                      onChange={handleChange("indonesiaHeader")}
                      value={fields.indonesiaHeader}
                      maxLength={50}
                    />
                    {validationErrors.indonesiaHeader && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaHeader}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onClick={handleClick}
                    >
                      <div className="form-group">
                        <label for="exampleInputEmail3">Image*</label>
                        <input
                          type="file"
                          class="form-control file-upload-info"
                          disabled=""
                          placeholder="Upload Image"
                          style={{ display: "none" }}
                          name="image"
                          onChange={(e) => handleFileChange(e)}
                          ref={fileInputRef}
                        />
                        <div>
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="img-custom"
                            />
                          ) : editData?.url ? (
                            <img
                              src={editData?.url}
                              alt="Preview"
                              className="img-custom"
                            />
                          ) : (
                            <img
                              src={MAIN__DUMMY_}
                              alt="Default Preview"
                              className="img-custom"
                            />
                          )}
                          {/* <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" /> */}
                        </div>
                        {validationErrors.url && (
                          <div className="text-danger err">
                            {validationErrors.url}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {editkey && (
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="exampleInputJobType">Position*</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Position*"
                        name="count"
                        min={1}
                        max={totalCount}
                        onChange={handleChange("count")}
                        value={fields.count}
                      />
                      {validationErrors.count && (
                        <div className="text-danger err">
                          {validationErrors.count}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">Description*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Description*"
                      name="description"
                      onChange={handleChange("description")}
                      value={fields.description}
                    />
                    {validationErrors.description && (
                      <div className="text-danger err">
                        {validationErrors.description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">
                      Indonesia Description*
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Description*"
                      name="indonesiaDescription"
                      onChange={handleChange("indonesiaDescription")}
                      value={fields.indonesiaDescription}
                    />
                    {validationErrors.indonesiaDescription && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaDescription}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="form-group">
                  <div className="row">
                    <label for="exampleInputPassword4">Status*</label>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value="active"
                            name="status"
                            onChange={handleChange("status")}
                            checked={fields.status === "active" ? true : false}
                          />{" "}
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value="inactive"
                            name="status"
                            onChange={handleChange("status")}
                            checked={
                              fields.status === "inactive" ? true : false
                            }
                          />{" "}
                          Inactive{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {validationErrors.status && (
                    <div className="text-danger err">
                      {validationErrors.status}
                    </div>
                  )}
                </div> */}
                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Wait.." : "Submit"}
                </button>
                <Link to={Path.blogs}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
