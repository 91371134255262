import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import { FEATURE } from "helpers/viewContent";
import * as Path from "routes/paths";
//services
import {
  addFeature_,
  updateFeature_,
  viewFeature_,
} from "store/services/feature";

const AddEdit = ({ dispatch, match, location }) => {
  let editkey = match.params.id;
  const urlParams = new URLSearchParams(location?.search);
  const position = urlParams.get("position") || "";
  const totalCount = urlParams.get("totalCount") || "";
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    indonesiaTitle: Yup.string().required("Indonesia Title is required"),
    description: Yup.string().required("Description is required"),
    indonesiaDescription: Yup.string().required(
      "Indonesia Description is required"
    ),
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [fields, setfields] = useState({
    title: "",
    description: "",
    indonesiaTitle:"",
    indonesiaDescription:"",
    count: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  async function getDataView() {
    try {
      let res = await dispatch(viewFeature_(editkey));
      setfields(res?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (editkey) {
      getDataView();
    }
  }, []);

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          ...fields,
        };
        setIsShow(true);
        if (editkey) {
          obj = {
            ...fields,
            featureId: editkey,
          };
          await dispatch(updateFeature_(obj));
        } else {
          await dispatch(addFeature_(obj));
        }
        setIsShow(false);
        history.push(Path.feature);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  return (
    <>
      <Helmet title={FEATURE.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{FEATURE.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.feature}>Feature</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add/Edit
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add/Edit</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Title*"
                      name="title"
                      onChange={handleChange("title")}
                      value={fields.title}
                    />
                    {validationErrors.title && (
                      <div className="text-danger err">
                        {validationErrors.title}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Indonesia Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Title*"
                      name="indonesiaTitle"
                      onChange={handleChange("indonesiaTitle")}
                      value={fields.indonesiaTitle}
                    />
                    {validationErrors.indonesiaTitle && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaTitle}
                      </div>
                    )}
                  </div>
                </div>
                {editkey && (
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="exampleInputJobType">Position*</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Position*"
                        name="count"
                        min={1}
                        max={totalCount}
                        onChange={handleChange("count")}
                        value={fields.count}
                      />
                      {validationErrors.count && (
                        <div className="text-danger err">
                          {validationErrors.count}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">Description*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Description*"
                      name="description"
                      onChange={handleChange("description")}
                      value={fields.description}
                    />
                    {validationErrors.description && (
                      <div className="text-danger err">
                        {validationErrors.description}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">
                      Indonesia Description*
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Description*"
                      name="indonesiaDescription"
                      onChange={handleChange("indonesiaDescription")}
                      value={fields.indonesiaDescription}
                    />
                    {validationErrors.indonesiaDescription && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaDescription}
                      </div>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Wait.." : "Submit"}
                </button>
                <Link to={Path.feature}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
