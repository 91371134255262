import React, { lazy } from "react";

//imported
import * as Path from "./paths";
//auth
import SignIn from "components/views/admin/auth/Login";
//forgot password
import ForgotPassword from "components/views/admin/auth/ForgotPassword";
import ResetPassword from "components/views/admin/auth/ResetPassword";

//profile
import Profile from "components/views/admin/profile/Profile";
//ChangePassword
import ChangePassword from "components/views/admin/profile/ChangePassword";

//dashboard
import Dashboard from "components/views/admin/dashboard/Dashboard";
//users
import UserList from "components/views/admin/user/Listing";
import UserDetail from "components/views/admin/user/Detail";
import UserAddEdit from "components/views/admin/user/AddEdit";
//payment
import PaymentList from "components/views/admin/payments/Listing";
import PaymentDetail from "components/views/admin/payments/Detail";
import PaymentAddEdit from "components/views/admin/payments/AddEdit";
//promotion
import PromotionList from "components/views/admin/promotions/Listing";
import PromotionDetail from "components/views/admin/promotions/Detail";
import PromotionAddEdit from "components/views/admin/promotions/AddEdit";
//trades
import TradesList from "components/views/admin/trades/Listing";
import TradesDetail from "components/views/admin/trades/Detail";
import TradesAddEdit from "components/views/admin/trades/AddEdit";
//system access
import SystemAccessList from "components/views/admin/system_access/Listing";
import SystemAccessAddEdit from "components/views/admin/system_access/AddEdit";
import SystemAccessDetail from "components/views/admin/system_access/Detail";
//cms
import CMSList from "components/views/admin/cms/Listing";
import CMSAddEdit from "components/views/admin/cms/AddEdit";
import CmsContent from "components/publicCMS/CmsContent";
//FAQ
import FAQList from "components/views/admin/faq/Listing";
import FAQAddEdit from "components/views/admin/faq/AddEdit";
//Contact support
import ContactSupport from "components/views/admin/contact_support/Listing";
//Affiliate Referral
import AffiliateReferralList from "components/views/admin/affiliates_referrals/Listing";
import AffiliateReferralView from "components/views/admin/affiliates_referrals/Detail";
//Report
import ReportList from "components/views/admin/reports/Listing";
import ReportListView from "components/views/admin/reports/Detail";
//Revenue
import RevenueList from "components/views/admin/revenue/Listing";
import RevenueListView from "components/views/admin/revenue/Detail";

// Blogs
import BlogsList from "components/views/admin/blogs/Listing";
import BlogsAddEdit from "components/views/admin/blogs/AddEdit";
import BlogsView from "components/views/admin/blogs/Detail";

// Jobs
import JobsList from "components/views/admin/jobs/Listing";
import JobsAddEdit from "components/views/admin/jobs/AddEdit";
// import JobsView from "components/views/admin/jobs/Detail";

// Testimony
import TestimonyList from "components/views/admin/testimony/Listing";
import TestimonyAddEdit from "components/views/admin/testimony/AddEdit";

// Feature
import FeatureList from "components/views/admin/feature/Listing";
import FeatureAddEdit from "components/views/admin/feature/AddEdit";

// Basic setting
import BasicSetting from "components/views/admin/basicSetting/AddEdit";

//Configuration
import ConfigurationAddEdit from "components/views/admin/configuration/AddEdit";
import ConfigurationList from "components/views/admin/configuration/Listing";
//BackOffice
import BackOfficeList from "components/views/admin/backoffice/Listing";
import BackOfficeView from "components/views/admin/backoffice/Detail";

//ManageLogs
import ManageLogsList from "components/views/admin/manage_logs/Listing";
import ManageLogsView from "components/views/admin/manage_logs/Detail";

//ManageStock
import ManageStockList from "components/views/admin/manage_stocks/Listing";
// import ManageLogsView from "components/views/admin/manage_stock/Detail";
//Bank Request
import BankRequestList from "components/views/admin/bank_request/Listing";

//not found page
import NotFound from "components/NotFound";

const routes = [
  /******** login routes ***********/
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    // component: lazy(() => import('components/views/admin/modules/auth/Login'))
    component: SignIn,
  },
  /******** forgot password routes ***********/
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: ForgotPassword,
  },
  /******** reset password ***********/
  {
    path: Path.reset_password,
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  /******** dashboard ***********/
  // {
  //   path: Path.dashboard,
  //   exact: true,
  //   auth: true,
  //   component: Dashboard,
  // },
  /******** users***********/
  // {
  //   path: Path.users,
  //   exact: true,
  //   auth: true,
  //   component: UserList,
  // },
  // {
  //   path: Path.user_add,
  //   exact: true,
  //   auth: true,
  //   component: UserAddEdit,
  // },
  // {
  //   path: Path.user_edit,
  //   exact: true,
  //   auth: true,
  //   component: UserAddEdit,
  // },
  // {
  //   path: Path.useredit,
  //   exact: true,
  //   auth: true,
  //   component: UserAddEdit,
  // },
  // {
  //   path: Path.user_view,
  //   exact: true,
  //   auth: true,
  //   component: UserDetail,
  // },
  // {
  //   path: Path.userview,
  //   exact: true,
  //   auth: true,
  //   component: UserDetail,
  // },

  /******** Trades ***********/
  {
    path: Path.trade,
    exact: true,
    auth: true,
    component: TradesList,
  },
  // {
  //   path: Path.trade_add,
  //   exact: true,
  //   auth: true,
  //   component: TradesAddEdit,
  // },
  // {
  //   path: Path.trade_edit,
  //   exact: true,
  //   auth: true,
  //   component: TradesAddEdit,
  // },
  // {
  //   path: Path.tradeedit,
  //   exact: true,
  //   auth: true,
  //   component: TradesAddEdit,
  // },
  {
    path: Path.tradeview,
    exact: true,
    auth: true,
    component: TradesDetail,
  },
  {
    path: Path.trade_view,
    exact: true,
    auth: true,
    component: TradesDetail,
  },

  /******** not found page ***********/
  {
    path: "",
    exact: false,
    component: NotFound,
  },
];

export default routes;
