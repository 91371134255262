import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "style/assets/vendors/mdi/css/materialdesignicons.min.css";
import "style/assets/vendors/css/vendor.bundle.base.css";
import "style/assets/vendors/css/vendor.bundle.base.css";
import "style/assets/css/style.css";
import "style/assets/css/custom.css";
import "style/assets/images/favicon.ico";

/******** imported ***********/
import Routes from "./routes";
import store from "./store";
import { authCheck } from "./store/actions/auth";

store.dispatch(authCheck());

function App() {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <Router>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
