import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default ({ fields, handleChange, handleSubmit, isShow, validationErrors, editkey, changePasswordToogle, changePassword, showPassword, togglePasswordVisiblity, setValidationErrors, setfields, backButtonStatus }) => {

    return (
        <>
            <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Full Name*</label>
                        <input type="text" className="form-control" id="exampleInputName1" placeholder="Full Name*" name="name" onChange={handleChange("name")}
                            value={fields.name}
                        />
                        {validationErrors.name && (
                            <div className="text-danger err">{validationErrors.name}</div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">E-mail*</label>
                        <input type="text" className="form-control" id="exampleInputName1" placeholder="E-mail*" name="email" onChange={handleChange("email")}
                            value={fields.email}
                        />
                        {validationErrors.email && (
                            <div className="text-danger err">{validationErrors.email}</div>
                        )}
                    </div>
                </div>
                <div className="row">
                    {editkey &&
                        <div className="form-group col-md-6">
                            <label for="exampleInputName1">You want to change password</label>
                            <div className="col-md-12">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" onClick={(e) => changePasswordToogle(e)} /> {changePassword ? 'Yes' : 'No'} <i className="input-helper"></i></label>
                                </div>
                            </div>
                        </div>
                    }
                    {(changePassword || !editkey) &&
                        <div className="form-group col-md-6">
                            <label for="exampleInputName1">Password*</label>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password*" value={fields.password} onChange={handleChange("password")} />
                                        <div className="input-group-append">
                                            <button onClick={togglePasswordVisiblity} className="btn btn-bg btn-facebook" type="button">
                                                <i  className={showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {validationErrors.password && (
                                    <div className="text-danger err">{validationErrors.password}</div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Mobile Number*</label>
                        <div className="col-md-12">
                            <PhoneInput
                                enableAreaCodes={true}
                                name="mobileNumber"
                                className="form-phone_"
                                country={'us'} // Set the default country, or the one relevant to your use case
                                value={`${fields.mobileNumber}` || ''} // Provide the value for the PhoneInput
                                onChange={(value, country) => {
                                    // Handle phone value change
                                    setfields(prevState => ({
                                        ...prevState,
                                        mobileNumber: value,
                                        countryCode: country.dialCode,
                                    }));
                                    setValidationErrors({ mobileNumber: "" });
                                }}
                                maxLength={15}
                            />
                        </div>
                        {validationErrors.mobileNumber && (
                            <div className="text-danger err">{validationErrors.mobileNumber}</div>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <label for="exampleInputPassword4">Status*</label>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="">
                                    <input type="radio" className="" value={"active"} name="status" onChange={handleChange("status")} checked={fields.status === "active" ? true : false} /> Active
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="">
                                    <input type="radio" className="" value={"inactive"} name="status" onChange={handleChange("status")} checked={fields.status === "inactive" ? true : false} /> Inactive </label>
                            </div>
                        </div>
                    </div>
                    {validationErrors.status && (
                        <div className="text-danger err">{validationErrors.status}</div>
                    )}
                </div>
                <button type="submit" disabled={isShow ? true : false} className="btn btn-gradient-primary me-2">{isShow ? 'Wait..' : 'Submit'}</button>
            </form>
        </>
    );
};