import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import ReactPagination from "components/shared/ReactPagination";
import { PAGE_LIMIT } from "helpers/viewContent";
import SearchFilter from "components/shared/SearchFilter";

//services
import { getList_ } from "store/services/commonService";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalcount, settotalcount] = useState(0);
  // console.log(totalcount);
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "sum/trades",
      };
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      settotalcount(res?.data?.totalcount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter]);

  //filter
  // let listToDisplay = listing;
  // if (filter?.search !== "") {
  //   const searchTerm = filter?.search?.toLowerCase();
  //   listToDisplay = listing.filter((list) => {
  //     const itemName = list?.name;
  //     const itemStockName = list?.shortName;
  //     return (
  //       itemName?.includes(searchTerm) || itemStockName?.includes(searchTerm)
  //     );
  //   });
  // }
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  //render html
  const renderList_ = () => {
    return (
      listing?.length > 0 &&
      listing?.map((list, i) => (
        <tr key={i}>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            title="View"
            className="cursor-pointer"
          >
            <b>{list._id ? `${list?._id}` : "N/A"}</b>
          </td>
          <td>{list?.shortName ? list?.shortName : "N/A"}</td>
          <td>{list.total_qty ? (list?.total_qty).toFixed(2) : "0"}</td>
          <td>
            <label
              className={
                list?.total_unrealized_pl && list.total_unrealized_pl < 0
                  ? "badge badge-danger"
                  : "badge badge-success"
              }
            >
              {list?.total_unrealized_pl
                ? (list?.total_unrealized_pl).toFixed(2)
                : "0"}
            </label>
          </td>
        </tr>
      ))
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              {/* <h4 className="card-title">
                <SearchFilter
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  clearSerchFilter={clearSerchFilter}
                  SearchText="Search by stock name"
                  isSearchDate={false}
                  isExportButton={false}
                  isAddButton={false}
                  isSelectBox={false}
                  isInput={true}
                  filter={filter}
                  setFilter={setFilter}
                  isSelectOtherBox={false}
                  dropdownField="status"
                />
              </h4> */}
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th> Symbol</th>
                      <th> Stock Name</th>
                      <th> Quantity</th>
                      <th> P&L</th>
                    </tr>
                  </thead>
                  <tbody>{renderList_()}</tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalcount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
