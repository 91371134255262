import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

//imported
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { CONTACT_SUPPORT } from 'helpers/viewContent';
import { FORMAT_TEXT, DATE_FORMAT } from "helpers/common";
//services
import { getList_ } from 'store/services/commonService';

const Listing = ({ dispatch }) => {
  const [listing, setListing] = useState([]);
  const [show, setShow] = useState(false);
  const [currentVal, setCurrentVal] = useState(null);

  const handleClose = () => {
    setShow(false);
    setCurrentVal(null)
  }
  const handleShow = (data) => {
    setShow(true);
    setCurrentVal(data)
  }

  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let value = {
        url: 'contact_support',
        common: true
      }
      let res = await dispatch(getList_(value));
      setListing(res?.body)
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Helmet title={CONTACT_SUPPORT.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-contacts menu-icon"></i>
          </span>  {CONTACT_SUPPORT.CURRENT_MODULE}
        </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={Path.contact_support}>{CONTACT_SUPPORT.CURRENT_MODULE}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Listing</li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="row">
                  <div className="col-md-6">
                    Listing
                  </div>
                  <div className="col-md-4">
                  </div>
                </div>
              </h4>
              <div class="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> Name </th>
                      <th> Email </th>
                      <th> Phone Number </th>
                      <th> Message </th>
                      <th> Created At </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listing?.length > 0 && listing.map((list, i) => {
                      return (
                        <tr key={i}>
                          <td className="py-1">
                            {list?.name ? FORMAT_TEXT(list?.name) : '---'}
                          </td>
                          <td className="py-1">
                            {list?.email ? FORMAT_TEXT(list?.email) : '---'}
                          </td>
                          <td>{`${list?.country_code} ${list?.phone}`} </td>
                          <td className="py-1">
                            {list?.message ? FORMAT_TEXT(list?.message) : '---'}
                          </td>
                          <td className="py-1">
                            {list?.created_at ? DATE_FORMAT(list?.created_at) : '---'}
                          </td>
                          <td className="py-1" onClick={() => handleShow(list)}>
                            <label class="badge badge-gradient-info" style={{ cursor: 'pointer' }} title="View" >View</label>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <h6>{currentVal?.message}!</h6>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}

const mapStateToPros = (state) => {
  return {
    loader: state.Pages.loader,
  }
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Listing);