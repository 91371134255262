import {
  GET_JOB,
  ADD_JOB,
  UPDATE_JOB,
  VIEW_JOB,
  DELETE_JOB,
} from "store/actions/jobs";

const initialState = {
  isAuthenticated: false,
  loader: false,
  jobList: [],
  jobDetail: {},
  totalItemsCount: 0,
};

const getJob = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    jobList: data?.data?.data,
    totalItemsCount: data?.data?.totalcount,
  };
  return stateObj;
};

const addJob = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updateJob = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const viewJob = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    jobDetail: payload,
  };
  return stateObj;
};

const deleteJob = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === "function") {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const Jobs = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_JOB:
      return getJob(state, payload);
    case ADD_JOB:
      return addJob(state, payload);
    case UPDATE_JOB:
      return updateJob(state, payload);
    case VIEW_JOB:
      return viewJob(state, payload);
    case DELETE_JOB:
      return deleteJob(state, payload);
    default:
      return state;
  }
};
export default Jobs;
