import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { SYSTMENACCESS } from "helpers/viewContent";
import Permissions from "./Permissions";
//services
import {
  addData_,
  getDetail_,
  updateData_,
} from "store/services/commonService";
import Roles from "./Roles";

const AddEdit = ({ dispatch, match, subadminId }) => {
  let editkey = match.params.id;
  const [initialFields, setInitialFields] = useState({});

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required('Full name is required'),
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only text characters are allowed in the name")
      .required("Full name is required"),
    status: Yup.string().required("Status is required"),
    mobileNumber: Yup.string().required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: !editkey
      ? Yup.string().min(6, "Password must be at least 6 characters")
      : Yup.string(), // Password is not required if editKey exists
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [editData, setEditData] = useState({});
  const [changePassword, setChangePassword] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [backButtonStatus, setbackButtonStatus] = useState(false);

  const [fields, setfields] = useState({
    name: "",
    email: "",
    password: "",
    mobileNumber: "",
    countryCode: "",
    status: "active",
  });

  const optionVal = [
    { value: "dashboard", label: "Dashboard" },
    { value: "user", label: "Users" },
    { value: "payment", label: "Manage Payments" },
    { value: "trades", label: "Manage Trades" },
    { value: "revenue", label: "Revenue" },
    { value: "promotions", label: "Promotions" },
    { value: "report", label: "Report" },
    { value: "affiliates", label: "Affiliates & Referrals" },
    // { value: 'faq', label: 'FAQ' },
    // { value: 'cms', label: 'Content Pages' },
    // { value: 'configuration', label: 'Configuration' },
  ];
  const [options, setOptions] = useState(optionVal);

  //password
  const [showPassword, setShowPassword] = useState(false);

  //password show
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = useCallback(
    (name) => (evt) => {
      const value = evt.target.value;
      setfields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  const changePasswordToogle = (evt) => {
    const isChecked = evt.target.checked;
    setChangePassword(isChecked);
  };
  async function getData() {
    try {
      let query = {
        url: "getUserById",
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setbackButtonStatus(false);
      setEditData(res?.data);
      setfields(res?.data);
      setfields({
        name: res?.data?.name,
        email: res?.data?.email,
        password: res?.data?.password,
        status: res?.data?.status,
        countryCode: res?.data?.countryCode,
        mobileNumber: `${res?.data?.countryCode}${res?.data?.mobileNumber}`,
        isUserDashboard: res?.data?.isUserDashboard,
        isPaymentDashboard: res?.data?.isPaymentDashboard,
        isLastOpen: res?.data?.isLastOpen,
        isGraphPermission: res?.data?.isGraphPermission,
        isNewlyRegistered: res?.data?.isNewlyRegistered,
        isPendentClient: res?.data?.isPendentClient,
      });
      setInitialFields({
        name: res?.data?.name,
        email: res?.data?.email,
        password: res?.data?.password,
        status: res?.data?.status,
        countryCode: res?.data?.countryCode,
        mobileNumber: `${res?.data?.countryCode}${res?.data?.mobileNumber}`,
        isUserDashboard: res?.data?.isUserDashboard,
        isPaymentDashboard: res?.data?.isPaymentDashboard,
        isLastOpen: res?.data?.isLastOpen,
        isGraphPermission: res?.data?.isGraphPermission,
        isNewlyRegistered: res?.data?.isNewlyRegistered,
        isPendentClient: res?.data?.isPendentClient,
      });

      let resData = res?.data?.permissions;
      setSelectedOption(resData);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey || backButtonStatus) {
      getData();
    }
  }, [backButtonStatus]);

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let editedFields = {};
        // Check if the name field has changed
        if (initialFields.name !== fields.name) {
          editedFields = {
            name: fields.name,
          };
        } else if (initialFields.email !== fields.email) {
          editedFields = {
            email: fields.email,
          };
        } else if (initialFields.mobileNumber !== fields.mobileNumber) {
          const phoneNumberWithoutPlus = fields.mobileNumber
            ? fields.mobileNumber.substring(fields?.countryCode?.length)
            : null;
          const codeWithoutPlus = fields?.countryCode;
          editedFields = {
            mobileNumber: phoneNumberWithoutPlus,
            countryCode: fields.countryCode
              ? codeWithoutPlus?.startsWith("+")
                ? codeWithoutPlus
                : "+" + codeWithoutPlus
              : null,
          };
        } else if (
          initialFields.password !== fields.password ||
          changePassword
        ) {
          editedFields = {
            password: fields.password,
          };
        }

        setIsShow(true);
        if (editkey) {
          editedFields = {
            ...editedFields,
            password_update: changePassword,
            userId: editkey,
            url: "editAdmin",
          };
          let result = await dispatch(updateData_(editedFields));
          if (result?.data?.status === "success") {
            dispatch(actions.persist_store({ subadminId: editkey }));
            setChangePassword(false);
            setShowPassword(false);
            setTabIndex(1);
          }
        } else {
          editedFields = {
            ...fields,
            url: "addAdmin",
          };
          let res = await dispatch(addData_(editedFields));
          if (res?.data?.status === "success") {
            dispatch(
              actions.persist_store({ subadminId: res?.data?.data?._id })
            );
            setTabIndex(1);
          }
        }
        // return
        if (tabIndex == 1) {
          history.push(Path.system_access);
          dispatch(actions.persist_store({ subadminId: null }));
        }
        setIsShow(false);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };
  return (
    <>
      <Helmet title={SYSTMENACCESS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{SYSTMENACCESS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.system_access}>System Access</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add/Edit
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <Tabs
                selectedIndex={tabIndex}
                editkey={editkey}
                onSelect={editkey ? (index) => setTabIndex(index) : undefined}
              >
                <TabList>
                  <Tab>Add Role</Tab>
                  <Tab>Assign Permissions</Tab>
                </TabList>
                <TabPanel>
                  <h4 className="card-title">Add/Edit</h4>
                  <Roles
                    fields={fields}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isShow={isShow}
                    validationErrors={validationErrors}
                    editkey={editkey}
                    changePasswordToogle={changePasswordToogle}
                    changePassword={changePassword}
                    showPassword={showPassword}
                    togglePasswordVisiblity={togglePasswordVisiblity}
                    setValidationErrors={setValidationErrors}
                    setfields={setfields}
                    backButtonStatus={backButtonStatus}
                  />
                </TabPanel>
                <TabPanel>
                  <Permissions
                    dispatch={dispatch}
                    selectedOption={selectedOption}
                    setTabIndex={setTabIndex}
                    subadminId={subadminId ? subadminId : editkey}
                    fields={fields}
                    setfields={setfields}
                    setbackButtonStatus={setbackButtonStatus}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    subadminId: state.persistStore.subadminId,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
