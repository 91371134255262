import { postData, postData_, getData, getData__ } from "helpers/Helper";
import {
  APP_PATH,
  LOGIN,
  LOGOUT,
  PROFILE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD_FORM,
  RESET_PASSWORD,
} from "./apiPath";
import {
  authLogin,
  authLogout,
  profile,
  updateProfile,
  updatePassword,
  forgotPassword,
  resetPasswordForm,
  resetPassword,
} from "store/actions/auth";
import { persist_store } from "store/actions/index";

export function login(credentials, msz) {
  return async (dispatch) => {
    try {
      const data = await postData(`${APP_PATH}${LOGIN}`, credentials, msz);
      dispatch(authLogin(data));
      await dispatch(profile_(credentials));

      console.log("data", data);

      return data;
    } catch (error) {
      console.error("Error while login:", error);
      throw error;
    }
  };
}
//logout
export function logout(credentials) {
  return (dispatch) =>
    postData_(`${APP_PATH}${LOGOUT}`, credentials).then((data) => {
      dispatch(authLogout(data));
      return data;
    });
}

export function profile_(credentials) {
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return getData(`${APP_PATH}${PROFILE}`, credentials)
      .then((data) => {
        dispatch(profile(data));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch((error) => {
        dispatch(persist_store({ loader: false }));
        console.error("Error fetching data:", error);
      });
  };
}

export function updateProfile_(credentials) {
  return async (dispatch) => {
    try {
      const data = await postData(`${APP_PATH}${UPDATE_PROFILE}`, credentials);
      dispatch(updateProfile(data));
      // Call profile_ to update the user profile after successful update
      await dispatch(profile_(credentials));
      return data;
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };
}

export function updatePassword_(credentials) {
  return (dispatch) =>
    postData(`${APP_PATH}${CHANGE_PASSWORD}`, credentials).then((data) => {
      dispatch(updatePassword(data));
      return data;
    });
}

//forgot passord
export function forgotPassword_(value) {
  return (dispatch) =>
    postData(`${APP_PATH}${FORGOT_PASSWORD}`, value).then((data) => {
      dispatch(forgotPassword(data));
      return data;
    });
}

//forgot passord
export function resetPasswordForm_(value) {
  return (dispatch) =>
    getData__(`${APP_PATH}${RESET_PASSWORD_FORM}${value}`, value).then(
      (data) => {
        // dispatch(resetPasswordForm(data));
        return data;
      }
    );
}

//forgot passord
export function resetPassword_(value) {
  return (dispatch) =>
    postData(`${APP_PATH}${RESET_PASSWORD}`, value).then((data) => {
      dispatch(resetPassword(data));
      return data;
    });
}
