import { GET_DASHBOARD } from 'store/actions/dashboard';

const initialState = {
  isAuthenticated: false,
  loader:false,
  dashboardProfile:{}
};

const getDashboard = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      dashboardProfile:payload?.data,
    };
    return stateObj;
  };

const Dashboard = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_DASHBOARD:
          return getDashboard(state, payload); 
        default:
        return state;
    };
}
export default Dashboard;
