import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//countUp
import CountUp from "react-countup";
import ReactPagination from "components/shared/ReactPagination";

//imported
import { DASHBOARD, PAGE_LIMIT, itemsPerPage } from "helpers/viewContent";
import { useModules } from "helpers/modules";
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import GraphPage from "./GraphPage";
import GraphPageNewClient from "./GraphPageNewClient";
import GraphPageDeposit from "./GraphPageDeposit";
import TableDataClient from "./TableDataClient";
import TableDataPendentClient from "./TableDataPendentClient";
//services
import { getDashboard_ } from "store/services/dashboardService";
import { getList_ } from "store/services/commonService";

const Dashboard = ({ dispatch, user, userProfile }) => {
  let userRole = user?.user ? user?.user : userProfile;
  // console.log("🚀 ~ Dashboard ~ userRole:", userRole);
  const [modules, setModules] = useState(useModules);
  const [dbmodules, setDbModules] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [selectedInterval1, setSelectedInterval1] = useState("monthly");
  const [selectedInterval2, setSelectedInterval2] = useState("monthly");

  const [dashboardList, SetDashboardList] = useState({});
  const [dashboardDBList, setdashboardDBList] = useState({});
  const [transactionData, settransactionData] = useState({ response: [] });
  const [earningData, setearningData] = useState({ response: [] });
  const [userData, setuserData] = useState({ response: [] });

  async function getDashboardList_() {
    try {
      let query = {
        filter: "monthly",
        type: "",
      };
      let res = await dispatch(getDashboard_(query));
      if (res) {
        setdashboardDBList(res?.data?.data);
        settransactionData({
          response: res?.data?.data?.transactionData?.response,
        });
        setearningData({ response: res?.data?.data?.earningData?.response });
        setuserData({ response: res?.data?.data?.userData?.response });
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getDashboardList_();
  }, []);

  useEffect(() => {
    let userPermission =
      userProfile && userProfile?.permissions?.length > 0
        ? JSON.parse(userProfile?.permissions)
        : {};
    let userData =
      user?.user?.permissions && user?.user?.permissions?.length > 0
        ? JSON.parse(user?.user?.permissions)
        : userPermission;
    setUserPermissions(userData);
  }, [userRole]);

  const hasPermission = (moduleName, action) => {
    return userPermissions[moduleName]?.[action] || false;
  };

  const [userListing, setUserListing] = useState([]);
  const [userPeningListing, setUserPeningListing] = useState({});

  //get userInfo
  const [filter, setFilter] = useState({
    page: 1,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: -1,
    orderBy: "createdAt",
  });
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getUsersWithFilter",
        common: false,
      };
      let res = await dispatch(getList_(query));
      setUserListing(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  //get userInfo pending
  const [filter2, setFilter2] = useState({
    page: 1,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: -1,
    orderBy: "createdAt",
  });
  const fetchPeningUserData = async () => {
    try {
      let query = {
        ...filter2,
        url: "getUsersWithFilter",
        common: false,
      };
      let res = await dispatch(getList_(query));
      let userList = res?.data?.data;
      let pendingArr = [];
      for (let list of userList) {
        if (list?.status === "pending") {
          pendingArr.push(list);
        }
      }
      setUserPeningListing(pendingArr);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
    fetchPeningUserData();
  }, []);

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };
  const handlePageChange = (pageNumber) => setActivePage(pageNumber);

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemPostion =
    dashboardDBList?.lastOpenPosition?.length > 0 &&
    dashboardDBList?.lastOpenPosition?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Helmet title={DASHBOARD.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">Dashboard</h3>
      </div>
      {(userRole?.isUserDashboard ||
        !userRole?.isPaymentDashboard ||
        !userRole?.isTradeDashboard) &&
        !userPermissions && (
          <div className="col-lg-12 not-fount-permission" val="i">
            <h3 className="text-arr-permission">
              Currently you don't have a access of any module's
            </h3>
          </div>
        )}
      <div className="row dashboard_first_row">
        <div className="col-lg-7 column_eight">
          <div className="py-2">
            <div className="" style={{ borderRadius: 20 }}>
              <div className="row mx-3">
                {modules?.length > 0 &&
                  modules.map((mod, i) => {
                    if (dashboardDBList.hasOwnProperty(mod.model_name)) {
                      const value = dashboardDBList[mod.model_name];
                      mod.count = value;
                    }
                    if (
                      !userRole?.isUserDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "userCount"
                    ) {
                      mod.is_dashboard = false;
                    }
                    if (
                      !userRole?.isPaymentDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "transactionCount"
                    ) {
                      mod.is_dashboard = false;
                    }
                    if (
                      !userRole?.isTradeDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "tradeCount"
                    ) {
                      mod.is_dashboard = false;
                    }
                    if (
                      userRole?.isUserDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "userCount"
                    ) {
                      mod.is_dashboard = true;
                    }
                    if (
                      userRole?.isPaymentDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "transactionCount"
                    ) {
                      mod.is_dashboard = true;
                    }
                    if (
                      userRole?.isTradeDashboard &&
                      userRole?.role === "SUBADMIN" &&
                      mod.model_name === "tradeCount"
                    ) {
                      mod.is_dashboard = true;
                    }
                    let verifyUrl = false;

                    for (const permissionKey in userPermissions) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          userPermissions,
                          permissionKey
                        )
                      ) {
                        const permission = userPermissions[permissionKey];
                        // Check if the permission key matches the model name and if it has the "view" property set to true
                        if (
                          permissionKey.toLowerCase() ===
                            mod.model_name.toLowerCase() &&
                          permission.hasOwnProperty("view") &&
                          permission.view === true
                        ) {
                          verifyUrl = true;
                          break; // Exit the loop once found
                        }
                      }
                    }
                    const shouldShowModule =
                      hasPermission(mod.model_name, "view") ||
                      userRole?.role === "SUBADMIN";
                    if (
                      userPermissions &&
                      shouldShowModule &&
                      mod.is_dashboard
                    ) {
                      return (
                        <div className="col-lg-4 px-2 my-2" val="i">
                          <div
                            className={`card ${mod.dashboard_color} h-100 custom_radius border card-img-holder border-dark px-3 text-white  dashboard_tags_back-color`}
                          >
                            <Link
                              className="dashboard_tags"
                              to={verifyUrl ? mod.url : "#"}
                            >
                              <div className="card-body py-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="left">
                                    <h6 className="font-weight-normal">
                                      {mod.name}
                                    </h6>
                                    <h2 className="">
                                      <CountUp
                                        start={0}
                                        end={mod.count ? mod?.count : 0}
                                        duration={3}
                                      />
                                    </h2>
                                  </div>
                                  <div className="imgWrpp">
                                    <img
                                      src={mod.dashboard_img}
                                      className="img-sixe img-fluid"
                                      alt="circle-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    } else if (
                      mod.is_dashboard &&
                      mod.is_permission &&
                      userRole?.role === "ADMIN"
                    ) {
                      return (
                        <div className="col-lg-4 px-2 my-2" val="i">
                          <div
                            className={`card ${mod.dashboard_color} h-100 custom_radius border card-img-holder border-dark px-3 text-white  dashboard_tags_back-color`}
                          >
                            <Link className="dashboard_tags" to={mod.url}>
                              <div className="card-body py-2">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="left">
                                    <h6 className="font-weight-normal">
                                      {mod.name}
                                    </h6>
                                    <h2 className="">
                                      <CountUp
                                        start={0}
                                        end={mod.count ? mod?.count : 0}
                                        duration={3}
                                      />
                                    </h2>
                                  </div>
                                  <div className="imgWrpp">
                                    <img
                                      src={mod.dashboard_img}
                                      className="img-sixe img-fluid"
                                      alt="circle-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          {userRole?.isGraphPermission && userRole?.role === "SUBADMIN" && (
            <>
              <div className="py-2">
                <GraphPage
                  dashboardList={dashboardList}
                  dispatch={dispatch}
                  selectedInterval={selectedInterval}
                  setSelectedInterval={setSelectedInterval}
                  earningData={earningData}
                  setearningData={setearningData}
                />
              </div>
            </>
          )}
          {userRole?.role === "ADMIN" && (
            <>
              <div className="py-2">
                <GraphPage
                  dashboardList={dashboardList}
                  dispatch={dispatch}
                  selectedInterval={selectedInterval}
                  setSelectedInterval={setSelectedInterval}
                  earningData={earningData}
                  setearningData={setearningData}
                />
              </div>
            </>
          )}
        </div>

        {userRole?.isLastOpen && userRole?.role === "SUBADMIN" && (
          <div className="col-lg-5 column_four">
            <div className="card card_sidebar">
              <div className="card-body p-3">
                <h4 className="card-title sidebar_title">
                  last Open Positions
                </h4>
                <div class="lastOpen table-responsive">
                  <table className="table table_side_bar">
                    <thead>
                      <tr>
                        <th> Name </th>
                        <th> Symbol </th>
                        <th> Price </th>
                        <th> PLPC </th>
                        <th> Qty </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItemPostion?.length > 0 &&
                        currentItemPostion?.map((position, i) => {
                          return (
                            <tr>
                              <td className="d-flex align-items-center">
                                <div className="symbol_img me-2">
                                  <img
                                    src={
                                      position?.imgUrl
                                        ? position?.imgUrl
                                        : SIDEBAR_LOGIN_LOGO
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                {position?.shortName
                                  ? position?.shortName
                                  : position?.name}
                              </td>
                              <td
                                onClick={() =>
                                  renderTradesGraph(position?.symbol)
                                }
                                className="cursor-pointer"
                                title="View"
                              >
                                <b>{position?.symbol}</b>
                              </td>
                              <td>
                                $
                                {position.price != null &&
                                !isNaN(position.price)
                                  ? parseInt(position.price).toFixed(2)
                                  : "0"}
                              </td>
                              <td>
                                {" "}
                                <label className="badge badge-success">
                                  {position.unrealized_plpc != null &&
                                  !isNaN(position.unrealized_plpc)
                                    ? parseInt(
                                        position.unrealized_plpc
                                      ).toFixed(2)
                                    : "0"}
                                </label>
                              </td>
                              <td>{position?.qty ? position?.qty : "N/A"}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {dashboardDBList?.lastOpenPosition?.length > 0 && (
                  <div className="pagination-box-review">
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={
                        dashboardDBList?.lastOpenPosition?.length
                      }
                      handlePageChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {userRole?.role === "ADMIN" && (
          <div className="col-lg-5 column_four">
            <div className="card card_sidebar">
              <div className="card-body p-3">
                <h4 className="card-title sidebar_title">
                  last Open Positions
                </h4>
                <div class="lastOpen table-responsive">
                  <table className="table table_side_bar">
                    <thead>
                      <tr>
                        <th> Name </th>
                        <th> Symbol </th>
                        <th> Price </th>
                        <th> PLPC </th>
                        <th> Qty </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItemPostion?.length > 0 &&
                        currentItemPostion?.map((position, i) => {
                          return (
                            <tr>
                              <td className="d-flex align-items-center">
                                <div className="symbol_img me-2">
                                  <img
                                    src={
                                      position?.imgUrl
                                        ? position?.imgUrl
                                        : SIDEBAR_LOGIN_LOGO
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                {position?.shortName
                                  ? position?.shortName
                                  : position?.name}
                              </td>
                              <td
                                onClick={() =>
                                  renderTradesGraph(position?.symbol)
                                }
                                className="cursor-pointer"
                                title="View"
                              >
                                <b>{position?.symbol}</b>
                              </td>
                              <td>
                                $
                                {position.price != null &&
                                !isNaN(position.price)
                                  ? parseInt(position.price).toFixed(2)
                                  : "0"}
                              </td>
                              <td>
                                {" "}
                                <label className="badge badge-success">
                                  {position.unrealized_plpc != null &&
                                  !isNaN(position.unrealized_plpc)
                                    ? parseInt(
                                        position.unrealized_plpc
                                      ).toFixed(2)
                                    : "0"}
                                </label>
                              </td>
                              <td>{position?.qty ? position?.qty : "N/A"}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {dashboardDBList?.lastOpenPosition?.length > 0 && (
                  <div className="pagination-box-review">
                    <ReactPagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={
                        dashboardDBList?.lastOpenPosition?.length
                      }
                      handlePageChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row d-lg-flex d-none">
        {userRole?.isGraphPermission && userRole?.role === "SUBADMIN" && (
          <>
            <div className="py-2 col-lg-6 p-0">
              <GraphPageNewClient
                dashboardList={dashboardList}
                dispatch={dispatch}
                selectedInterval1={selectedInterval1}
                setSelectedInterval1={setSelectedInterval1}
                userData={userData}
                setuserData={setuserData}
              />
            </div>
            <div className="py-2 col-lg-6 p-0">
              <GraphPageDeposit
                dashboardList={dashboardList}
                dispatch={dispatch}
                selectedInterval2={selectedInterval2}
                setSelectedInterval2={setSelectedInterval2}
                transactionData={transactionData}
                settransactionData={settransactionData}
              />
            </div>
          </>
        )}
        {userRole?.role === "ADMIN" && (
          <>
            <div className="py-2 col-lg-6 p-0">
              <GraphPageNewClient
                dashboardList={dashboardList}
                dispatch={dispatch}
                selectedInterval1={selectedInterval1}
                setSelectedInterval1={setSelectedInterval1}
                userData={userData}
                setuserData={setuserData}
              />
            </div>
            <div className="py-2 col-lg-6 p-0">
              <GraphPageDeposit
                dashboardList={dashboardList}
                dispatch={dispatch}
                selectedInterval2={selectedInterval2}
                setSelectedInterval2={setSelectedInterval2}
                transactionData={transactionData}
                settransactionData={settransactionData}
              />
            </div>
          </>
        )}
      </div>

      {userRole?.isNewlyRegistered && userRole?.role === "SUBADMIN" && (
        <div className="row">
          <TableDataClient
            userListing={userListing}
            setFilter={setFilter}
            filter={filter}
            userPermissions={userPermissions}
            userRole={userRole}
          />
        </div>
      )}
      {userRole?.role === "ADMIN" && (
        <div className="row">
          <TableDataClient
            userListing={userListing}
            setFilter={setFilter}
            filter={filter}
            userRole={userRole}
          />
        </div>
      )}
      <br />
      {userRole?.isPendentClient && userRole?.role === "SUBADMIN" && (
        <TableDataPendentClient
          userPeningListing={userPeningListing}
          setFilter={setFilter2}
          filter={filter2}
          userPermissions={userPermissions}
          userRole={userRole}
        />
      )}
      {userRole?.role === "ADMIN" && (
        <div className="row">
          <TableDataPendentClient
            userPeningListing={userPeningListing}
            setFilter={setFilter2}
            filter={filter2}
            userRole={userRole}
          />
        </div>
      )}
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    user: state.Auth,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Dashboard);
