import React from "react";
import { DATE_FORMAT_TIME_ } from "helpers/common";
import { itemsPerPage } from "helpers/viewContent";
import { useHistory } from "react-router-dom";
import * as Path from "routes/paths";
import { IMG_LOGO_1, SIDEBAR_LOGIN_LOGO } from "utils/constant";
import ReactPagination from "components/shared/ReactPagination";

export default ({
  userListing,
  setFilter,
  filter,
  userPermissions,
  userRole,
}) => {
  const history = useHistory();
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const indexOfLastItem = filter?.page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    userListing?.length > 0 &&
    userListing?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="col-md-12">
        <div className="card graph_card">
          <div className="card-body">
            <h4 className="card-title sidebar_title">
              Newly Registered Clients
            </h4>
            <div class="table-responsive">
              <table className="table common_tabel_th">
                <thead>
                  <tr>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Mobile NUmber </th>
                    <th> Created At </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length > 0 &&
                    currentItems.map((list, i) => {
                      return (
                        <tr
                          title="view"
                          className="cursor-pointer"
                          onClick={() =>
                            userRole?.role === "SUBADMIN" &&
                            userPermissions.hasOwnProperty("userCount") &&
                            userPermissions?.userCount.hasOwnProperty("view") &&
                            userPermissions?.userCount?.view
                              ? history.push(`${Path.userview}/${list._id}`)
                              : userRole?.role === "ADMIN"
                              ? history.push(`${Path.userview}/${list._id}`)
                              : "#"
                          }
                        >
                          <td className="d-flex align-items-center">
                            <div className="symbol_img me-2">
                              <img
                                src={
                                  list?.profileImage
                                    ? list?.profileImage
                                    : SIDEBAR_LOGIN_LOGO
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <b>
                              {list.first_name
                                ? `${list?.first_name} ${list?.last_name}`
                                : "N/A"}
                            </b>
                          </td>
                          <td>{list.email ? list?.email : "N/A"}</td>
                          <td>
                            {list.mobileNumber
                              ? `${list?.countryCode} ${list?.mobileNumber}`
                              : "N/A"}
                          </td>
                          <td>{DATE_FORMAT_TIME_(list?.createdAt)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {userListing?.length > 0 && (
              <div className="pagination-box-review">
                <ReactPagination
                  activePage={filter?.page}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={userListing?.length}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
