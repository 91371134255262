import { getData, postData, postData_, putData } from "helpers/Helper";
import { COMMON_PATH, GET_BASICSETTING, UPDATE_BASICSETTING } from "./apiPath";
import {
  getBasicSetting,
  updateBasicSetting,
} from "store/actions/basicSetting";
import { persist_store } from "store/actions/index";

export function getBasicSetting_(value, callback) {
  const { page, limit, url, common } = value || {};
  let urlPath = `${COMMON_PATH}${GET_BASICSETTING}`;
  return async (dispatch) => {
    dispatch(persist_store({ loader: true }));
    try {
      const data = await getData(urlPath, value);
      dispatch(getBasicSetting(data, callback));
      dispatch(persist_store({ loader: false }));
      return data;
    } catch (error) {
      dispatch(persist_store({ loader: false }));
      console.error("Error fetching data:", error);
    }
  };
}

export function updateBasicSetting_(value, callback) {
  return (dispatch) =>
    postData(`${COMMON_PATH}${UPDATE_BASICSETTING}`, value).then((data) => {
      dispatch(updateBasicSetting(data, callback));
      return data;
    });
}
