import React, { useState, useEffect, useCallback} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

// imported
import { RESET_PASSWORD } from 'helpers/viewContent';
import {MAIN_LOGO} from 'utils/constant'
import * as Yup from 'yup';
//services
import {resetPassword_} from 'store/services/authService';

//validations
const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required").min(6, "Password must be at most 6 characters long"),
    confirmPassword: Yup.string()
  .required("Please confirm your password")
  .oneOf([Yup.ref('password'), null], 'Passwords must match'), 
});

const validateData = async (data) => {
    try {
        await validationSchema.validate(data, { abortEarly: false });
        return { isValid: true, errors: {} };
    } catch (error) {
        const errors = {};
        error.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

const ResetPassword = ({dispatch, userProfile, match}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fields, setfields] = useState({
        password:"",
        confirmPassword: "",
        token:""
    });

    const handleChange = useCallback(
        name => evt => {
          setfields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
          setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )

    useEffect(() => {
        console.log('useEffect is running...');
        // rest of your code
    }, []);
    
    //submit setting data
    const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
          setSending(true)
          let query = {
            ...fields,
            token : match ? match.params.token : '',
          }
            await dispatch(resetPassword_(query));
            setSending(false)
            setfields({
                password:"",
                confirmPassword:""
            })
        } else {
            setValidationErrors(validation.errors);
        }
        } catch (err) {
            console.log(err);
            setSending(false)
        }
    }
    
    return(
        <>
        <Helmet title={RESET_PASSWORD.CURRENT_MODULE} />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth login-background-color">
                <div className="row flex-grow">
                    {!success &&
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left p-5">
                            <div className="brand-logo">
                            <img src={MAIN_LOGO} className="loginLogo"/>
                            </div>
                            <h4>{RESET_PASSWORD.CURRENT_MODULE}</h4>
                            <h6 className="font-weight-light">{RESET_PASSWORD.MAIN_CONTENT}</h6>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label for="exampleInputName1">New Password*</label>
                                    <input type="password" className="form-control" id="exampleInputName1" placeholder="******" name="password" onChange={handleChange("password")}
                                    value={fields.password}
                                    />
                                    {validationErrors.password && (
                                        <div className="text-danger">{validationErrors.password}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputName1">Confirm Password*</label>
                                    <input type="password" className="form-control" id="exampleInputName1" placeholder="******" name="confirmPassword" onChange={handleChange("confirmPassword")}
                                    value={fields.confirmPassword}
                                    />
                                    {validationErrors.confirmPassword && (
                                        <div className="text-danger">{validationErrors.confirmPassword}</div>
                                    )}
                                </div>
                               
                            <button type="submit" disabled={sending ? true : false} className="btn btn-gradient-primary me-2">{sending ?  'Loading...' : RESET_PASSWORD.BUTTON}</button>
                            </form>
                        </div>
                    </div>
                    }
                    {success &&
                    <div className="col-lg-4 mx-auto">
                        <div className="auth-form-light text-left p-5">
                            <h4>Link Expired</h4>
                        </div>
                    </div> 
                    } 
                </div>
            </div>
        </div>
        </>

    )
}
const mapStateToPros = (state) => {
    return{
        userProfile: state.Auth.user
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ResetPassword);