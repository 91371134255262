import React, { useState, useEffect, useCallback } from "react";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as Yup from 'yup';
import { addData_, updateData_ } from 'store/services/commonService';
import {DATE_FORMAT_FOR_DB} from 'helpers/common'

export default ({openModalValue, openModalClose, clientListing, staffListing, setOpenModalValue,dispatch, currentState, setCurrentState, setIsLoadApi, setisDeleted}) => {
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        endDate: Yup.string().required('Required'),
        icon: Yup.string().required('Required'),
        assignId: Yup.string().required('Required'),
        subjectId: Yup.string().required('Required'),
        comment: Yup.string().required('Required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };
    const [isLoad, setIsLoad] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [fields, setfields] = useState({
        title: "",
        endDate: "",
        icon:"",
        assignId:"",
        subjectId:"",
        comment:"",
    })

    const handleChange = useCallback(
        name => evt => {
          setfields(prevState => ({
            ...prevState,
            [name]: evt.target.value,
          }))
          setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    //submit setting data
    const handleSubmit = async (evt) => {
        try{
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                let query = {}
                let date_ = await DATE_FORMAT_FOR_DB(fields.endDate)
                if(currentState){
                    query = {
                        ...fields,
                        endDate : date_,
                        url :"task/update",
                        id : currentState?._id
                    }
                    setIsLoad(true)
                    let result =  await dispatch(updateData_(query));
                    if(result.data?.status === 'success'){
                        setCurrentState(null)
                        setOpenModalValue(false)
                        setIsLoadApi(true)
                        setisDeleted(true)
                    }
                    setIsLoad(false)
                }else{
                    query ={
                        ...fields,
                        endDate : date_,
                        url :"task/add"
                    }
                    setIsLoad(true)
                    let res =  await dispatch(addData_(query));
                    if(res?.data?.status === 'success'){
                        setOpenModalValue(false)
                        setIsLoadApi(true)
                        setisDeleted(true)
                    }
                    setIsLoad(false)
                }
            } else {
                setValidationErrors(validation.errors);
                setIsLoad(false)
                setIsLoadApi(false)
            }
        } catch (err) {
            setIsLoad(false)
            setIsLoadApi(false)
            console.log(err);
        }
    }
    const isValidDate = async (current) => {
        const currentDate = new Date();
        return current.isSameOrAfter(currentDate, "day");
    };

    const handleChangePicker = async (selectedDate, name) => {
        let dateFormat = selectedDate ? selectedDate.format("YYYY-MM-DD hh:mm A") : "";
            // Update the state with the selected date
            setfields(prev => ({
                ...prev,
                [name]: dateFormat,
            }));
            // Clear any existing validation errors
            setValidationErrors(prev => ({
                ...prev,
                [name]: "",
            }));
        
    };
    useEffect(() => {
        setfields({
            title:currentState?.title,
            endDate:moment(currentState?.endDate).format("YYYY-MM-DD hh:mm A"),
            icon:currentState?.icon,
            assignId:currentState?.assignId,
            subjectId:currentState?.subjectId,
            comment:currentState?.comment,
        })
    }, [currentState])
    return(
        <>
            <Modal
                show={openModalValue}
                onHide={openModalClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <hr/>
                <Modal.Body>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label for="exampleInputName1">Title*</label>
                        <input type="text" className="form-control" id="exampleInputName1" placeholder="Title" name="title" onChange={handleChange("title")} value={fields.title} />
                        {validationErrors.title && (
                            <div className="text-danger err">{validationErrors.title}</div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Expire Time*</label>
                        <Datetime
                            value={fields.endDate || ""}
                            inputProps={{
                                placeholder: "YYYY-MM-DD hh:mm A",
                                readOnly: true,
                                className:"form-control class-time-date-time"
                            }}
                            name="endDate"
                            dateFormat="YYYY-MM-DD"
                            timeFormat="hh:mm A"
                            isValidDate={isValidDate}
                            onChange={(selectedDate) =>
                                handleChangePicker(selectedDate, "endDate")
                            }
                            closeOnSelect={true}                  
                        />
                        {validationErrors.endDate && (
                            <div className="text-danger err">{validationErrors.endDate}</div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Icon*</label>
                        <select className="form-select filler_search" name="icon" onChange={handleChange("icon")}> 
                            <option value="" selected disabled>Select </option>
                            <option value="call" selected={currentState?.icon === 'call'}>Call </option>
                            <option value="mail" selected={currentState?.icon === 'mail'}>Mail </option>
                        </select>
                        {validationErrors.icon && (
                            <div className="text-danger err">{validationErrors.icon}</div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Assigned to (staff)*</label>
                        <select className="form-select filler_search" name="assignId" onChange={handleChange("assignId")} >
                            <option value="" selected disabled>Select </option>
                            {staffListing?.length > 0 && staffListing.map((staff, i) => {
                                return(
                                    <>
                                      <option value={staff._id} key={i} selected={currentState?.assignId===staff?._id}>{staff?.name}</option>
                                    </>
                                )
                            })}
                        </select>
                        {validationErrors.assignId && (
                            <div className="text-danger err">{validationErrors.assignId}</div>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="exampleInputName1">Subject (client name)*</label>
                        <select className="form-select filler_search" name="subjectId" onChange={handleChange("subjectId")}>
                            <option value="" selected disabled>Select </option>
                            {clientListing?.length > 0 && clientListing.map((client, i) => {
                                return(
                                    <>
                                      <option value={client._id} key={i}  selected={currentState?.subjectId===client?._id}>{client?.email}{client?.name!==null ? `(${client?.name})` : ''} </option>
                                    </>
                                )
                            })}
                            <option value="mail">Mail </option>
                        </select>
                        {validationErrors.subjectId && (
                            <div className="text-danger err">{validationErrors.subjectId}</div>
                        )}
                    </div>
                    <div className="form-group col-md-12">
                        <label for="exampleInputName1">Task Text*</label>
                        <textarea type="text" name="comment" className="form-control" id="exampleInputName1" placeholder="Enter here" onChange={handleChange("comment")} value={fields.comment || ''}/>
                        {validationErrors.comment && (
                            <div className="text-danger err">{validationErrors.comment}</div>
                        )}
                    </div>
                </div>
                <hr />
                </Modal.Body>
                <Modal.Footer>
                <button type="submit"  disabled={isLoad ? true : false} onClick={(e) => handleSubmit(e)} className="btn btn-lg btn-gradient-primary" title="Create" >{currentState ? 'Update' : 'Create'} Task</button>
                <button type="button" className="btn btn-lg btn-outline-secondary" title="Cancel" onClick={openModalClose}>Cancel </button>
                </Modal.Footer>
            </Modal>
        </>

    );
};