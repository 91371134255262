import React from "react";
import Modal from 'react-bootstrap/Modal';
import { DATE_FORMAT, FORMAT_TEXT } from "helpers/common";

export default ({ openModalHistoryValue, openModalHistoryClose, listing, currentTabValue, currentTabClass }) => {
    return (
        <>
            <Modal
                show={openModalHistoryValue}
                onHide={openModalHistoryClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="card-title"> <i class={`mdi mdi-checkbox-blank-circle ${currentTabClass === 'overdue' ? 'primary' : currentTabClass === 'today' ? 'success' : 'info'}`}></i> {currentTabClass === 'overdue' ? 'Overdue' : currentTabClass === 'Today' ? 'success' : 'Tomorrow'} Task</h4>
                    </Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    {currentTabValue?.length > 0 && currentTabValue.slice(0, 5).map((tab, i) => {
                                        return (
                                            <>
                                                <div className={`media-body task ${currentTabClass === 'overdue' ? 'primary' : currentTabClass === 'today' ? 'success' : 'info'}`} key={i}>
                                                    <p className="card-text">{DATE_FORMAT(tab.endDate)} {tab?.subjectDetails?.name !== null ? `(${tab?.subjectDetails?.name})` : FORMAT_TEXT(tab?.subjectDetails?.email)}</p>
                                                    {tab.title}
                                                </div>
                                                <br />
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};