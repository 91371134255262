import {
    GET_FEATURE,
    ADD_FEATURE,
    UPDATE_FEATURE,
    VIEW_FEATURE,
    DELETE_FEATURE,
  } from "store/actions/feature";
  
  const initialState = {
    isAuthenticated: false,
    loader: false,
    featureList: [],
    featureDetail: {},
    totalItemsCount: 0,
  };
  
  const getFeature = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      featureList: data?.data?.data,
      totalItemsCount: data?.data?.totalcount,
    };
    return stateObj;
  };
  
  const addFeature = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const updateFeature = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const viewFeature = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      featureDetail: payload,
    };
    return stateObj;
  };
  
  const deleteFeature = (state, payload) => {
    const { callback } = payload;
    if (typeof callback === "function") {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
  };
  
  const Feature = (state = initialState, { type, payload = null }) => {
    switch (type) {
      case GET_FEATURE:
        return getFeature(state, payload);
      case ADD_FEATURE:
        return addFeature(state, payload);
      case UPDATE_FEATURE:
        return updateFeature(state, payload);
      case VIEW_FEATURE:
        return viewFeature(state, payload);
      case DELETE_FEATURE:
        return deleteFeature(state, payload);
      default:
        return state;
    }
  };
  export default Feature;
  