import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default ({ show, handleClose, currenTransaction, tabIndex }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Link to="#" className="close_btn" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
              />
            </svg>
          </Link>
          <h2 className="text-center my-2">Payment</h2>
          {tabIndex === 1 && (
            <div className="axix_modal_content my-3">
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction ID</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.userDetails?.accountId
                        ? currenTransaction?.userDetails?.accountId
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Customer</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.userDetails?.first_name
                        ? `${currenTransaction?.userDetails?.first_name} ${currenTransaction?.userDetails?.last_name}`
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction Amount</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currenTransaction?.amount
                        ? currenTransaction?.amount.toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>TransactionT Type</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.transactionType}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Status</strong>
                    </label>
                  </li>
                  <li>
                    <label className="badge badge-success payment_status">
                      {currenTransaction?.status}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Description</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.text}
                    </label>
                  </li>
                </ul>
              </div>
              <h2 className="text-center my-2">Bank Details</h2>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Account Holder Name</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.bankDetails?.accountHolderName}
                    </label>
                  </li>
                </ul>
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Nickname</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.bankDetails?.nickname}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Account Number</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.bankDetails?.accountNumber}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Routing Number</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.bankDetails?.routingNumber}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {tabIndex === 0 && (
            <div className="axix_modal_content my-3">
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction ID</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.transactionId
                        ? currenTransaction?.transactionId
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Customer</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.userDetails?.first_name
                        ? `${currenTransaction?.userDetails?.first_name} ${currenTransaction?.userDetails?.last_name}`
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction Amount</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currenTransaction?.amount != null &&
                      !isNaN(currenTransaction?.amount)
                        ? parseInt(currenTransaction?.amount).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Payment Method</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.transactionType}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Status</strong>
                    </label>
                  </li>
                  <li>
                    <label className="badge badge-success payment_status">
                      {" "}
                      {currenTransaction?.status}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {tabIndex === 2 && (
            <div className="axix_modal_content my-3">
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction ID</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.transactionId
                        ? currenTransaction?.transactionId
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>
              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Customer</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.userDetails?.first_name
                        ? `${currenTransaction?.userDetails?.first_name} ${currenTransaction?.userDetails?.last_name}`
                        : "N/A"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Transaction Amount</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      $
                      {currenTransaction?.amount != null &&
                      !isNaN(currenTransaction?.amount)
                        ? parseInt(currenTransaction?.amount).toFixed(2)
                        : "0"}
                    </label>
                  </li>
                </ul>
              </div>

              <div className="line_up d-flex align-items-center gap-3 justify-content-center">
                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Payment Method</strong>
                    </label>
                  </li>
                  <li>
                    <label className="col-form-label">
                      {currenTransaction?.transactionType}
                    </label>
                  </li>
                </ul>

                <ul className="d-flex align-items-center gap-3 justify-content-center">
                  <li>
                    <label className="col-form-label">
                      <strong>Status</strong>
                    </label>
                  </li>
                  <li>
                    <label className="badge badge-success payment_status">
                      {" "}
                      {currenTransaction?.status}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
