import {
    getData,
    postData,
    postData_,
    deleteDataMethod,
    putData,
  } from "helpers/Helper";
  import {
    APP_PATH,
    GET_TESTIMONY,
    ADD_TESTIMONY,
    UPDATE_TESTIMONY,
    VIEW_TESTIMONY,
    DELETE_TESTIMONY,
  } from "./apiPath";
  import {
    getTestimony,
    addTestimony,
    updateTestimony,
    viewTestimony,
    deleteTestimony,
  } from "store/actions/testimony";
  import { persist_store } from "store/actions/index";
  
  export function getTestimony_(value, callback) {
    const { page, limit, url, common } = value || {};
    let urlPath = `${APP_PATH}${GET_TESTIMONY}`;
    //   if (page !== undefined && limit !== undefined) {
    //     urlPath += `?page=${page}&limit=${limit}`;
    //   }
    return async (dispatch) => {
      // Set loader state to true before making the API call
      dispatch(persist_store({ loader: true }));
      // Make the API call
      try {
        const data = await postData_(urlPath, value);
  
        // Dispatch the getList action with the data received from the API
        dispatch(getTestimony(data, callback));
        // Set loader state to false after the API call is complete
        dispatch(persist_store({ loader: false }));
        return data;
      } catch (error) {
        // In case of an error, also set loader state to false
        dispatch(persist_store({ loader: false }));
        // You might want to handle the error here or propagate it further
        console.error("Error fetching data:", error);
      }
    };
  }
  
  //add
  export function addTestimony_(value, callback) {
    return (dispatch) =>
      postData(`${APP_PATH}${ADD_TESTIMONY}`, value).then((data) => {
        dispatch(addTestimony(data, callback));
        return data;
      });
  }
  
  //view
  export function viewTestimony_(value, callback) {
    let urlPath = `${APP_PATH}${VIEW_TESTIMONY}${value}`;
    return async (dispatch) => {
      dispatch(persist_store({ loader: true }));
      try {
        const data = await getData(urlPath, value);
        dispatch(viewTestimony(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      } catch (error) {
        dispatch(persist_store({ loader: false }));
        console.error("Error fetching data:", error);
      }
    };
  }
  
  export function updateTestimony_(value, callback) {
    return (dispatch) =>
      putData(`${APP_PATH}${UPDATE_TESTIMONY}${value?.testimonyId}`, value).then((data) => {
        dispatch(updateTestimony(data, callback));
        return data;
      });
  }
  
  export function deleteTestimony_(value, callback) {
    let url = `${APP_PATH}${DELETE_TESTIMONY}${value?.testimonyId}`;
    return (dispatch) =>
      deleteDataMethod(url).then((data) => {
        dispatch(deleteTestimony(data, callback));
        return data;
      });
  }
  