import { useHistory } from 'react-router-dom';

const useCustomHistory = () => {
  const history = useHistory();

  const pathname = history.location.pathname.split('/');

  const filtered = pathname.filter(function (el) {
    if (el !== "") {
      return el;
    }
  });

  let path = '/';

  if (filtered.length >= 3) {
    path += filtered[0] + "/" + filtered[1] + "/" + filtered[2];
  } else {
    path += filtered[0] + "/" + (filtered[1] ?? '');
  }

  return path;
};

export { useCustomHistory };
