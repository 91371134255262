import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { debounce } from "lodash";

//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { BACKOFFICE, PAGE_LIMIT } from "helpers/viewContent";
import { DELETE_ALERT } from "helpers/constantsMessage";
import SearchFilter from "components/shared/SearchFilter";
import TableList from "./TableList";
import AddEditModal from "./AddEditModal";
import TaskModalHistory from "./TaskModalHistory";
import { getList_, deleteData_ } from "store/services/commonService";
import TaskModalHistoryList from "./TaskModalHistoryList";

//services
const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isDeleted, setisDeleted] = useState(false);
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    orderBy: "createdAt",
    order: 1,
    type: "",
  });
  const [currentState, setCurrentState] = useState(null);
  const [isLoadApi, setIsLoadApi] = useState(false);

  const [openModalValue, setOpenModalValue] = useState(false);
  const [openModalHistoryValue, setOpenModalHistoryValue] = useState(false);

  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //get client listing
  const [clientListing, setClientListing] = useState([]);
  //get staff listing
  const [staffListing, setStaffListing] = useState([]);

  const [currentTabValue, setcurrentTabValue] = useState([]);
  const [currentTabClass, setcurrentTabClass] = useState(null);

  const openModalAdd = () => {
    setOpenModalValue(true);
  };
  const openModalClose = () => {
    setOpenModalValue(false);
    setCurrentState(null);
  };

  const openModalHistoryAdd = (typeVal, currentTabValue) => {
    setOpenModalHistoryValue(true);
    setcurrentTabValue(currentTabValue);
    setcurrentTabClass(typeVal);
  };
  const openModalHistoryClose = () => {
    setOpenModalHistoryValue(false);
    setcurrentTabValue([]);
    setcurrentTabClass(null);
  };

  const editModalTaks = (list) => {
    setCurrentState(list);
    setOpenModalValue(true);
  };

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list.title.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }

  //get data
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "task/getWithFilter",
        common: false,
      };
      setIsLoadApi(false);
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter, isLoadApi]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      type: "",
    });
  };
  const selectSearchOption = [
    {
      id: "1",
      value: "overdue",
      name: "Overdue",
    },
    {
      id: "2",
      value: "today",
      name: "Today",
    },
    {
      id: "3",
      value: "tomorrow",
      name: "Tomorrow",
    },
  ];

  useEffect(() => {
    let objPer = userData["backoffice"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  //get client listing
  const fetchClientsData = async () => {
    try {
      let query = {
        limit: 1000,
        orderBy: "createdAt",
        url: "getUsersWithFilter",
        search_string: "",
        page: 1,
        common: false,
      };
      let res = await dispatch(getList_(query));
      setClientListing(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  useEffect(() => {
    fetchClientsData();
    fetchAssignedStaffData();
  }, []);

  //get staff listing
  const fetchAssignedStaffData = async () => {
    try {
      let query = {
        limit: 1000,
        orderBy: "createdAt",
        url: "getAllAdminList",
        search_string: "",
        page: 1,
        common: false,
      };
      let res = await dispatch(getList_(query));
      setStaffListing(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  //delete
  const deleteDataFunction_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              url: "task/remove",
              id: id,
              status: "deleted",
              common: false,
            };
            await dispatch(deleteData_(query)).then(() => {
              fetchData();
              setisDeleted(true);
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={BACKOFFICE.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{BACKOFFICE.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.backoffice}>{BACKOFFICE.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <TaskModalHistoryList
        dispatch={dispatch}
        openModalHistoryAdd={openModalHistoryAdd}
        setisDeleted={setisDeleted}
        isDeleted={isDeleted}
      />

      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <SearchFilter
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  clearSerchFilter={clearSerchFilter}
                  options={selectSearchOption}
                  SearchText="Search by subject/assigned"
                  isSearchDate={false}
                  isExportButton={false}
                  isAddButton={false}
                  isSelectBox={true}
                  isInput={true}
                  addLink={Path.backoffice}
                  filter={filter}
                  setFilter={setFilter}
                  isAddModalButton={
                    userRole === "SUBADMIN" &&
                    userSubPermissions !== undefined &&
                    userSubPermissions?.create
                      ? true
                      : userRole === "ADMIN" && userSubPermissions === undefined
                      ? true
                      : false
                  }
                  openModalAdd={openModalAdd}
                  dropdownField="type"
                />
              </h4>
              <TableList
                listing={listToDisplay}
                openModalAdd={openModalAdd}
                userSubPermissions={userSubPermissions}
                userRole={userRole}
                deleteDataFunction_={deleteDataFunction_}
                editModalTaks={editModalTaks}
                activePage={activePage}
                totalItemsCount={listToDisplay?.length}
                handlePageChange={handlePageChange}
                filter={filter}
                handleSortingFunc={handleSortingFunc}
              />
            </div>
          </div>
        </div>
      </div>
      <AddEditModal
        openModalValue={openModalValue}
        openModalClose={openModalClose}
        openModalAdd={openModalAdd}
        clientListing={clientListing}
        staffListing={staffListing}
        setOpenModalValue={setOpenModalValue}
        dispatch={dispatch}
        currentState={currentState}
        setCurrentState={setCurrentState}
        setIsLoadApi={setIsLoadApi}
        setisDeleted={setisDeleted}
      />
      <TaskModalHistory
        openModalHistoryValue={openModalHistoryValue}
        openModalHistoryClose={openModalHistoryClose}
        openModalHistoryAdd={openModalHistoryAdd}
        listing={listToDisplay}
        currentTabValue={currentTabValue}
        currentTabClass={currentTabClass}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
