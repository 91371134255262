//action types
export const GET_JOB = "GET_JOB";
export const ADD_JOB = "ADD_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const VIEW_JOB = "VIEW_JOB";
export const DELETE_JOB = "DELETE_JOB";

export function getJob(data, callback) {
  return {
    type: GET_JOB,
    payload: { data, callback },
  };
}

export function addJob(data, callback) {
  return {
    type: ADD_JOB,
    payload: { data, callback },
  };
}

export function updateJob(data, callback) {
  return {
    type: UPDATE_JOB,
    payload: { data, callback },
  };
}

export function viewJob(data, callback) {
  return {
    type: VIEW_JOB,
    payload: { data, callback },
  };
}

export function deleteJob(data, callback) {
  return {
    type: DELETE_JOB,
    payload: { data, callback },
  };
}
