import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import { PAGE_LIMIT } from "helpers/viewContent";
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { CMS } from "helpers/viewContent";
import { FORMAT_TEXT } from "helpers/common";
import { DELETE_ALERT } from "helpers/constantsMessage";
//services
import { getCms_, deleteCms_ } from "store/services/pagesService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const ContentList = ({ dispatch, userProfile, user }) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });
  async function getData() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getCms_(filter));
      setListing(res?.data?.data);
      setTotalCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //delete
  const deleteData_ = async (id) => {
    try {
      await DELETE_ALERT().then(async (willDelete) => {
        if (willDelete) {
          try {
            let query = {
              contentId: id,
            };
            await dispatch(deleteCms_(query)).then(() => {
              getData();
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    let objPer = userData["cms"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  return (
    <>
      <Helmet title={CMS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{CMS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.cms}>{CMS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="row">
                  <div className="col-md-6">Listing</div>
                  <div className="col-md-4"></div>
                  {userRole === "SUBADMIN" &&
                  userSubPermissions !== undefined &&
                  userSubPermissions?.create ? (
                    <div className="col-md-2">
                      <Link to={Path.cms_form}>
                        <button
                          type="button"
                          class="btn btn-lg btn-gradient-primary"
                        >
                          {" "}
                          + Add
                        </button>
                      </Link>
                    </div>
                  ) : (
                    userRole === "ADMIN" &&
                    userSubPermissions === undefined && (
                      <div className="col-md-2">
                        <Link to={Path.cms_form}>
                          <button
                            type="button"
                            class="btn btn-lg btn-gradient-primary"
                          >
                            {" "}
                            + Add
                          </button>
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </h4>
              <div class="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th onClick={() => handleSortingFunc("name")}>
                        {" "}
                        Name{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th> Description </th>
                      <th> Url </th>
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined &&
                      (userSubPermissions?.delete ||
                        userSubPermissions?.update) ? (
                        <th> Action</th>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && <th> Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listing?.length > 0 &&
                      listing.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td className="py-1">
                              {list?.name ? FORMAT_TEXT(list?.name) : "N/A"}
                            </td>
                            <td>
                              {list?.content ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: FORMAT_TEXT(list?.content),
                                  }}
                                />
                              ) : (
                                "N/A"
                              )}{" "}
                            </td>
                            <td>
                              <a
                                href={`${Path.cms_Page}/${list?._id}`}
                                className="view-btn"
                                target="_blank"
                              >
                                View
                              </a>
                            </td>
                            <td>
                              {userRole === "SUBADMIN" &&
                              userSubPermissions !== undefined ? (
                                <>
                                  {userSubPermissions?.update && (
                                    <>
                                      <Link
                                        to={`${Path.cms_form}/${list?._id}`}
                                      >
                                        <label
                                          class="btn btn-sm btn-gradient-primary"
                                          style={{ cursor: "pointer" }}
                                          title="Edit"
                                        >
                                          <i className="mdi mdi-grease-pencil"></i>
                                        </label>
                                      </Link>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  {userSubPermissions?.delete && (
                                    <>
                                      <label
                                        className="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Delete"
                                        onClick={() => deleteData_(list?._id)}
                                      >
                                        <i className="mdi mdi-delete"></i>
                                      </label>
                                    </>
                                  )}
                                </>
                              ) : (
                                userRole === "ADMIN" &&
                                userSubPermissions === undefined && (
                                  <>
                                    <Link to={`${Path.cms_form}/${list?._id}`}>
                                      <label
                                        class="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Edit"
                                      >
                                        <i className="mdi mdi-grease-pencil"></i>
                                      </label>
                                    </Link>
                                    &nbsp;&nbsp;
                                    <label
                                      className="btn btn-sm btn"
                                      style={{ cursor: "pointer" }}
                                      title="Delete"
                                      onClick={() => deleteData_(list?._id)}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </label>
                                  </>
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.Pages.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ContentList);
