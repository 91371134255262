import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//imported
import * as Path from "routes/paths";
import { CONFIGURATION, PAGE_LIMIT } from "helpers/viewContent";
import { FORMAT_TEXT, DATE_FORMAT_TIME } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
//services
import { getList_ } from "store/services/commonService";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  //history
  const history = useHistory();
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order: 1,
    orderBy: "createdAt",
  });

  const [listing, setListing] = useState([]);

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "mailTemplate/all",
        common: true,
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter?.page, tabIndex]);

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  useEffect(() => {
    let objPer = userData["configuration"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={CONFIGURATION.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{CONFIGURATION.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.configuration}>
                {CONFIGURATION.CURRENT_MODULE}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="row">
                  <div className="col-md-6">Listing</div>
                  <div className="col-md-4"></div>
                </div>
              </h4>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Email Templates</Tab>
                </TabList>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("title")}>
                            {" "}
                            Title{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "title"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "title"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Email Title </th>
                          <th> Content </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            Created At{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Status </th>
                          {userRole === "SUBADMIN" &&
                          userSubPermissions !== undefined &&
                          userSubPermissions?.update ? (
                            <th> Action</th>
                          ) : (
                            userRole === "ADMIN" &&
                            userSubPermissions === undefined && <th> Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {listing?.length > 0 &&
                          listing.map((list, i) => {
                            return (
                              <tr
                                key={i}
                                onClick={() =>
                                  history.push(
                                    `${Path.configurationedit}/${list._id}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  {list.title
                                    ? FORMAT_TEXT(list?.title)
                                    : "N/A"}
                                </td>
                                <td>
                                  {list.emailTitle
                                    ? FORMAT_TEXT(list?.emailTitle)
                                    : "N/A"}
                                </td>
                                <td>
                                  {" "}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: list?.body
                                        ? FORMAT_TEXT(list?.body)
                                        : "---",
                                    }}
                                  />
                                </td>
                                <td>
                                  {list.createdAt
                                    ? DATE_FORMAT_TIME(list?.createdAt)
                                    : "N/A"}
                                </td>
                                <td>
                                  <label
                                    style={{ cursor: "pointer" }}
                                    className={`badge badge-${
                                      list?.status == 1
                                        ? "success payment_status"
                                        : "danger"
                                    }`}
                                  >
                                    {list?.status == 1 ? "Active" : "Inactive"}
                                  </label>
                                </td>
                                <td>
                                  {userRole === "SUBADMIN" &&
                                  userSubPermissions !== undefined ? (
                                    <>
                                      {userSubPermissions?.update && (
                                        <>
                                          <Link
                                            to={`${Path.configurationedit}/${list?._id}`}
                                          >
                                            <label
                                              class="btn btn-sm"
                                              style={{ cursor: "pointer" }}
                                              title="Edit"
                                            >
                                              <i className="mdi mdi-grease-pencil"></i>
                                            </label>
                                          </Link>
                                          &nbsp;&nbsp;
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    userRole === "ADMIN" &&
                                    userSubPermissions === undefined && (
                                      <>
                                        <Link
                                          to={`${Path.configurationedit}/${list?._id}`}
                                        >
                                          <label
                                            class="btn btn-sm"
                                            style={{ cursor: "pointer" }}
                                            title="Edit"
                                          >
                                            <i className="mdi mdi-grease-pencil"></i>
                                          </label>
                                        </Link>
                                      </>
                                    )
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
