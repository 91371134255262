import React, { useState, useEffect, useCallback, useRef } from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
//imported
import { TRADES } from 'helpers/viewContent';
import * as Path from 'routes/paths';
import * as actions from 'store/actions/index.js';
import { uploadFileVideoMulti_ } from 'helpers/uploadFile';
import { MAIN__DUMMY_ } from 'utils/constant'
//services
import { addData_, getDetail_, updateData_ } from 'store/services/commonService';

const AddEdit = ({ dispatch, match }) => {
    let editkey = match.params.id;
    const fileInputRef = useRef(null);
    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Status is required'),
        post_type: Yup.string().required('Post Type is required'),
        media: !editkey ? Yup.array().min(1, 'Media Image is required').required('Media Image is required') : Yup.mixed().required('Media Image is required'),
    });
    const validateData = async (data) => {
        try {
            await validationSchema.validate(data, { abortEarly: false });
            return { isValid: true, errors: {} };
        } catch (error) {
            const errors = {};
            error.inner.forEach((err) => {
                errors[err.path] = err.message;
            });
            return { isValid: false, errors };
        }
    };

    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [sending, setSending] = useState(false);
    const [editData, setEditData] = useState({});
    //media
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileType, setSelectedFileType] = useState(null);
    const [previewImage, setPreviewImage] = useState({});
    const [fields, setfields] = useState({
        title: "",
        description: "",
        post_type: "",
        media: [],
        media_type: "",
        status: "1",
    })

    const handleChange = useCallback(
        name => evt => {
            setfields(prevState => ({
                ...prevState,
                [name]: evt.target.value,
            }))
            setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        []
    )
    const handleFileChange = async (e) => {
        e.preventDefault();
        let fileName = e.target.name;
        const file = e.target.files[0];
        dispatch(actions.persist_store({ loader: true }));
        setIsShow(true)
        setSending(true)
        let data = await uploadFileVideoMulti_(dispatch, file, setValidationErrors, setSelectedFile, setPreviewImage, fileName, setSelectedFileType)
        console.log(data, "data")
        data = data?.length > 0 ? data : [];
        if (data?.length > 0) {
            setfields((prev) => {
                // Check if the fileName key exists in the prev object
                if (fileName in prev) {
                    const prevData = prev[fileName];
                    // Check if any item in the previous data array contains the image key
                    const indexToUpdate = prevData.findIndex(item => item?.media === data[0]?.media || item?.image_url === data[0]?.image_url);

                    if (indexToUpdate !== -1) {
                        // If the image key already exists, update the specific element
                        prevData[indexToUpdate] = data[0];
                    } else {
                        // If the image key does not exist, concatenate the new data
                        prevData.push(...data);
                    }

                    // Return the updated state
                    return {
                        ...prev,
                        [fileName]: prevData,
                    };
                } else {
                    // If the fileName key does not exist, return the previous state without modification
                    return prev;
                }
            });
        }
        dispatch(actions.persist_store({ loader: false }));
        setIsShow(false)
        setSending(false)
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        await uploadFileVideoMulti_(file);
    };
    const handleClick = () => {
        fileInputRef.current.click();
    };

    async function getData() {
        try {
            let query = {
                url: 'post/view',
                id: editkey
            }
            dispatch(actions.persist_store({ loader: true }));
            let res = await dispatch(getDetail_(query));
            let resData = res?.body
            let obj = {
                ...resData,
                media: [res?.body?.media]
            }
            setEditData(res?.body);
            setfields(obj)
            dispatch(actions.persist_store({ loader: false }));
        } catch (err) {
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }

    useEffect(() => {
        if (editkey) {
            getData()
        }
    }, []);
    //submit setting data
    const handleSubmit = async (evt) => {
        try {
            evt.preventDefault();
            const validation = await validateData(fields);
            if (validation.isValid) {
                dispatch(actions.persist_store({ loader: true }));
                setIsShow(true)
                fields.media = previewImage?.media ? JSON.stringify(fields.media) : []
                fields.media_type = selectedFileType
                let query = {}
                if (editkey) {
                    query = {
                        ...fields,
                        url: "post/edit",
                        id: editkey
                    }
                    await dispatch(updateData_(query));
                } else {
                    query = {
                        ...fields,
                        url: "post/add"
                    }
                    await dispatch(addData_(query));
                }
                // return
                setIsShow(false)
                dispatch(actions.persist_store({ loader: false }));
                history.push(Path.trade)
            } else {
                setValidationErrors(validation.errors);
            }
        } catch (err) {
            setIsShow(false)
            dispatch(actions.persist_store({ loader: false }));
            console.log(err);
        }
    }
    return (
        <>
            <Helmet title={TRADES.CURRENT_MODULE} />
            <div className="page-header">
                <h3 className="page-title">
                    {TRADES.CURRENT_VIEW}
                </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={Path.trade}>Mangae Trades</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add/Edit</li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card graph_card">
                        <div className="card-body">
                            <h4 className="card-title">Add/Edit</h4>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Title</label>
                                        <input type="text" className="form-control" id="exampleInputName1" placeholder="Title" name="title" onChange={handleChange("title")}
                                            value={fields.title}
                                        />
                                        {validationErrors.title && (
                                            <div className="text-danger err">{validationErrors.title}</div>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Post Type*</label>
                                        <select className="form-select" name="post_type" onChange={handleChange("post_type")}
                                            value={fields.post_type}>
                                            <option value="" selected disabled>Select </option>
                                            <option value="0" selected={fields?.post_type === '0' ? 'selected' : ''}>Picture </option>
                                            <option value="1" selected={fields?.post_type === '1' ? 'selected' : ''}>Blog </option>
                                        </select>
                                        {validationErrors.post_type && (
                                            <div className="text-danger err">{validationErrors.post_type}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="exampleInputName1">Description</label>
                                        <textarea type="text" className="form-control" id="exampleInputName1" placeholder="Description" name="description" onChange={handleChange("description")}
                                            value={fields.description}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
                                            <label for="exampleInputEmail3">Media*</label>
                                            <input type="file" class="form-control file-upload-info" name="media" disabled="" placeholder="Upload Image" style={{ display: 'none' }} onChange={(e) => handleFileChange(e)} ref={fileInputRef} />
                                            <div>
                                                {previewImage?.media && selectedFileType !== '1' ? (
                                                    <img src={previewImage?.media} alt="Preview" className="img-custom" />
                                                ) : editData?.media && editData?.media_type === '0' && selectedFileType !== '1' ? (
                                                    <img src={editData?.media} alt="Preview" className="img-custom" />
                                                ) : previewImage?.media && selectedFileType === '1' ? (
                                                    <video src={previewImage?.media} alt="Default Preview" className="img-custom-video" />
                                                ) : editData?.media && editData?.media_type === '1' ? (
                                                    <video src={editData?.media} alt="Default Preview" className="img-custom-video" />
                                                ) : (
                                                    <img src={MAIN__DUMMY_} alt="Default Preview" className="img-custom" />
                                                )}
                                            </div>
                                            {validationErrors.media && (
                                                <div className="text-danger err">{validationErrors.media}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <label for="exampleInputPassword4">Status*</label>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value={'1'} name="status" onChange={handleChange("status")} checked={fields.status === '1' ? true : false} /> Active
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="">
                                                    <input type="radio" className="" value={'0'} name="status" onChange={handleChange("status")} checked={fields.status === '0' ? true : false} /> Inactive </label>
                                            </div>
                                        </div>
                                    </div>
                                    {validationErrors.status && (
                                        <div className="text-danger err">{validationErrors.status}</div>
                                    )}
                                </div>

                                <button type="submit" disabled={isShow ? true : false} className="btn btn-gradient-primary me-2">{isShow ? 'Wait..' : 'Submit'}</button>
                                <Link to={Path.trade}><button className="btn btn-outline-secondary btn-lg">Back</button></Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

const mapStateToPros = (state) => {
    return {
    }
};
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(AddEdit);