import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import * as Yup from "yup";

//imported
import { BASICSETTING } from "helpers/viewContent";

//services
import {
  getBasicSetting_,
  updateBasicSetting_,
} from "store/services/basicSetting";

const AddEdit = ({ dispatch, userProfile, user }) => {
  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Footer Content is required"),
    indonesiaText: Yup.string().required(
      "Indonesia Footer Content is required"
    ),
    address: Yup.string().required("Address is required"),
    indonesiaAddress: Yup.string().required("Indonesia Address is required"),
    app_message: Yup.string().required("App message is required"),
    indonesiaApp_message: Yup.string().required(
      "Indonesia App message is required"
    ),
    facebook_follow: Yup.string().url().required("Facebook link is required"),
    instagram_follow: Yup.string().url().required("Instagram link is required"),
    tiktok_follow: Yup.string().url().required("Tiktok link is required"),
    twitter_follow: Yup.string().url().required("Twitter link is required"),
    Admin_Phone_Number: Yup.string()
      .required("Phone number is required")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Invalid phone number"
      )
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number cannot exceed 15 digits")
      .test("is-valid-phone-number", "Invalid phone number", function (value) {
        const phoneNumberRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
        return phoneNumberRegex.test(value);
      }),
    Contact_Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    copy_right_mail: Yup.string()
      .email("Invalid email address")
      .required(" Copy Right Email is required"),

    footer_website_link: Yup.string()
      .url()
      .required("Footer website link is required"),
    android_app_link: Yup.string()
      .url()
      // .matches(
      //   /https:\/\/play\.google\.com\/store\/apps\/details\?id=([a-zA-Z0-9_\.\-]+)&/,
      //   "Must be a valid link to the Google Play Store app"
      // )
      .required("Link to Google Play Store app is required"),

    ios_app_link: Yup.string()
      .url()
      // .matches(
      //   /itunes.apple.com\/app\/.+?\/id\d+/,
      //   "Must be a valid link to the App Store app"
      // )
      .required("iOS App Link is required"),
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;

  const [userSubPermissions, setuserSubPermissions] = useState(null);

  const [isShow, setIsShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [fields, setfields] = useState({
    text: "",
    facebook_follow: "",
    tiktok_follow: "",
    instagram_follow: "",
    twitter_follow: "",
    Admin_Phone_Number: "",
    android_app_link: "",
    ios_app_link: "",
    app_message: "",
    copy_right_mail: "",
    Contact_Email: "",
    address: "",
    indonesiaAddress: "",
    indonesiaText: "",
    indonesiaApp_message: "",
    footer_website_link: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  async function getDataView() {
    try {
      let res = await dispatch(getBasicSetting_());
      setfields({
        ...res?.data[0]?.App_Settings,
        ...res?.data[0]?.social_settings,
      });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getDataView();
  }, []);

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        setIsShow(true);
        let obj = {
          App_Settings: {
            indonesiaAddress: fields?.indonesiaAddress,
            address: fields?.address,
            indonesiaText: fields?.indonesiaText,
            text: fields?.text,
            android_app_link: fields?.android_app_link,
            ios_app_link: fields?.ios_app_link,
            app_message: fields?.app_message,
            indonesiaApp_message: fields?.indonesiaApp_message,
            copy_right_mail: fields?.copy_right_mail,
            Contact_Email: fields?.Contact_Email,
            Admin_Phone_Number: fields?.Admin_Phone_Number,
            footer_website_link: fields?.footer_website_link,
          },
          social_settings: {
            facebook_follow: fields?.facebook_follow,
            instagram_follow: fields?.instagram_follow,
            tiktok_follow: fields?.tiktok_follow,
            twitter_follow: fields?.twitter_follow,
          },
        };
        await dispatch(updateBasicSetting_(obj));

        setIsShow(false);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  useEffect(() => {
    let objPer = userData["landingPage"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={BASICSETTING.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{BASICSETTING.CURRENT_MODULE}</h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              {/* <h4 className="card-title">Add/Edit</h4> */}
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Footer Content*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Footer Content*"
                      name="text"
                      onChange={handleChange("text")}
                      value={fields.text}
                    />
                    {validationErrors.text && (
                      <div className="text-danger err">
                        {validationErrors.text}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">
                      Indonesia Footer Content*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Footer Content*"
                      name="indonesiaText"
                      onChange={handleChange("indonesiaText")}
                      value={fields.indonesiaText}
                    />
                    {validationErrors.indonesiaText && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaText}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Facebook*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Facebook*"
                      name="facebook_follow"
                      onChange={handleChange("facebook_follow")}
                      value={fields.facebook_follow}
                    />
                    {validationErrors.facebook_follow && (
                      <div className="text-danger err">
                        {validationErrors.facebook_follow}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Instagram*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Instagram*"
                      name="instagram_follow"
                      onChange={handleChange("instagram_follow")}
                      value={fields.instagram_follow}
                    />
                    {validationErrors.instagram_follow && (
                      <div className="text-danger err">
                        {validationErrors.instagram_follow}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Tiktok*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Tiktok*"
                      name="tiktok_follow"
                      onChange={handleChange("tiktok_follow")}
                      value={fields.tiktok_follow}
                    />
                    {validationErrors.tiktok_follow && (
                      <div className="text-danger err">
                        {validationErrors.tiktok_follow}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Twitter*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Twitter*"
                      name="twitter_follow"
                      onChange={handleChange("twitter_follow")}
                      value={fields.twitter_follow}
                    />
                    {validationErrors.twitter_follow && (
                      <div className="text-danger err">
                        {validationErrors.twitter_follow}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Phone Number*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Phone Number*"
                      name="Admin_Phone_Number"
                      onChange={handleChange("Admin_Phone_Number")}
                      value={fields.Admin_Phone_Number}
                    />
                    {validationErrors.Admin_Phone_Number && (
                      <div className="text-danger err">
                        {validationErrors.Admin_Phone_Number}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Contact Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Contact Email*"
                      name="Contact_Email"
                      onChange={handleChange("Contact_Email")}
                      value={fields.Contact_Email}
                    />
                    {validationErrors.Contact_Email && (
                      <div className="text-danger err">
                        {validationErrors.Contact_Email}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Copy Right Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Copy Right Email*"
                      name="copy_right_mail"
                      onChange={handleChange("copy_right_mail")}
                      value={fields.copy_right_mail}
                    />
                    {validationErrors.copy_right_mail && (
                      <div className="text-danger err">
                        {validationErrors.copy_right_mail}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">App Message*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="App Message*"
                      name="app_message"
                      onChange={handleChange("app_message")}
                      value={fields.app_message}
                    />
                    {validationErrors.app_message && (
                      <div className="text-danger err">
                        {validationErrors.app_message}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">
                      Indonesia App Message*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia App Message*"
                      name="indonesiaApp_message"
                      onChange={handleChange("indonesiaApp_message")}
                      value={fields.indonesiaApp_message}
                    />
                    {validationErrors.indonesiaApp_message && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaApp_message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Android App Link*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Android App Link*"
                      name="android_app_link"
                      onChange={handleChange("android_app_link")}
                      value={fields.android_app_link}
                    />
                    {validationErrors.android_app_link && (
                      <div className="text-danger err">
                        {validationErrors.android_app_link}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">IOS App Link*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="IOS App Link*"
                      name="ios_app_link"
                      onChange={handleChange("ios_app_link")}
                      value={fields.ios_app_link}
                    />
                    {validationErrors.ios_app_link && (
                      <div className="text-danger err">
                        {validationErrors.ios_app_link}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleInputName1">Footer Website Link*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Footer Website Link*"
                      name="footer_website_link"
                      onChange={handleChange("footer_website_link")}
                      value={fields.footer_website_link}
                    />
                    {validationErrors.footer_website_link && (
                      <div className="text-danger err">
                        {validationErrors.footer_website_link}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">Address*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Address*"
                      name="address"
                      onChange={handleChange("address")}
                      value={fields.address}
                    />
                    {validationErrors.address && (
                      <div className="text-danger err">
                        {validationErrors.address}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group ">
                    <label for="exampleInputName1">Indonesia Address*</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Indonesia Address*"
                      name="indonesiaAddress"
                      onChange={handleChange("indonesiaAddress")}
                      value={fields.indonesiaAddress}
                    />
                    {validationErrors.indonesiaAddress && (
                      <div className="text-danger err">
                        {validationErrors.indonesiaAddress}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Wait.." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
