import React from "react";
import {  Link } from 'react-router-dom';
import { PAGE_LIMIT } from 'helpers/viewContent';
import {  FORMAT_TEXT, DATE_FORMAT_TIME_ } from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";

export default ({listing, userSubPermissions, userRole, deleteDataFunction_, editModalTaks, activePage, totalItemsCount, handlePageChange, filter, handleSortingFunc}) => {
    
    return(
        <>
            <div class="table-responsive">
                <table className="table common_th">
                <thead>
                    <tr>
                        <th onClick={() => handleSortingFunc('subject')}>  Subject <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'subject' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'subject' ? 'Desc' :'Asc')}></i> </th>
                        <th onClick={() => handleSortingFunc('assigned')}>  Assgined <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'assigned' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'assigned' ? 'Desc' :'Asc')}></i> </th>
                        <th onClick={() => handleSortingFunc('title')}>  Title <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'title' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'title' ? 'Desc' :'Asc')}></i> </th>                     
                        <th> Comment </th>
                        <th onClick={() => handleSortingFunc('endDate')}>  Expire <i className={`cursor-pointer mdi mdi-arrow-${filter?.order=== -1 && filter?.orderBy === 'endDate' ? 'up' :'down'}`} title={(filter?.order === -1 && filter?.orderBy === 'endDate' ? 'Desc' :'Asc')}></i> </th>    
                        <th> Status </th>
                        {(userRole === 'SUBADMIN' && userSubPermissions !== undefined) && (userSubPermissions?.delete ||  userSubPermissions?.update) ? (
                            <th> Action</th>
                        ) : (userRole === 'ADMIN' && userSubPermissions===undefined &&
                        <th> Action</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                {listing?.length> 0 && listing.map((list, i) => {
                    return(
                        <tr key={i} style={{cursor:'pointer'}}>
                        <td>{list?.subjectDetails?.name ? FORMAT_TEXT(list?.subjectDetails?.name) : FORMAT_TEXT(list?.subjectDetails?.email) }</td>
                        <td>{list?.assignDetails?.name ? FORMAT_TEXT(list?.assignDetails?.name) : FORMAT_TEXT(list?.assignDetails?.email) }</td>
                        <td>{list.title ? list.title : 'N/A'}</td>
                        <td>{list.comment ? list.comment : 'N/A'}</td>
                        <td>{list.endDate ? DATE_FORMAT_TIME_(list?.endDate) : 'N/A'}</td>
                        <td><i class={`mdi mdi-checkbox-blank-circle ${list.status === 'upcoming' ? 'info' : list.status === 'inactive' ? 'primary' : 'success'}`}></i>  {list.status === 'upcoming' ? 'Tomorrow' : list.status === 'inactive' ? 'Overdue' : 'Today'}</td>
                        <td>
                            {(userRole === 'SUBADMIN' && userSubPermissions!==undefined) ? (
                                <>
                                {list.icon === 'call' &&
                                    <label class="btn btn-sm" style={{cursor:'pointer'}} title={list?.subjectDetails?.mobileNumber ? `${list?.subjectDetails?.countryCode}${list?.subjectDetails?.mobileNumber}` : '#'} ><i className="mdi mdi-phone"></i></label>
                                }
                                {list.icon === 'mail' &&
                                    <label class="btn btn-sm" style={{cursor:'pointer'}} title={list?.subjectDetails?.email ? `${list?.subjectDetails?.email}` : '#'} ><i className="mdi mdi-email"></i></label>
                                }
                                {userSubPermissions?.update &&
                                <>
                                <Link to="#" onClick={() => editModalTaks(list)} ><label class="btn btn-sm" style={{cursor:'pointer'}} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link> 
                                    &nbsp;&nbsp;
                                </>
                                }
                                {userSubPermissions?.delete &&
                                <>
                                <label className="btn btn-sm"  onClick={() => deleteDataFunction_(list?._id)} style={{cursor:'pointer'}} title="Delete"><i className="mdi mdi-delete"></i></label>
                                </>
                                }
                                </>
                            ) : (userRole === 'ADMIN' && userSubPermissions===undefined &&
                                <>
                                <Link to="#"  onClick={() => editModalTaks(list)} ><label class="btn btn-sm" style={{cursor:'pointer'}} title="Edit"><i className="mdi mdi-grease-pencil"></i></label></Link> 
                                &nbsp;&nbsp;
                                <label className="btn btn-sm"  onClick={() => deleteDataFunction_(list?._id)} style={{cursor:'pointer'}} title="Delete"><i className="mdi mdi-delete"></i></label>
                                </>
                            )}
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                </table>
            </div>
            {listing.length > 0 && (
            <div className="pagination-box-review">
                <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                />
            </div>
            )} 
            {listing?.length == 0 &&
                <div className="row">
                <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                    </div>
                </div>
            }
        </>
    );
};