import { GET_FAQ, ADD_FAQ, UPDATE_FAQ, VIEW_FAQ, DELETE_FAQ, UPDATE_STATUS_FAQ} from 'store/actions/faq';

const initialState = {
  isAuthenticated: false,
  loader:false,
  faqList:[],
  faqDetail:{},
  totalItemsCount : 0
};

const getFAQ = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
      callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      faqList:data?.data?.data,
      totalItemsCount:data?.data?.totalcount,
    };
    return stateObj;
};

const addFAQ = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updateFAQ = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const viewFAQ = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
    faqDetail:payload,
  };
  return stateObj;
};

const deleteFAQ = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const updateStatusFAQ = (state, payload) => {
  const { callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }
  const stateObj = {
    ...state,
    isAuthenticated: true,
  };
  return stateObj;
};

const Pages = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_FAQ:
          return getFAQ(state, payload); 
        case ADD_FAQ:
          return addFAQ(state, payload); 
        case UPDATE_FAQ:
          return updateFAQ(state, payload); 
        case VIEW_FAQ:
          return viewFAQ(state, payload); 
        case DELETE_FAQ:
          return deleteFAQ(state, payload); 
        case UPDATE_STATUS_FAQ:
          return updateStatusFAQ(state, payload); 
        default:
        return state;
    };
}
export default Pages;
