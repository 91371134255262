import {getData, postData, postData_, deleteDataMethod} from 'helpers/Helper'
import { APP_PATH, GET_FAQ, ADD_FAQ, UPDATE_FAQ, VIEW_FAQ, DELETE_FAQ, UPDATE_STATUS_FAQ } from './apiPath'
import { getFAQ, addFAQ, updateFAQ, viewFAQ, deleteFAQ, updateStatusFAQ} from 'store/actions/faq';
import { persist_store } from 'store/actions/index';

export function getFAQ_(value, callback) {
  const { page, limit, url, common } = value || {};
  let urlPath = `${APP_PATH}${GET_FAQ}`;
  // if (page !== undefined && limit !== undefined) {
  //     urlPath += `?page=${page}&limit=${limit}`;
  // } 

  return (dispatch) => {
    // Set loader state to true before making the API call
    dispatch(persist_store({ loader: true }));
    // Make the API call
    return postData_(urlPath, value)
      .then((data) => {
        // Dispatch the getList action with the data received from the API
        dispatch(getFAQ(data, callback));
        // Set loader state to false after the API call is complete
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        // In case of an error, also set loader state to false
        dispatch(persist_store({ loader: false }));
        // You might want to handle the error here or propagate it further
        console.error('Error fetching data:', error);
      });
  };
}

//add 
export function addFAQ_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${ADD_FAQ}`, value)
    .then((data) => {
      dispatch(addFAQ(data, callback));
      return data;
    });
}


//view
export function viewFAQ_(value, callback) {
  let urlPath = `${APP_PATH}${VIEW_FAQ}${value}`;
  return (dispatch) => {
    dispatch(persist_store({ loader: true }));
    return getData(urlPath, value)
      .then((data) => {
        dispatch(viewFAQ(data, callback));
        dispatch(persist_store({ loader: false }));
        return data;
      })
      .catch(error => {
        dispatch(persist_store({ loader: false }));
        console.error('Error fetching data:', error);
      });
  };
}

export function updateFAQ_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${UPDATE_FAQ}`, value)
    .then((data) => {
      dispatch(updateFAQ(data, callback));
      return data;
    });
}

export function deleteFAQ_(value, callback) {
  let url = `${APP_PATH}${DELETE_FAQ}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(deleteFAQ(data, callback));
      return data;
    });
}

export function updateStatusFAQ_(value, callback) {
  return (dispatch) => postData(`${APP_PATH}${UPDATE_STATUS_FAQ}${value?.id}`, value)
    .then((data) => {
      dispatch(updateStatusFAQ(data, callback));
      return data;
    });
}